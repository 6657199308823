import { useForm } from "react-hook-form"
import InputsFields from "../../helpers/InputFields";
import { useResetPasswordMutation } from "../../store/services/UsersService";
import Loader from "../main/Loader";
import {toast} from "react-toastify";

export default function ResetPasswordForm ({id}: {id: string}) {

    const form = useForm();
    const { handleSubmit } = form;
    const [resetPassword, {isLoading}] = useResetPasswordMutation();

    const handleResetPassword = async (data: any) => {
        let result: any;

        result = await resetPassword({id: id, ...data})

        if ('error' in result) {
            toast.error(`Password non modificata! Errore: ${result?.error?.data?.detail}`)
        } else {
            toast.success(`Password modificata`)
        }

    }

    const ResetInput = [
        {
            label: 'Password',
            name: 'password',
            placeholder: 'Password',
            type: 'text',
            validators: {
                required: 'Il campo è obbligatorio'
            },
            type_input: 'password',
            size_input: 'sm:w-2/5 w-full',
        }
    ]

    return(
        <div className="p-5">
            <p>Inserisci una nuova password</p>
            <form onSubmit={handleSubmit(handleResetPassword)} className="relative my-8">
                {isLoading && <Loader />}
                <InputsFields arrFields={ResetInput} form={form} />
                <button className="h-10 bg-[#064e3b] text-white px-5 my-6 rounded-lg text-md">Salva</button>
            </form>
        </div>
    )
}