import { Children, useState } from "react"
import { Controller } from "react-hook-form";
import Select, { components, StylesConfig } from 'react-select';
import ErrorMessageComponent from "../main/ErrorMessage";

export default function ReactSelectInput({ input, form, options, type, isDisabled }: any) {

    const { control, formState: { errors }, setValue } = form;
    const [isFocused, setIsFocus] = useState(false);

    const handleSetPrice = (nameInput: string, currentValue: any) => {
        if(nameInput.includes('.prodotto')) {
            const currentSetPrice = nameInput.split('.')[0];
            const Num = currentValue?.price?.toFixed(2);
            setValue(`${currentSetPrice}.prezzo`, Number(Num), {shouldDirty: true})
        }
    }

    const customStyle: StylesConfig = {
        control: (base: any, { isDisabled}) => ({
            ...base,
            border: "0 !important",
            boxShadow: "0 !important",
            "&:hover": { border: "0 !important" },
            backgroundColor: isDisabled || input?.isDisabled && 'transparent'
        }),
        dropdownIndicator: (base: any) => ({
            ...base,
            color: '#91919c',
            "&:hover": {
                color: '#09acf8'
            },
            "&:focus": {
                color: '#09acf8'
            }
        }),
        singleValue: (base: any) => ({
            ...base,
            color: '#333333'
        }),
        option: (base: any, { isSelected }) => ({
            ...base,
            color: isSelected && '#91919c',
            background: isSelected ? '#F9F9F9' : '',
            "&:hover": {
                color: '#91919c',
                background: '#F9F9F9'
            }
        }),
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            overflow: "visible",
            padding: 0,
            margin: 0,
        }),
        placeholder: (provided: any, state: any) => ({
            ...provided,
            position: "absolute",
            top: (state.hasValue || state.selectProps.inputValue || isFocused) ? -30 : "6%",
            transitionProperty: 'all',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
            transitionDuration: '350ms',
            fontSize: (state.hasValue || state.selectProps.inputValue || isFocused) ? 13 : 13,
            color: (state.selectProps.inputValue || isFocused) ? '#333333' : '#9CA3AF',
        }),
    };

    const { ValueContainer, Placeholder } = components;

    const CustomValueContainer = ({ children, ...props }: any) => {
        return (
            <ValueContainer {...props} className={`text-base bg-transparent transition-all`}>
                <Placeholder {...props} isFocused={props.isFocused}>
                    {props.selectProps.placeholder}
                </Placeholder>
                {Children.map(children, child =>
                    child && child.type !== Placeholder ? child : null
                )}
            </ValueContainer>
        );
    };

    return (

        <div>
        <div className={`${input.size_input} h-10 transition-all`}>
            <div className={`h-full flex flex-col justify-end ${isDisabled || input?.isDisabled ? 'cursor-not-allowed' : ''}`}>
                <Controller
                    control={control}
                    name={input.name}
                    rules={input.validators}
                    render={({ field: {onChange, value}}: any) => (
                        <Select
                            onFocus={() => setIsFocus(true)}
                            onBlur={() => setIsFocus(false)}
                            className={`${isDisabled || input?.isDisabled ? 'opacity-60 cursor-not-allowed' : ''} bg-[#efefef] rounded-md pl-2 py-1 peer h-10 w-full placeholder-transparent focus:outline-none text-md`}
                            components={{ ValueContainer: CustomValueContainer }}
                            placeholder={input.placeholder}
                            styles={{ ...customStyle, menu: provided => ({ ...provided, zIndex: 9999 }) }}
                            menuPlacement="auto"
                            value={value}
                            onChange={(e) => {onChange(e); handleSetPrice(input.name, e)}}
                            options={input.options ? input.options : options}
                            isClearable
                            isSearchable
                            isDisabled={isDisabled || input?.isDisabled}
                            defaultValue={input?.options && input?.options[0]?.label === 'Qualsiasi' ? input?.options[0] : ''}
                        />
                    )}
                />
            </div>
        </div>
        <ErrorMessageComponent errors={errors} name={input?.name} />
    </div>
    );
}