import { useGetEsitoConsulenzaQuery } from "../store/services/LookUpService"

export default function EsitoConsulenzaLookUp() {
    const {data} = useGetEsitoConsulenzaQuery('');

    const replaceEsito = (esito: string) => {
        let split =  esito.split(/(?=[A-Z])/);
        return `${split[0]} ${split[1] ? split[1]?.toLowerCase() : ''}`
    }

    let optionEsito = data?.map((esito: string) => ({value: esito, label: replaceEsito(esito)}))
    return optionEsito;
}