
export const ProductListInput = [
    {
        label: 'Nome prodotto',
        name: 'nome',
        placeholder: 'Nome prodotto',
        type: 'text',
        validators: {
            required: 'Il nome è obbligatorio'
        },
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Prezzo',
        name: 'prezzoConsigliato',
        placeholder: 'Prezzo',
        type: 'text',
        validators: {
            required: 'Il prezzo è obbligatorio',
            validate: (value: string) => value !== '0' || 'Il prezzo deve essere maggiore di zero'
        },
        type_input: 'input',
        size_input: 'w-full',
    }
]