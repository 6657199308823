export const HeaderRepOp = [
    {
        Header: "Periodo riferimento",
        accessor: "periodoRiferimento",
        disableSortBy: true,
        Filter: false,
        customSort: false,
    },
    {
        Header: "Nominativo",
        accessor: "nominativo",
        disableSortBy: true,
        Filter: false,
        customSort: false,
        Cell: ({ row }: any) => (
            <>
                <p>{row?.original?.codice}</p>
                <p>{row?.original?.nominativo}</p>
            </>
        )
    },
    {
        Header: "Anagrafiche Create",
        accessor: "anagraficheCreate",
        disableSortBy: true,
        Filter: false,
        customSort: false,
    },
    {
        Header: "Chiamate effettuate",
        accessor: "chiamateEffettuate",
        disableSortBy: true,
        Filter: false,
        customSort: false,
    },
    {
        Header: "Chiamate eff. risposta",
        accessor: "chiamateEffettuateConRisposta",
        disableSortBy: true,
        Filter: false,
        customSort: false,
    },
    {
        Header: "Chiamate eff. senza risposta",
        accessor: "chiamateEffettuateSenzaRisposta",
        disableSortBy: true,
        Filter: false,
        customSort: false,
    },
    {
        Header: "Appuntamenti Presi",
        accessor: "appuntamentiConsulenzePresi",
        disableSortBy: true,
        Filter: false,
        customSort: false,
    }
]