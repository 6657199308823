
export default function RadioBtnIput({ input, form }: any) {

    const { register } = form

    return (
        <div className="flex space-x-3 items-center">
            <input value={input.value} {...register(input.name, { ...input.validators })} id={input.label} type="radio" className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:outline-none" />
            <label htmlFor={input.label} className="cursor-pointer select-none">
                {input.label}
            </label>
        </div>
    )
}