import { ChevronDownIcon, ChevronUpIcon, FunnelIcon,  } from "@heroicons/react/24/outline";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { IFilterInterviews } from "../../helpers/adapter/filters/AdapterFilterInterviews";
import InputsFields from "../../helpers/InputFields";
import EsitoConsulenzaLookUp from "../../lookup/EsitoConsulenza";
import ProdottiLookUp from "../../lookup/Prodotti";
import SedeLookUp from "../../lookup/Sede";
import StatiIntervistaLookUp from "../../lookup/Stato";
import StatoVideoLookup from "../../lookup/StatoVideo";
import { selectCurrentUserInfo } from "../../store/feautures/AuthSlice";
import { useGetUserRoleQuery } from "../../store/services/LookUpService";
import { InputFilterSede, InterviewListFilter, RicercaGlogale } from "../../utils/listInputs/InterviewsListFilter";
import StatoPagamentoLookup from "../../lookup/StatoPagamento";

export default function InterviewsFilters({methodParent}: any) {

    const form = useForm();
    const [isOpenFilter, setIsOpenFilter] = useState<boolean>(true);
    const { handleSubmit, reset } = form;
    let optionsSede: any = SedeLookUp();
    let optionsEsiti: any = EsitoConsulenzaLookUp();
    let optionsStati: any =  StatiIntervistaLookUp();
    let optionStateVideo: any = StatoVideoLookup();
    let optionPaymentState: any = StatoPagamentoLookup()
    const { role } = useSelector(selectCurrentUserInfo);
    const {isLoading, data} = useGetUserRoleQuery('');
    let optionProduct = ProdottiLookUp();

    let optionNominativo: any[] = useMemo(() => {
        return data?.map((user: any) => ({value: user?.id, label: `${user?.nominativo}(${user?.codiceUtente})`}))
    }, [data]);

    let optionNominativoOperatore: any[] = useMemo(() => {
        return data?.filter((user: any) => user.ruolo === 'Operatore').map((user: any) => ({value: user?.id, label: `${user?.nominativo}(${user?.codiceUtente})`}))
    }, [data]);

    let optionConsulente: any[] = useMemo(() => {
        return data?.filter((user: any) => user.ruolo === 'Consulente').map((user: any) => ({value: user?.id, label: `${user?.nominativo}(${user?.codiceUtente})`}))
    }, [data]);

    let optionMontatore: any[] = useMemo(() => {
        return data?.filter((user: any) => user.ruolo === 'Montatore').map((user: any) => ({value: user?.id, label: `${user?.nominativo}(${user?.codiceUtente})`}))
    }, [data]);

    let optionSpeaker: any[] = useMemo(() => {
        return data?.filter((user: any) => user.ruolo === 'Speaker').map((user: any) => ({value: user?.id, label: `${user?.nominativo}(${user?.codiceUtente})`}))
    }, [data]);

    const InitialValueFilterInterviews: IFilterInterviews = {
        NomeOspite: '',
        CognomeOspite: '',
        TelefonoOspite: '',
        Sede: '',
        CodiceOspite: '',
        Stato: '',
        Esito: '',
        NominativoOCodiceOpearatoreChiamataId: '',
        NominativoOCodiceConsulenteId: '',
        NominativoOCodiceSpeakerId: '',
        NominativoOCodiceMontatoreId: '',
        DataConsulenzaDa: '',
        DataConsulenzaA: '',
        DataIntervistaDa: '',
        DataIntervistaA: '',
        DataConsegnaVideoDa: '',
        DataConsegnaVideoA: '',
        RicercaGlobale : '',
        StatoVideo: '',
        MetodoPagamento: '',
        prodotto: '',
        StatoPagamento: ''
    }

    return(
        <div className="bg-white shadow-lg rounded-lg">
            <div className="flex justify-between items-center p-4" >
                <p>Filtri</p>
                <button onClick={() => setIsOpenFilter(!isOpenFilter)}>
                    {isOpenFilter ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />}
                </button>
            </div>
            {isOpenFilter && <form onSubmit={handleSubmit(methodParent)} >

                <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 grid-rows-none gap-6 p-4">
                    <InputsFields arrFields={InterviewListFilter(optionsEsiti, optionsStati, optionNominativoOperatore, optionConsulente, optionSpeaker, optionMontatore, optionProduct, optionStateVideo, optionPaymentState)} form={form} />
                    {role === 'SuperAdmin' || role === 'Admin' ? <InputsFields arrFields={InputFilterSede(optionsSede)} form={form} /> : null}
                    {['SuperAdmin', 'Admin', 'Consulente', 'Speaker'].includes(role) ? <InputsFields arrFields={RicercaGlogale} form={form} /> : null}
                </div>

                <div className="flex items-center justify-end space-x-5 sm:col-span-3 p-5">
                    <button className="w-5 h-5" onClick={() => reset(InitialValueFilterInterviews)}>
                        <FunnelIcon className="w-5 h-5" />
                    </button>
                    <button className="h-10 w-52 bg-[#064e3b] text-white rounded-lg px-10 text-md">
                        Cerca
                    </button>
                </div>

            </form>}
        </div>
    )
}