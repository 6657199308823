import { ChevronDownIcon, ChevronUpIcon, FunnelIcon,  } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputsFields from "../../helpers/InputFields";
import RegioniLookUp from "../../lookup/Regioni";
import { useGetUserRoleQuery } from "../../store/services/LookUpService";
import { FilterUsersInputs } from "../../utils/listInputs/UsersFiltersList";


export default function UserFilters({methodParent}: any) {

    const form = useForm();
    const [isOpenFilter, setIsOpenFilter] = useState<boolean>(true);
    const { handleSubmit, reset } = form;
    const anyOption = [{value: '', label: 'Qualsiasi'}];
    const optionRegioni = anyOption.concat(RegioniLookUp(true));
    const {data} = useGetUserRoleQuery({role: 'Supervisore'});
    const [listSuperVi, setListSuperV] = useState([])

    useEffect(() => {
        let listMap = data?.map((acc: any) => {
            return {value: acc?.id, label: acc?.nominativo }
        });

        setListSuperV(listMap);
    }, [data])

    const InitialValueFilterUser = {
        Nome: null,
        Cognome: null,
        CodiceUtente: null,
        Regione: '',
        Email: null,
        Ruolo: '',
        SupervisoreId: ''
    }

    return(
        <div className="bg-white shadow-lg rounded-lg">
            <div className="flex justify-between items-center p-4" >
                <p>Filtri</p>
                <button onClick={() => setIsOpenFilter(!isOpenFilter)}>
                    {isOpenFilter ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />}
                </button>
            </div>
            {isOpenFilter && <form onSubmit={handleSubmit(methodParent)} className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 grid-rows-none gap-6 p-4">
                <InputsFields arrFields={FilterUsersInputs(optionRegioni, listSuperVi)} form={form} />

                <div className="flex items-center justify-end space-x-5">
                    <button className="w-5 h-5" onClick={() => reset(InitialValueFilterUser)}>
                        <FunnelIcon className="w-5 h-5" />
                    </button>
                    <button className="h-10 w-52 bg-[#064e3b] text-white rounded-lg px-10 text-md">
                        Cerca
                    </button>
                </div>

            </form>}
        </div>
    )
}