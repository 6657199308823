import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    selectedTab: '',
}

const TabSlice = createSlice({
    name: 'setTabSelect',
    initialState: initialState,
    reducers: {
        setCurrentTab: (state, {payload}) => {
            return state =  {...payload};
        }
    }
})

export const { setCurrentTab } = TabSlice.actions
export default TabSlice.reducer

export const SelectedTab = (state: any) => state.TabSlice.selectedTab