import { useState } from "react";
import TabsComponent from "../../components/main/Tabs";
import ReportConsulente from "./ReportConsulente";
import ReportFatturato from "./ReportFatturato";
import ReportMontatore from "./ReportMontatore";
import ReportOperator from "./ReportOperator";
import ReportOpVendite from "./ReportOpVendite";
import ReportOspiti from "./ReportOspiti";
import ReportLead from "./ReportLead";

export default function ReportPage() {

    let [categories] = useState({
        ReportOperatore: [
            {
                id: 1,
                component: <ReportOperator />
            },
        ],
        StatisticheConsulente: [
            {
                id: 2,
                component: <ReportConsulente />
            },
        ],
        StatisticheMontatore: [
            {
                id: 3,
                component: <ReportMontatore />
            },
        ],
        VenditeOperatore: [
            {
                id: 4,
                component: <ReportOpVendite />
            },
        ],
        ReportFatturato: [
            {
                id: 5,
                component: <ReportFatturato />
            }
        ],
        ReportOspiti: [
            {
                id: 6,
                component: <ReportOspiti />
            }
        ],
        Lead: [
            {
                id: 7,
                component: <ReportLead />
            }
        ]
    })

    return (

        <>
            <h2>Report</h2>
            <TabsComponent categories={categories} />
        </>
    )
}