import { ClipboardDocumentCheckIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { selectCurrentUserInfo } from "../../store/feautures/AuthSlice";
import FormSetState from "../form/FormSetState";
import DialogComponent from "../main/DIalogComponent";

export default function ActionSetState({ row, contentType, label }: any) {

    const { role } = useSelector(selectCurrentUserInfo);

    return (
        role !== 'Montatore'
        ?   <DialogComponent
                icon={<ClipboardDocumentCheckIcon className="w-6 h-6" />}
                label={label}
                content={<FormSetState row={row} />}
                isDisabled={(!row?.esitoConsulenza && !['SuperAdmin', 'Admin'].includes(role))}
                textTooltip="É richiesto un esito per effettuare quest'operazione"
            />
        : null
    )
}
