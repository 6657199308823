import moment from "moment-timezone";

export interface ICallSent {
    argomentoIntervista: string | null,
    note: string | null,
    esito: string | null,
    dataAppuntamento: string | null | any,
    interesseVideo: string | null,
    idOperatoreCreazione?: any
}

export function CallAdapter(call: any) {
    let date = moment.tz(call?.dataAppuntamento, "DD-MM-YYYY HH:mm", "Europe/Rome");
    let callMap: ICallSent;
    return callMap = {
            argomentoIntervista: call?.argomentoIntervista || null,
            note: call?.note || null,
            esito: call?.esito?.value || null,
            dataAppuntamento: call?.dataAppuntamento 
                ? date
                : null,
            interesseVideo: call?.interesseVideo?.value || null,
            idOperatoreCreazione: call?.idOperatoreCreazione ?  call?.idOperatoreCreazione?.value : null
        } 
}