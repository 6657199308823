
export const ReportOspitiListInputs = (optionsSede: any[]) => ([
    {
        label: 'Data da',
        name: 'DataDa',
        placeholder: 'Data da',
        type: 'text',
        validators: {
            required: 'Il campo è richiesto'
        },
        type_input: 'picker',
        size_input: 'w-full',
        isLimitDate: false,
        isTimeShow: false
    },
    {
        label: 'Data a',
        name: 'DataA',
        placeholder: 'Data a',
        type: 'text',
        validators: {
            required: 'Il campo è richiesto'
        },
        type_input: 'picker',
        size_input: 'w-full',
        isLimitDate: false,
        isTimeShow: false
    },
    {
        label: 'Sede intervista',
        name: 'Sede',
        placeholder: 'Sede intervista',
        type: 'text',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        isLimitDate: false,
        isTimeShow: false,
        options:  [{value: '', label: 'Qualsiasi'}].concat(optionsSede)
    }
])