import { useMemo, useState } from "react";
import { GetInteresse } from "../../helpers/CallsLabelHelpes";
import { DataFormatHelperTab } from "../../helpers/DataFormatHelper";
import ActionDetailArgoment from "./ActionDetail";
import DetailArgoment from "./DetailArgoment";
import EsitoLookUp from "../../lookup/Esito";

export default function CallsListComponent({ calls }: any) {

    const [isShowArgoment, setIsShowArgoment] = useState<boolean>(false);
    const [argument, setArgument] = useState<{note: string, argomento: string}>();
    const optionEsito = EsitoLookUp();

    const foundEsito = (esito: any) => {
        let op = optionEsito?.find((obj: any) => {
            return obj.value === esito;
        })
        
        return <>{op?.label}</>
    };

    return (
        <>
            {calls?.length == 0 && <p className="h-32 m-10 text-center">Nessuna chiamata presente</p>}
            {calls?.length > 0 &&
                <div className="flex flex-col m-8">
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                        {!isShowArgoment ?
                         <div className="overflow-hidden">
                                <table className="min-w-full">
                                    <thead className="border-b bg-white">
                                        <tr>
                                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">Argomento</th>
                                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">Note</th>
                                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">Interesse</th>
                                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">Esito</th>
                                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">Data chiamata</th>
                                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">Data appuntamento</th>
                                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">Nominativo</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {calls?.map((call: any, index: number) => (
                                            <tr key={`trList-${index}`} className="bg-white border-b">
                                                <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap inline-flex items-center">
                                                    <p className="w-24 truncate mr-8">{call?.argomentoIntervista}</p>
                                                    {/* <ActionDetailArgoment title={'Dettaglio argomento'} argument={call?.argomentoIntervista} methodParent={setIsShowArgoment} setArgument={setArgument} /> */}
                                                </td>

                                                <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                                                    <div className="inline-flex items-center">
                                                        <p className="w-24 truncate mr-4">{call?.note}</p>
                                                        <ActionDetailArgoment title={'Dettaglio argomento/note'} argument={{note: call?.note, argomento: call?.argomentoIntervista}} methodParent={setIsShowArgoment} setArgument={setArgument} />
                                                    </div>
                                                </td>

                                                <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">{GetInteresse(call?.interesseVideo)}</td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">{foundEsito(call?.esito)}</td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">{call?.creationDate ? DataFormatHelperTab(call?.creationDate) : ''}</td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">{call?.dataAppuntamento ? DataFormatHelperTab(call?.dataAppuntamento) : ''}</td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                                                    {call?.operatoreChiamataNominativo ? call?.operatoreChiamataNominativo : '-'}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            : <DetailArgoment argument={argument} methodParent={setIsShowArgoment} />
                        }
                        </div>
                    </div>
                </div>}
        </>
    )
}