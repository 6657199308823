import CheckboxInput from "../components/inputs/CheckboxInput"
import DatePickerInput from "../components/inputs/DatePickerInput"
import DefaultInput from "../components/inputs/DefaultInput"
import InputFile from "../components/inputs/InputFile"
import PasswordInput from "../components/inputs/PasswordInput"
import InputPhoneNum from "../components/inputs/PhoneInputNum"
import RadioBtnIput from "../components/inputs/RadioBtnInput"
import ReactSelectInput from "../components/inputs/ReactSelectInputs"
import TextAreaInput from "../components/inputs/TextAreaInput"


export default function InputsFields(props: any) {

    const CreateInput = (input: any) => {
        switch (input.type_input) {
            case 'input': return <DefaultInput input={input} form={props.form} key={input.name} type={props.typeView} isDisabled={props?.isDisabled} role={props?.role} listRole={props?.listaRole} />
            case 'text-area': return <TextAreaInput input={input} form={props.form} key={input.name} type={props.typeView} isDisabled={props?.isDisabled} role={props?.role} listRole={props?.listaRole} />
            case 'react-select': return <ReactSelectInput input={input} form={props.form} options={props.options} key={input.name} type={props.typeView} isDisabled={props?.isDisabled} role={props?.role} listRole={props?.listaRole} />
            case 'radioBtn': return <RadioBtnIput input={input} form={props.form} key={input.label} isDisabled={props?.isDisabled} role={props?.role} listRole={props?.listaRole} />
            case 'checkbox': return <CheckboxInput input={input} form={props.form} key={input.label} isDisabled={props?.isDisabled} role={props?.role} listRole={props?.listaRole}/>
            case 'password': return <PasswordInput input={input} form={props.form} key={input.label} isDisabled={props?.isDisabled} role={props?.role} listRole={props?.listaRole}/>
            case 'picker': return <DatePickerInput input={input} form={props.form} key={input.label} isDisabled={props?.isDisabled} role={props?.role} listRole={props?.listaRole}/>
            case 'file': return <InputFile input={input} form={props.form} key={input.label} isDisabled={props?.isDisabled} role={props?.role}/>
        }
    }

    return (
        props.arrFields.map((input: any) => {
                const Input = CreateInput(input);
                return Input;
        })
    )
}