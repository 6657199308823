import { QueryStringHelper } from "../../helpers/QueryHelper";
import { emptySplitApi } from "./InjectionEndpoint"

const productsService = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        
        getProducts: build.query({
            query: (params: any) => `/api/prodotti?${QueryStringHelper(params)}`,
            providesTags: ['products']
        }),

        getProductById: build.query({
            query: (productId: any) => `/api/prodotti/${productId}`,
            providesTags: ['products']
        }),

        updateProduct: build.mutation({
            query: ({id, ...rest}) => ({
                url: `/api/prodotti/${id}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['products']
        }),

        addProduct: build.mutation({
            query: (product) => ({
                url: `/api/prodotti`,
                method: 'POST',
                body: product
            }),
            invalidatesTags: ['products']
        }),

        deleteProduct: build.mutation({
            query: (id) => ({
                url: `/api/prodotti/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['products']
        }),
    }),
})

export const {
    useGetProductByIdQuery,
    useGetProductsQuery,
    useUpdateProductMutation,
    useAddProductMutation,
    useDeleteProductMutation
} = productsService;