import ActionListCall from "../../components/action/ActionListCall";
import ActionListInterview from "../../components/action/ActionListInterview";
import ActionUpdateRegistry from "../../components/action/ActionUpdateRegistry";
import UserRounded from "../../components/main/UserRounded";
import { DataFormatHelperTab, DataFormatHelperTabNoTime } from "../../helpers/DataFormatHelper";
import { listRegistryTab } from "../listTabs/RegistryListTabs";

export const HeaderHostRegistry = [
    {
        Header: "Cod. ospite",
        accessor: "codiceOspite",
        disableSortBy: true,
        Filter: false,
        customSort: false
    },
    {
        Header: "Nome",
        accessor: "nome",
        disableSortBy: true,
        Filter: false,
        customSort: false,
        width: 250,
        Cell: ({ row }: any) => (
            <div className="flex align-items-center">
            <UserRounded name={row?.original?.nome} lastname={row?.original?.cognome} />
            <div className="ml-3">
                <div className="truncate w-36">{row?.original?.nome} {row?.original?.cognome}</div>
                <div className="text-gray-500">Tel: {row?.original?.telefono}</div>
                <p className="truncate w-36">Az: {row?.original?.azienda}</p>
            </div>
        </div>
        )
    },
    {
        Header: "Regione",
        accessor: "regione",
        disableSortBy: true,
        Filter: false,
        customSort: false,
    },
    {
        Header: "Cat. Prof.",
        accessor: "categoriaProfessionale",
        disableSortBy: true,
        Filter: false,
        customSort: false,
        Cell: ({ row }: any) => (
            <> 
            <p className="truncate w-36">{row?.original?.categoriaProfessionale}</p>
            <p className="truncate w-36">{row?.original?.sottocategoriaProfessionale}</p>
            </>
        )
    },
    {
        Header: "Creatore",
        accessor: "createdByNominativo",
        disableSortBy: true,
        Filter: false,
        customSort: false,
    },
    {
        Header: "Creata",
        accessor: "creationDate",
        disableSortBy: true,
        Filter: false,
        customSort: false,
        Cell: ({ row }: any) => (
            DataFormatHelperTab(row?.original?.creationDate)
        )
    },
    {
        Header: "Modificata",
        accessor: "updated",
        disableSortBy: false,
        Filter: false,
        customSort: false,
        Cell: ({ row }: any) => (
            <>
                <p>{row?.original?.updatedDate ? DataFormatHelperTab(row?.original?.updatedDate) : '-'}</p>
                <p>{row?.original?.updatedByNominativo}</p>
            </>
        )
    },
    {
        Header: "",
        accessor: "action",
        disableSortBy: true,
        Filter: false,
        customSort: false,
        Cell: ({ row }: any) => (
            <div className="flex space-x-6 justify-end px-5">
                <ActionUpdateRegistry  row={row?.original} label={'info'} elementForTab={listRegistryTab(row?.original)} />
            </div>
        )
    }
]
