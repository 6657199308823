import moment from "moment-timezone";

export interface IFilterInterviews {
    NomeOspite: string,
    CognomeOspite: string,
    TelefonoOspite: string,
    Sede: {value: string, label: string} | any,
    CodiceOspite: string,
    Stato: {value: string, label: string} | any,
    Esito: {value: string, label: string} | any,
    NominativoOCodiceOpearatoreChiamataId: {value: string, label: string} | any,
    NominativoOCodiceConsulenteId: {value: string, label: string} | any,
    NominativoOCodiceSpeakerId: {value: string, label: string} | any,
    NominativoOCodiceMontatoreId: {value: string, label: string} | any,
    DataConsulenzaDa: string | any,
    DataConsulenzaA: string | any,
    DataIntervistaDa: string | any,
    DataIntervistaA: string | any,
    DataConsegnaVideoDa: string | any,
    DataConsegnaVideoA: string | any,
    RicercaGlobale: string | any,
    StatoVideo: string | any,
    MetodoPagamento: string |any,
    prodotto: {value: string, label: string} | any,
    StatoPagamento: string |any
}

export function InterviewAdapterFilterSent(interview: IFilterInterviews) {
    // let dateFromat = (date: string) => (moment.tz(date, "DD-MM-YYYY", "Europe/Rome"));
    let RegistryMap: IFilterInterviews;
    return RegistryMap = {
        NomeOspite: interview?.NomeOspite,
        CognomeOspite: interview?.CognomeOspite,
        TelefonoOspite: interview?.TelefonoOspite,
        Sede: interview?.Sede?.value,
        CodiceOspite: interview?.CodiceOspite,
        Stato: interview?.Stato?.value,
        Esito: interview?.Esito?.value,
        NominativoOCodiceOpearatoreChiamataId: interview?.NominativoOCodiceOpearatoreChiamataId?.value ? interview?.NominativoOCodiceOpearatoreChiamataId.value : '',
        NominativoOCodiceConsulenteId: interview?.NominativoOCodiceConsulenteId?.value ? interview?.NominativoOCodiceConsulenteId?.value : '',
        NominativoOCodiceSpeakerId: interview?.NominativoOCodiceSpeakerId?.value ? interview?.NominativoOCodiceSpeakerId?.value : '',
        NominativoOCodiceMontatoreId: interview?.NominativoOCodiceMontatoreId?.value ? interview?.NominativoOCodiceMontatoreId?.value : '',
        DataConsulenzaDa: interview?.DataConsulenzaDa ? moment.tz(interview?.DataConsulenzaDa, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        DataConsulenzaA: interview?.DataConsulenzaA ? moment.tz(interview?.DataConsulenzaA, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        DataIntervistaDa: interview?.DataIntervistaDa ? moment.tz(interview?.DataIntervistaDa, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        DataIntervistaA: interview?.DataIntervistaA ? moment.tz(interview?.DataIntervistaA, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        DataConsegnaVideoDa: interview?.DataConsegnaVideoDa ? moment.tz(interview?.DataConsegnaVideoDa, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        DataConsegnaVideoA: interview?.DataConsegnaVideoA ? moment.tz(interview?.DataConsegnaVideoA, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        RicercaGlobale: interview?.RicercaGlobale ?  interview?.RicercaGlobale : '',
        StatoVideo: interview?.StatoVideo?.value,
        MetodoPagamento: interview?.MetodoPagamento?.value,
        prodotto: interview?.prodotto?.value,
        StatoPagamento: interview?.StatoPagamento?.value
    }
}