import { useForm } from "react-hook-form"
import InputsFields from "../../helpers/InputFields";
import { useCreateAccountMutation, useGetAccountByIdQuery, useUpdateAccountMutation } from "../../store/services/UsersService";
import { passwordInput, Supervisore, UserRegion, UsersListInputs } from "../../utils/listInputs/UsersListInputs";
import Loader from "../main/Loader";
import { toast } from "react-toastify";
import { UserAdapterSent, UserAdapterValues } from "../../helpers/adapter/UserAdapter";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUserInfo } from "../../store/feautures/AuthSlice";
import { SeletRoleOption } from "../../utils/listInputs/ListRoleAccount";
import { skipToken } from '@reduxjs/toolkit/query/react'
import RegioniLookUp from "../../lookup/Regioni";
import { useNavigate } from "react-router-dom";
import { useGetUserRoleQuery } from "../../store/services/LookUpService";

export default function UsersForm({ infoForUpdate, type }: any) {

    const { data, isLoading, isSuccess } = useGetAccountByIdQuery(infoForUpdate?.id ?? skipToken);
    const form = useForm();
    const { handleSubmit, reset, watch } = form;
    const [addAccount, { isLoading: isAdding }] = useCreateAccountMutation();
    const [updateAccount, { isLoading: isUpdating }] = useUpdateAccountMutation();
    let actionLabel = type === 'add' ? 'aggiunto' : 'modificato';
    let roleSelected = watch('ruolo');
    const { role } = useSelector(selectCurrentUserInfo);
    const optionRegioni = RegioniLookUp(false);
    const navigate = useNavigate();
    const {isLoading: isLoadingAccount, data: accountList} = useGetUserRoleQuery(roleSelected?.value === 'Operatore'  ? {role: 'Supervisore'} : skipToken)
    const [listSuperVi, setListSuperV] = useState([])

    useEffect(() => {
        let listMap = accountList?.map((acc: any) => {
            return {value: acc?.id, label: acc?.nominativo }
        });

        setListSuperV(listMap);
    }, [accountList])

    useEffect(() => {
        if (isSuccess && data?.result) {
            let mapInfo = UserAdapterValues(data?.result, listSuperVi);
            reset(mapInfo);
        }
    }, [data, isSuccess])

    const handleSubmitUserData = async (data: any) => {
        let result: any;
        let objMap = UserAdapterSent(data);

        if (type === 'add') {
            result = await addAccount(objMap);
        }

        if (type === 'update') {
            result = await updateAccount({ id: infoForUpdate?.id, ...objMap });
        }

        if ('error' in result) {
            toast.error(`Utente non ${actionLabel}! Errore: ${result?.error?.data?.detail}`)
        } else {
            toast.success(`Utente ${actionLabel}`)
            navigate('/dashboard/users')
        }
    }

    return (
        <>
            {(isUpdating || isAdding || isLoading || isLoadingAccount) && <Loader />}
            <form onSubmit={handleSubmit(handleSubmitUserData)} className="my-6">
                <div className="grid grid-cols-2 grid-rows-none gap-10 p-10">
                    <InputsFields arrFields={UsersListInputs(SeletRoleOption[role], type === 'update' ? true : false)} form={form} />
                    {roleSelected?.value === 'Operatore' ? <InputsFields arrFields={Supervisore(listSuperVi)} form={form} /> : null}
                    {roleSelected?.value === 'Consulente' || roleSelected?.value === 'Speaker' ? <InputsFields arrFields={UserRegion(optionRegioni)} form={form} /> : null}
                    {type !== 'update' &&
                        <div>
                            <InputsFields arrFields={passwordInput} form={form} />
                            <ul className="list-none text-sm my-4">
                                <li>- almeno 6 caratteri</li>
                                <li>- almeno 1 non alfanumerico</li>
                                <li>- almeno 1 digit (0-9)</li>
                                <li>- almeno una maiuscola</li>
                            </ul>
                        </div>
                    }
                </div>
                <div className="flex justify-end my-8 p-10">
                    <button className="h-10 bg-[#064e3b] text-white px-5 w-64 rounded-lg text-md">Salva</button>
                </div>
            </form>
        </>

    )
}