import moment from "moment-timezone";
import { MethodList } from "../../utils/listInputs/InterviewListInputFormUpdate";

export interface IProdotto {
  id: string,
  dataConsegnaVideo: null | string,
  dataMaterialiRicevuti: string | null,
  intervistaId: number
  materialiRicevuti: true
  montatore: any,
  montatoreId: number,
  nomeProdotto: string,
  noteMontatore: null | string,
  prezzo: number
  prodottoId: number
}

export interface IPayment {
  id: string,
  metodo: string,
  data: string,
  importo: number,
  fatturaInviata: boolean | null
}

export function NewAdapterInterviewSent(interview: any) {
  let interviewMap = {
    dataConsulenza: interview?.dataConsulenza ? moment.tz(interview?.dataConsulenza, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : null,
    esitoConsulenza: interview?.esitoConsulenza?.value,
    dataIntervista: interview?.dataIntervista ? moment.tz(interview?.dataIntervista, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : null,
    consulenteId: interview?.consulenteId?.value,
    speakerId: interview?.speakerId?.value,
    notePreIntervista: interview?.notePreIntervista,
    // prodottoDaAggiungereNome: interview?.prodottoDaAggiungereNome,
    // prodottoDaAggiungerePrezzo: interview?.prodottoDaAggiungerePrezzo ? Number(interview?.prodottoDaAggiungerePrezzo.replace(',','.')) : null,
    ragioneSociale: interview?.ragioneSociale,
    codFiscPiva: interview?.codFiscPiva,
    pecCodSdi: interview?.pecCodSdi,
    nazione: interview?.nazione?.value || 'IT',
    provincia: interview?.provincia?.value || null,
    citta: interview?.citta?.value || null,
    cap: interview?.cap,
    indirizzo: interview?.indirizzo,
    noteSpeaker: interview?.noteSpeaker ? interview?.noteSpeaker : null,
    tipologiaIvaId: interview?.tipologiaIvaId?.value ? interview?.tipologiaIvaId?.value : null,
    codUpa: interview?.codUpa ? interview?.codUpa : null,

    // PRODOTTI
    prodotti: interview?.prodotti ? interview.prodotti.map((prodotto: any) => (
      {
        id: prodotto?.id ? prodotto.id : 0,
        prodottoId: prodotto?.prodotto?.value,
        prezzo: prodotto?.prezzo ? Number(prodotto?.prezzo?.toString()?.replace(',','.')) : null,
        montatoreId: prodotto?.montatore?.value,
        noteMontatore: prodotto?.noteMontatore,
        dataMaterialiRicevuti: prodotto?.dataMaterialiRicevuti ? moment.tz(prodotto?.dataMaterialiRicevuti, "DD-MM-YYYY", "Europe/Rome") : null,
        dataConsegnaVideo: prodotto?.dataConsegnaVideo ? moment.tz(prodotto?.dataConsegnaVideo, "DD-MM-YYYY", "Europe/Rome") : null,
      }
     )) : {},

    // PAGAMENTI
    pagamenti: interview?.pagamenti ? interview.pagamenti.map((pagamento: any) => {
      return {
        id: pagamento?.id ? pagamento.id : 0,
        metodo: pagamento?.metodo?.value,
        importo: pagamento?.importo ? Number(pagamento?.importo?.toString()?.replace(',','.')) : null,
        data: pagamento?.data ? moment.tz(pagamento?.data, "DD-MM-YYYY", "Europe/Rome") : null,
      }
    }) : {},

  }

  return interviewMap;

}

export const EmpytyProd = {
  prodotto: '',
  prezzo: '',
  montatoreId: '',
  noteMontatore: '',
  dataMaterialiRicevuti: '',
  dataConsegnaVideo: ''
}

interface Prodotti {
  prodotto: any,
  prezzo: any,
  montatoreId: any,
  noteMontatore: any,
  dataMaterialiRicevuti: any,
  dataConsegnaVideo: any,
}

export class NewAdapterInterviewFromServer {
  dataConsulenza: any;
  esitoConsulenza: any;
  dataIntervista: any;
  consulenteId: any;
  speakerId: any;
  notePreIntervista: any;
  ragioneSociale: any;
  codFiscPiva: any;
  pecCodSdi: any;
  provincia: any;
  citta: any;
  cap: any;
  indirizzo: any;
  noteSpeaker: any;
  tipologiaIvaId: any;
  codUpa?: any;
  prodotti: Prodotti;
  pagamenti: IProdotto;
  nazione: any;
  
  constructor(interview: any, optionIva: any[], nazioni: any[]) {
      this.dataConsulenza = interview?.dataConsulenza ? moment(interview?.dataConsulenza).format('DD-MM-YYYY HH:mm') : '';
      this.esitoConsulenza = interview?.esitoConsulenza ? {value: interview?.esitoConsulenza, label: interview?.esitoConsulenza} : '';
      this.dataIntervista = interview?.dataIntervista ? moment(interview?.dataIntervista).format('DD-MM-YYYY HH:mm') :'';
      this.consulenteId = interview?.consulente ? { value: interview?.consulente?.id, label: `${interview?.consulente?.nome} ${interview?.consulente?.cognome}` } : '';
      this.speakerId = interview?.speaker ? { value: interview?.speaker?.id, label: `${interview?.speaker?.nome} ${interview?.speaker?.cognome}` } : '';
      this.notePreIntervista = interview?.notePreIntervista;
      this.ragioneSociale = interview?.ragioneSociale;
      this.codFiscPiva = interview?.codFiscPiva;
      this.pecCodSdi = interview?.pecCodSdi;
      this.provincia = interview?.provincia ? { value: interview?.provincia, label: interview?.provincia} : '';
      this.citta = interview?.citta ? { value: interview?.citta, label: interview?.citta} : '';
      this.cap = interview?.cap;
      this.indirizzo = interview?.indirizzo;
      this.noteSpeaker = interview?.noteSpeaker ? interview?.noteSpeaker : null;
      this.tipologiaIvaId = interview?.tipologiaIvaId ? optionIva?.find(ele => ele?.value === interview?.tipologiaIvaId) : optionIva?.length > 0 ? optionIva[0] : "";
      this.codUpa = interview?.codUpa ? interview?.codUpa : null;      
      this.nazione = interview?.nazione 
        ? nazioni?.find(nazione =>  nazione?.value === interview?.nazione)
        : nazioni?.find(nazione =>  nazione?.label === "Italia")
      
      // PRODOTTI
      this.prodotti = interview?.prodotti ? interview.prodotti.map((prodotto: IProdotto) => (
        {
          id: prodotto?.id,
          prodotto: prodotto?.nomeProdotto ? { label: prodotto?.nomeProdotto, price: prodotto?.prezzo, value: prodotto?.prodottoId } : '',
          prezzo: prodotto?.prezzo ? Number(prodotto?.prezzo.toString().replace(',', '.')) : null,
          montatore: prodotto?.montatore ? { value: prodotto?.montatore?.id, label: `${prodotto?.montatore?.nome} ${prodotto?.montatore?.cognome}` } : '',
          noteMontatore: prodotto?.noteMontatore,
          dataMaterialiRicevuti: prodotto?.dataMaterialiRicevuti ? moment(prodotto?.dataMaterialiRicevuti).format('DD-MM-YYYY') : '',
          dataConsegnaVideo: prodotto?.dataConsegnaVideo ? moment(prodotto?.dataConsegnaVideo).format('DD-MM-YYYY') : '',
        }
      )) : {};

    // PAGAMENTI
      this.pagamenti = interview?.pagamenti ? interview.pagamenti.map((pagamento: IPayment) => (
        {
          id: pagamento.id,
          metodo: MethodList.find((metodo) => metodo.value === pagamento?.metodo),
          importo: pagamento?.importo ? Number(pagamento?.importo.toString().replace(',','.')) : null,
          data: pagamento?.data ? moment(pagamento?.data).format('DD-MM-YYYY') : '',
          fatturaInviata: pagamento?.fatturaInviata
        }
      )) : {}
  }
}