import Loader from "../../components/main/Loader";
import { saveAs } from 'file-saver';
import { useGetReportFatturatoMutation } from "../../store/services/ReportService";
import { toast } from "react-toastify";
import ReportFatturatoFilters from "../../components/filters/ReportFatturatoFilters";
import { AdapterFilterFatturato } from "../../helpers/adapter/filters/AdapterFilterFatturato";
import { useGetUserRoleQuery } from "../../store/services/LookUpService";

export default function ReportFatturato() {
    let { data: consulenteData, isLoading: isLoadingConsu } = useGetUserRoleQuery({role: 'Consulente' });
    const [getReportFatturato, requestReport] = useGetReportFatturatoMutation()

    const handleSetFilter = async (dataFilter: any) => {
        const mapFilter: any = AdapterFilterFatturato(dataFilter);
        let result: any = await getReportFatturato(mapFilter);

        if ('error' in result) {
            console.log('ERROR DOWNLOAD')
        } else {
            saveAs(result?.data, `Fatturato_${Date.now()}.xlsx`)
            toast.success(`Report scaricato`);
        }
    }

    return(
        <>
            {isLoadingConsu || requestReport.isLoading ? <Loader /> : null}
            <ReportFatturatoFilters methodParent={handleSetFilter} consulenteData={consulenteData} />
            {requestReport?.isError 
                ? 
                <div className="mt-8 bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                    <p className="font-bold">Attenzione</p>
                    <p>Nessun risultato presente per i parametri selezionati</p>
                </div>
                : null
            }
        </>
    )
}
