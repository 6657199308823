import ActionUpdate from "../../components/action/ActionUpdate";
import ActiveAccount from "../../components/action/ActiveAccount";
import DisableAccount from "../../components/action/DisableAccount";
import ResetPassword from "../../components/action/ResetPassword";
import UserRounded from "../../components/main/UserRounded";

export const HeaderUser = [
    {
        Header: "Codice utente",
        accessor: "codiceUtente",
        disableSortBy: true,
        Filter: false,
        customSort: false
    },
    {
        Header: "Nome",
        accessor: "nome",
        disableSortBy: true,
        Filter: false,
        customSort: false,
        Cell: ({ row }: any) => (
            <div className="flex items-center">
            <UserRounded name={row?.original?.nome} lastname={row?.original?.cognome} />
            <div className="ml-3">
                <div className="">{row?.original?.nome} {row?.original?.cognome}</div>
                <div className="text-gray-500">{row?.original.email}</div>
            </div>
        </div>
        )
    },
    {
        Header: "Regione",
        accessor: "regione",
        disableSortBy: true,
        Filter: false,
        customSort: false,
    },
    {
        Header: "Ruolo",
        accessor: "ruolo",
        disableSortBy: true,
        Filter: false,
        customSort: false,
    },
    {
        Header: "",
        accessor: "action",
        disableSortBy: true,
        Filter: false,
        customSort: false,
        Cell: ({ row }: any) => (
            <div className="flex space-x-6 justify-end px-5">
                {!row?.original?.disabled && <ActionUpdate row={row?.original} contentType={'user'} label={'Modifica utente'} />}
                {
                    row?.original?.disabled 
                    ? <ActiveAccount row={row?.original} />
                    : <DisableAccount row={row?.original} />
                }
                {!row?.original?.disabled && <ResetPassword row={row?.original}/>}
            </div>
        )
    }
]
