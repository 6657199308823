
export interface IPagination {
    CurrentPage: number,
    PageSize: number,
    sort: string,
    sortIdCol: string | null,
    sortDirection: string | null,
}

export function PaginationHelper(queryParams: any, data: any) {

    const updateParams: IPagination = {...queryParams};

    if(data?.pageSize || data?.pageIndex) {
        updateParams.PageSize = data?.pageSize;
        updateParams.CurrentPage = data?.pageIndex;
    }

    if(data?.col) {
        let sortDirection = data?.directionSort === 'DESC' ? `-${data?.col.id}` : data?.directionSort === 'ASC' ? data?.col.id : '-createdAt';
        updateParams.sort = sortDirection;
        updateParams.sortIdCol = data.col.id;
        updateParams.sortDirection = data?.directionSort;
    }

    return updateParams
}

export const initialParams: IPagination = {
    CurrentPage: 1,
    PageSize: 10,
    sort: '',
    sortIdCol: null,
    sortDirection: null
};

export const forSelet: IPagination = {
    CurrentPage: 1,
    PageSize: 500,
    sort: '-createdAt',
    sortIdCol: null,
    sortDirection: null
};