// import { ChevronLeftIcon, PlusIcon } from "@heroicons/react/24/outline";
// import { useState } from "react";
import { useSelector } from "react-redux";
// import EsitoLookUp from "../../lookup/Esito";
// import InteresseVideoLookUp from "../../lookup/InteresseVideo";
import { selectCurrentUserInfo } from "../../store/feautures/AuthSlice";
import { useGetHostRegistryByIdQuery } from "../../store/services/HostRegistryService";
import CallsListComponent from "../calls/CallsListComponent";
// import CallsForm from "../form/CallForm";
import Loader from "../main/Loader";

export default function CallListComponent({ row }: any) {

    const { data, isLoading } = useGetHostRegistryByIdQuery(row?.id);
    // let [isAddCall, setIsAddCall] = useState<boolean>(false);
    const { role } = useSelector(selectCurrentUserInfo);
    // let lastCall = data?.result?.chiamate?.slice(-1)[0];
    // let optionEsito = EsitoLookUp();
    // let optionInteresseVideo = InteresseVideoLookUp();

    return (
        <div className="relative">
            {isLoading && <Loader />}
            {/* <div className="flex items-center justify-end mx-8 my-6">
                 {!isAddCall && role !== 'Supervisore' && 
                    <button  className="w-auto text-gray-400 px-5 border-none flex items-center justify-center" onClick={() => setIsAddCall(true)}>
                        <PlusIcon className="w-5 h-5 mr-4" />
                        Aggiungi chiamata
                    </button>
                }
                {isAddCall &&
                    <button  className="w-auto text-gray-400 px-5 border-none flex items-center justify-center" onClick={() => setIsAddCall(false)} >
                        <ChevronLeftIcon className="w-5 h-5 mr-4" />
                        Torna alla lista
                    </button>
                }
            </div> */}
            {/* {!isAddCall
                ? <CallsListComponent calls={data?.result?.chiamate} />
                : <CallsForm methodParent={setIsAddCall} infoRegistry={row} type={'add'} lastCall={lastCall} optionEsito={optionEsito} optionInteresseVideo={optionInteresseVideo} />
            } */}

            <CallsListComponent calls={data?.result?.chiamate} />
        </div>
    )
}