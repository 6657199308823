import Loader from "../../components/main/Loader";
import { saveAs } from 'file-saver';
import { useDownloadReportOspitiMutation } from "../../store/services/ReportService";
import { toast } from "react-toastify";
import ReportOspitiFilters from "../../components/filters/ReportOspitiFilters";
import { AdapterReportOspiti } from "../../helpers/adapter/filters/AdapterReportOspiti";

export default function ReportOspiti() {
    const [downloadReportOspiti, requestReport] = useDownloadReportOspitiMutation()

    const handleSetFilter = async (dataFilter: any) => {
        const mapFilter: any = AdapterReportOspiti(dataFilter);
        let result: any = await downloadReportOspiti(mapFilter);

        if ('error' in result) {
            console.log('ERROR DOWNLOAD')
        } else {
            saveAs(result?.data, `StatisticheOspiti_${Date.now()}.xlsx`)
            toast.success(`Report scaricato`);
        }
    }

    return(
        <>
            {requestReport.isLoading ? <Loader /> : null}
            <ReportOspitiFilters methodParent={handleSetFilter} />
            {requestReport?.isError 
                ? 
                <div className="mt-8 bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                    <p className="font-bold">Attenzione</p>
                    <p>Nessun risultato presente per i parametri selezionati</p>
                </div>
                : null
            }
        </>
    )
}
