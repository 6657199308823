import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import InputsFields from "../../helpers/InputFields";
import { initialStateAuth, setCredentials } from "../../store/feautures/AuthSlice";
import { useLoginMutation } from "../../store/services/AuthService";
import { LoginListInput } from "../../utils/listInputs/LoginList";
import Loader from "../main/Loader";
import { injectStyle } from "react-toastify/dist/inject-style";
import jwt_decode from "jwt-decode";

export default function LoginForm() {

    const form = useForm();
    const {handleSubmit} = form;
    const [login, result] = useLoginMutation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // CALL IT ONCE IN YOUR APP
    if (typeof window !== "undefined") {
        injectStyle();
    }

    const submitDataForm = async (data: any ) => {
        try{
            const userData: any = await login(data).unwrap();
            let jwtDecode: any = jwt_decode(userData?.accessToken);
            let name = jwtDecode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];
            let lastname = jwtDecode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'];
            let role = jwtDecode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
            let id = jwtDecode["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];          
            dispatch(setCredentials({token: userData?.accessToken, refreshToken: userData?.refreshToken, name: name, lastname: lastname, role: role, id: id}))
            navigate("/dashboard")
        } catch {
            dispatch(setCredentials(initialStateAuth));
            toast.error(`Ops: controlla le tue credenziali`)
        }
    }

    return (
        <>
        {result?.isLoading && <Loader />}
        <form onSubmit={handleSubmit(submitDataForm)} className="my-12 min-w-full space-y-6">
            <InputsFields arrFields={LoginListInput} form={form} />
            <button className="px-6 py-2 w-full mt-4 text-white bg-[#064e3b] rounded-lg text-md">Login</button>
        </form>
        </>
    )
}