import { emptySplitApi } from "./InjectionEndpoint"


const authApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
      login: build.mutation({
        query: (data) => ({
            url: '/api/auth/login',
            method: 'POST',
            body: data 
        }),
      }),
    }),
  })
  
  export const { useLoginMutation } = authApi;