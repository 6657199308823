import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

export default function ActionDeleteProd({ closeView, onRemove, index }: any) {

    const [isOpen, setIsOpen] = useState(true);

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => null}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="relative w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Elimina pagamento
                                </Dialog.Title>
                                <p className="text-md text-gray-500 my-4">
                                    Per questo pagamento è già stata inviata la fattura sei sicuro di volerlo eliminare?
                                    Se clicchi su conferma ricordati di salvare l'intervista.
                                </p>

                                <div className="mt-4 grid grid-cols-2 space-x-4">
                                    <button onClick={() => { closeView(false); setIsOpen(false); }} type="button" className="h-10 border text-[#064e3b] border-[#064e3b] text-white px-2 inline rounded-lg text-md">
                                        Annulla
                                    </button>
                                    <button onClick={() => { setIsOpen(false); onRemove(index) }} type="button" className="h-10 bg-[#064e3b] text-white px-2 inline rounded-lg text-md">
                                        Conferma
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}