import { useState } from "react";
import ProductsFilters from "../../components/filters/ProductsFilter";
import { IPagination, PaginationHelper, initialParams } from "../../helpers/PaginationHelper";
import TableComponent from "../../components/main/Table";
import { HeaderProducts } from "../../utils/headerTable/TabHeaderProducts";
import ActionProduct from "../../components/action/products/ActionProducts";
import { useGetProductsQuery } from "../../store/services/ProductsService";
import Loader from "../../components/main/Loader";

export default function ProductsPage() {

    const [queryParams, setQueryParams] = useState<IPagination>(initialParams);
    const [filterParams, setFilterParams] = useState<any>({});
    const {isSuccess, isLoading, data, isFetching } = useGetProductsQuery({...queryParams, ...filterParams});

    const handleActionTable = (data: any) => {
        let paramsPagination: any = PaginationHelper(queryParams, data);
        setQueryParams(paramsPagination);
    }

    const handleSetFilter = (dataFilter: any) => {
        setFilterParams(dataFilter);
        setQueryParams(initialParams);
    }

    return(
        <div>
            {(isLoading || isFetching) && <Loader />}
            <h2 className="text-xl my-6">Prodotti</h2>
            <ProductsFilters methodParent={handleSetFilter} />

            <div className="flex items-center justify-end pt-14">
                <ActionProduct type={"add"} />
            </div>

            <div className="mt-6">
            {isSuccess && data?.result.length > 0 
                ? <TableComponent columns={HeaderProducts} data={data?.result} meta={data?.meta} parentMethod={handleActionTable} pageIndex={queryParams?.CurrentPage} pageSizeParent={queryParams?.PageSize} sortDirectionParent={queryParams?.sortDirection} currentSortCol={queryParams?.sortIdCol} /> 
                : <p className="p-6 text-center">NESSUN PRODOTTO PRESENTE</p>
            }
            </div>
        </div>
    )
}