import { TrashIcon } from "@heroicons/react/24/outline";
import { useMemo } from "react";
import { useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";
import { EmpytyProd } from "../../helpers/adapter/InterviewAdapter";
import InputsFields from "../../helpers/InputFields";
import { selectCurrentUserInfo } from "../../store/feautures/AuthSlice";
import { IntervieCons } from "../../utils/genericList/ListRoleRoutes";
import { ProductsInput } from "../../utils/listInputs/InterviewListInputFormUpdate";

export default function FormArrayProduct({ optionProduct, role, form , optionMontatore}: any) {

    const { control } = form;
    const userInfo = useSelector(selectCurrentUserInfo);
    const {
        fields,
        append,
        remove
    }: any = useFieldArray({
        control,
        name: 'prodotti'
    });

    return (
        <>
            <div className="flex items-center justify-between">
                <p className='text-[#064E3B]'>Prodotti</p>
                {IntervieCons.includes(role) ? <button className="text-gray-400 text-md" type='button' onClick={() => append(EmpytyProd)}>
                    Aggiungi prodotto
                </button> : null}
            </div>

                {
                    fields.map(({id}: any, index: number) => (
                        <fieldset name={`prodotti[${index}]`} key={id} className='text-[#064E3B] border rounded-lg my-2'>
                            <legend className="mx-5">{`Prodotto ${index + 1}`}</legend>
                            <div className="grid grid-cols-3 gap-x-6 gap-y-6 p-5 pr-10 relative">
                                <InputsFields arrFields={ProductsInput(`prodotti[${index}]`, optionProduct, optionMontatore, role, userInfo?.id, fields[index]?.montatore?.value)} form={form} />
                                {IntervieCons.includes(role) ? <button type="button" className="w-5 top-7 right-10 absolute" onClick={() => remove(index)}>
                                    <TrashIcon className="w-5 h-5" />
                                </button> : null}
                            </div>
                        </fieldset>
                    ))
                }
        </>
    );
}