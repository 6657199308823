
import { useState } from "react";
import { Outlet } from "react-router-dom"
import SideNav from "../../components/main/SideNav";
import {ToastContainer} from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import Toolbar from "../../components/main/Toolbar";

export default function MainPage() {

  // CALL IT ONCE IN YOUR APP
  if (typeof window !== "undefined") {
    injectStyle();
  }

  const [isOpen, setIsOpen] = useState<boolean>(true);

    return(
        <div className="flex h-screen overflow-hidden relative">

        {/* Sidebar */}
        <SideNav openSidenav={setIsOpen} isOpen={isOpen} />
  
        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-[#f1f5f9]">
          {/*  Site header */}
          <Toolbar isOpen={isOpen} />
  
          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-20 w-full max-w-9xl mx-auto">
              <Outlet />
            </div>
          </main>

          {/* MESSAGE ACTION */}
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    )
}