import moment from "moment-timezone"

export interface IFiltersCallsAdapter {
    CategoriaProfessionaleId?: any | {value: string, label: string},
    NominativoOAziendaOspite: string | null,
    CodiceOspite?: string | null,
    DataChiamataDa?: string | '' | any,
    DataChiamataA: string | '' | any,
    DataAppuntamentoDa?: string | '' | any,
    DataAppuntamentoA?: string | '' | any,
    Esito?: any | {value: string, label: string},
    NominativoOCodiceOperatoreCreazioneId?: any | {value: string, label: string},
    RegioneOspite?: any | {value: string, label: string},
    TelefonoOspite: string
}

export const FiltersCallsAdapter = (filtersCall: IFiltersCallsAdapter) => {
    let objAdapter = {
        CategoriaProfessionaleId: filtersCall.CategoriaProfessionaleId?.value ? filtersCall.CategoriaProfessionaleId?.value : '',
        NominativoOAziendaOspite: filtersCall.NominativoOAziendaOspite,
        CodiceOspite: filtersCall.CodiceOspite,
        DataAppuntamentoDa: filtersCall.DataAppuntamentoDa ? moment.tz(filtersCall?.DataAppuntamentoDa, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        DataAppuntamentoA: filtersCall?.DataAppuntamentoA ? moment.tz(filtersCall?.DataAppuntamentoA, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        DataChiamataDa: filtersCall.DataChiamataDa ? moment.tz(filtersCall?.DataChiamataDa, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        DataChiamataA: filtersCall.DataChiamataA ? moment.tz(filtersCall?.DataChiamataA, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        Esito: filtersCall.Esito?.value ? filtersCall.Esito?.value : "",
        NominativoOCodiceOperatoreCreazioneId: filtersCall?.NominativoOCodiceOperatoreCreazioneId?.value ? filtersCall.NominativoOCodiceOperatoreCreazioneId?.value : '',
        RegioneOspite: filtersCall.RegioneOspite?.value ? filtersCall.RegioneOspite?.value : "",
        TelefonoOspite: filtersCall.TelefonoOspite
    }

    return objAdapter
}