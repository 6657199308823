import { IdentificationIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { CurrentInterview } from "../../store/feautures/CurrentInterviewSlice";
import { listInterviewTab } from "../../utils/listTabs/InterviewListTabs";
import ActionButtonDialogRow from "../main/ActionButtonDialogRow";
import DialogComponent from "../main/DIalogComponent";

export default function ActionOpenDialogInterview({ row, label, type}: any) {

    let rowMap = useSelector(CurrentInterview);
    let elementForTab = listInterviewTab(rowMap)

    return (
        <DialogComponent
            icon={type ? <IdentificationIcon className="w-6 h-6" /> : <PencilSquareIcon className="w-6 h-6" />}
            label={label}
            content={<ActionButtonDialogRow elementForTab={elementForTab} />}
        />
    )
}