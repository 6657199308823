import { ChevronDownIcon, ChevronUpIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { IFilterRegistry } from "../../helpers/adapter/filters/AdapterFilterRegistry";
import InputsFields from "../../helpers/InputFields";
import RegioniLookUp from "../../lookup/Regioni";
import { selectCurrentUserInfo } from "../../store/feautures/AuthSlice";
import { useGetUserRoleQuery } from "../../store/services/LookUpService";
import { SuperAdminVisore } from "../../utils/genericList/ListRoleRoutes";
import { CheckOperator, HostRegistryFiltersList, RolesFilterRegistry } from "../../utils/listInputs/HostRegistryFiltersList";
import Loader from "../main/Loader";

export default function HostRegistryFilters({ methodParent }: any) {
    const {isLoading, data} = useGetUserRoleQuery('')
    const { role } = useSelector(selectCurrentUserInfo);
    const form = useForm({defaultValues: {globalSearch: role === 'Operatore' ? true : false}});
    const [isOpenFilter, setIsOpenFilter] = useState<boolean>(true);
    const { handleSubmit, reset } = form;
    const anyValue = [{value: '', label: 'Qualsiasi'}]
    const optionRegioni = anyValue.concat(RegioniLookUp(false));

    let optionNominativo: any[] = useMemo(() => {
        return data?.map((user: any) => ({value: user?.id, label: `${user?.nominativo}(${user?.codiceUtente})`}))
    }, [data]);

    let optionGestore: any[] = useMemo(() => {
        return data?.filter((user: any) => user.ruolo === 'Operatore').map((user: any) => ({value: user?.id, label: `${user?.nominativo}(${user?.codiceUtente})`}))
    }, [data]);

    const InitialValueFilterUser: IFilterRegistry = {
        Nominativo: null,
        CodiceOspite: null,
        Regione: null,
        Email: null,
        Ruolo: null,
        Telefono: null,
        NominativoOCodiceOperatoreCreazioneId: "",
        NominativoOCodiceOperatoreGestioneId: "",
        globalSearch: false,
        DataCreazioneDa: "",
        DataCreazioneA: ""
    }

    return (
        <div className="bg-white shadow-lg rounded-lg">
            <div className="flex justify-between items-center p-4" >
                <p>Filtri</p>
                <button onClick={() => setIsOpenFilter(!isOpenFilter)}>
                    {isOpenFilter ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />}
                </button>
            </div>
            {isOpenFilter && <form onSubmit={handleSubmit(methodParent)}>
                <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 grid-rows-none gap-6 p-4 relative">
                    {isLoading ? <Loader /> : null}
                    <InputsFields arrFields={HostRegistryFiltersList(optionRegioni)} form={form} />
                    {SuperAdminVisore.includes(role) && <InputsFields arrFields={RolesFilterRegistry(optionNominativo, optionGestore)} form={form} />}
                    {/* <InputsFields arrFields={CheckRegistry} form={form} /> */}
                    {role === 'Operatore' && <InputsFields arrFields={CheckOperator} form={form} />}
                </div>

                <div className="flex items-center justify-end space-x-5 p-5">
                    <button className="w-5 h-5" onClick={() => reset(InitialValueFilterUser)}>
                        <FunnelIcon className="w-5 h-5" />
                    </button>
                    <button className="h-10 w-52 bg-[#064e3b] text-white rounded-lg px-10 text-md">
                        Cerca
                    </button>
                </div>

            </form>}
        </div>
    )
}