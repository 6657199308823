import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { NewAdapterInterviewFromServer, NewAdapterInterviewSent } from "../../helpers/adapter/InterviewAdapter";
import { AlertPaymentImport, FormAlert } from "../../helpers/FormAlert";
import InputsFields from "../../helpers/InputFields";
import EsitoConsulenzaLookUp from "../../lookup/EsitoConsulenza";
import ProdottiLookUp from "../../lookup/Prodotti";
import { selectCurrentUserInfo } from "../../store/feautures/AuthSlice";
import { useGetInterviewsByIdQuery, useUpdateInterviewMutation } from "../../store/services/InterviewService";
import { useGetUserRoleQuery } from "../../store/services/LookUpService";
import { useGetCittaQuery, useGetProvincieQuery } from "../../store/services/ProvinceService";
import { IntervieAdmin, IntervieCons, IntervieSpeak, InterviewSpeack } from "../../utils/genericList/ListRoleRoutes";
import { Address, capInput, CittaInput, ConsulentiListOp, InfoAddresses, InputNoteSpeack, InterviewListInputFormUpdate, Iva, Nazione, NotePre, Provincia, SpeackListOp, Upa } from "../../utils/listInputs/InterviewListInputFormUpdate";
import FormArrayPayment from "../inputs/FormArrayPayment";
import FormArrayProduct from "../inputs/FormArrayProduct";
import Loader from "../main/Loader";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { toast } from "react-toastify";
import ActionConfirm from "../action/ActionConfirm";
import { setIsOpenDialogAction } from "../../store/feautures/DialogActionSlice";

export default function FormInterview({ row, optionIva, nazioni }: any) {
    
    const {role} = useSelector(selectCurrentUserInfo);
    const[isPartialAmount, setPartialAmount] = useState<boolean>(false);
    const[isProdMag, setProdMag] = useState<boolean>(false);
    let { isLoading: getIsLoading, data: getData, isSuccess: isSuccessGet } = useGetInterviewsByIdQuery(row?.interviewId);
    const form = useForm({mode: 'all'});
    const { handleSubmit, reset, watch, setValue, formState: {dirtyFields, isValid, errors} } = form;
    let { data, isLoading } = useGetUserRoleQuery({role: 'Speaker'});
    let { data: montatoreData, isLoading: loadingUser } = useGetUserRoleQuery({role: 'Montatore' });
    let { data: consulenteData, isLoading: loadingConsulente } = useGetUserRoleQuery({role: 'Consulente' });
    let optionSpeaker = data?.map((user: any) => ({ value: user?.id, label: user?.nominativo }));
    let optionMontatore = montatoreData?.map((montatore: any) => ({ value: montatore?.id, label: montatore?.nominativo }));
    let optionConsulente = consulenteData?.map((consulente: any) => ({ value: consulente?.id, label: consulente?.nominativo }));
    let optionProduct = ProdottiLookUp();
    let optionEsito = EsitoConsulenzaLookUp();
    let nazioneInput: any = watch('nazione')?.value;
    let provinciaInput: any = watch('provincia');
    let cittaInput: any = watch('citta');
    let dateConsulenza: any = watch('dataConsulenza');
    let tipologiaIvaId = watch('tipologiaIvaId');
    const [updateInterview, request] = useUpdateInterviewMutation();
    const [timeChangeInput, setTimeChangeInput] = useState(0);
    const [relodForm, setReloadForm] = useState(false);
    const {data: dataProvincie, isLoading: loadingProvincie}: any = useGetProvincieQuery(!relodForm ? '' : skipToken);
    const dispatch = useDispatch();
    // Logica citta provincia
    const {data: dataCitta, isLoading: loadingCitta}: any = useGetCittaQuery(provinciaInput?.value ? provinciaInput?.value : skipToken);

    let provincie: any = useMemo(() => {
        return dataProvincie?.map((provincia: any) => ({value: provincia.nome, label: provincia.nome, sigla: provincia.sigla}))
    }, [dataProvincie])

    let citta: any = useMemo(() => {
        return dataCitta?.map((citta: any) => ({value: citta.nome, label: citta.nome, cap: citta.cap}))
    }, [dataCitta]);

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if((isSuccessGet && !relodForm) || request?.isSuccess) {
            const defaultValues = new NewAdapterInterviewFromServer(getData?.result, optionIva, nazioni);
            reset({...defaultValues});
        }
        setTimeout(()=> {setLoader(false)}, 1500);
    }, [getData, relodForm]);
    
    useEffect(() => {
        if(nazioneInput?.value !== 'IT' && dirtyFields?.nazione) {
            setValue('provincia', '');
            setValue('citta', '');
            setValue('cap', null);
        }
    }, [nazioneInput])

    useEffect(() => {
        if(dirtyFields?.provincia || !provinciaInput) {
            setValue('citta', '');
            setValue('cap', null);
        }
    }, [provinciaInput])

    // SET CAP FROM CITY
    useEffect(()=> {
        dirtyFields?.citta && setValue('cap', !cittaInput ? null : cittaInput?.cap)
    }, [cittaInput])

    // SET DATE INTERVIEW
    useEffect(() => {
        let currentDate = moment(getData?.result?.dataConsulenza).format('DD-MM-YYYY HH:mm');
        if(dateConsulenza && (dateConsulenza !== currentDate) || timeChangeInput > 0) {
            setTimeChangeInput(timeChangeInput + 1)
            setValue('dataIntervista', dateConsulenza);
        }
    }, [dateConsulenza, getData])

    // Set CodiceUPA
    useEffect(() => {
        if(tipologiaIvaId?.value !== 4 ) {
            setValue('codUpa', '');
        }
    }, [tipologiaIvaId])


    const onSubmit = (dataForm: any) => {

        const Submit = async (data: any) => {
            
            let mapData = NewAdapterInterviewSent(data);
            let result = await updateInterview({ id: row?.interviewId, ...mapData });
            if ('error' in result) {
                setReloadForm(true);
                toast.error(`Intervista non modificata! Errore: ${result?.error?.data?.detail}`)
            } else {
                setReloadForm(false);
                setValue('prodottoDaAggiungereNome', null);
                setValue('prodottoDaAggiungerePrezzo', null);
                toast.success(`Intervista modificata`);
                setLoader(true);
            }

        }

        if(dataForm) {
            Submit(dataForm)
        } else {
            handleSubmit(Submit)();
        }
    }

    const handleSubmitForm = async (dataForm: any) => {
        if (Object.keys(errors).length == 0) {

            const {prodotti, pagamenti} = dataForm;
            
            const ctrl: any = AlertPaymentImport(prodotti, pagamenti, getData?.result);

            if (ctrl.isPayment) {
                dispatch(setIsOpenDialogAction({isOpen: true}))
                setPartialAmount(true);
                setReloadForm(true);
            } else if(ctrl.isProd) {
                dispatch(setIsOpenDialogAction({isOpen: true}))
                setProdMag(true);
                setReloadForm(true);
            } else {
                onSubmit(dataForm)
            }
        } else {
            FormAlert(isValid && Object.keys(errors).length == 0 ? 'noShow' : 'show', errors)
        }
    }

    return (
        <>
        {isPartialAmount || isProdMag ? 
            <ActionConfirm
                title={'Attenzione'} 
                message={'La somma degli importi dei pagamenti non corrisponde alla somma degli importi dei prodotti. Vuoi continuare con il salvataggio?'} 
                onAction={onSubmit}
                labelConfirm={'Conferma'}
                open={isPartialAmount || isProdMag}
            />
            : null
        }
        <form className={`my-4 ${request?.isLoading && 'opacity-20'}`}>
            {(getIsLoading || loadingUser || request?.isLoading || isLoading || loadingConsulente || loader) ? <Loader /> : null}
            {isSuccessGet ?
                <>
                    <div className="flex justify-between items-end px-8 mb-12">
                        <p className="font-semibold">Id: {getData?.result?.id}</p>
                        <button type='button' className="h-10 bg-[#064e3b] text-white px-5 w-64 rounded-lg text-md"
                            onClick={handleSubmit(handleSubmitForm)}
                        >
                            Salva
                        </button>
                    </div>
                    
                    <div className="px-8 space-y-4">
                        {/* PRIMA RIGA */}
                        <div className="grid grid-cols-3 gap-x-6 gap-y-8 mb-7">
                            <InputsFields arrFields={InterviewListInputFormUpdate(optionEsito, role)} form={form} isDisabled={!IntervieCons.includes(role)} />
                            <InputsFields arrFields={ConsulentiListOp(optionConsulente)} form={form} isDisabled={!IntervieAdmin.includes(role)} />
                            <InputsFields arrFields={SpeackListOp(optionSpeaker)} form={form} isDisabled={!IntervieAdmin.includes(role)} />
                        </div>

                        <InputsFields arrFields={NotePre} form={form} isDisabled={!IntervieSpeak.includes(role)} />

                        {/* PRODOTTI */}
                        <FormArrayProduct optionProduct={optionProduct} role={role} optionMontatore={optionMontatore} form={form} />
                        
                        {/* Aggiungi Prodotto */}
                        {/* {(role === 'Admin' || role === 'SuperAdmin')
                            ? <div className="grid grid-cols-2 gap-4 my-4">
                                <InputsFields arrFields={ProductAddInputs} form={form} isDisabled={!IntervieCons.includes(role)} />
                              </div>
                            : null
                        } */}

                        {/* PAGAMENTI */}
                        <FormArrayPayment interviewId={row?.interviewId} optionProduct={optionProduct} role={role} optionMontatore={optionMontatore} form={form} payments={getData?.result?.pagamenti} />
                        {/* DATI FATTURAZIONE */}
                        <p className="col-span-2 mt-4 text-[#064E3B]">Dati fatturazione</p>
                        <div className="grid grid-cols-3 gap-x-6 gap-y-8 mb-7">
                            <InputsFields arrFields={InfoAddresses} form={form} isDisabled={!IntervieCons.includes(role)} />
                            <InputsFields arrFields={Nazione(nazioni)} form={form} isDisabled={!IntervieCons.includes(role)} />
                            <InputsFields arrFields={Provincia(provincie)} form={form} isDisabled={!IntervieCons.includes(role) || nazioneInput !== 'IT'} />
                            <InputsFields arrFields={CittaInput(citta)} form={form} isDisabled={!IntervieCons.includes(role) || !provinciaInput || nazioneInput !== 'IT'} />
                            <InputsFields arrFields={capInput} form={form} isDisabled={!IntervieCons.includes(role) || !cittaInput || nazioneInput !== 'IT' } />
                            <InputsFields arrFields={Address} form={form} isDisabled={!IntervieCons.includes(role)} />
                            <InputsFields arrFields={Iva} form={form} options={optionIva} isDisabled={!IntervieCons.includes(role)} />
                            {tipologiaIvaId?.value === 4 && <InputsFields arrFields={Upa} form={form} isDisabled={!IntervieCons.includes(role)} />}
                        </div>

                        {/* DATI MONTAGGIO */}
                        <div className="py-2">
                            <InputsFields arrFields={InputNoteSpeack} form={form} isDisabled={!InterviewSpeack.includes(role)} />               
                        </div>
                    </div>
                    
                    <div className="flex justify-end pt-5 px-8">
                        <button type='button' className="h-10 bg-[#064e3b] text-white px-5 w-64 rounded-lg text-md"
                        onClick={handleSubmit(handleSubmitForm)}>
                            Salva
                        </button>
                    </div>
                </>
               : <div className="h-96"></div>}
        </form>
        </>
    )
}