import { useSelector } from "react-redux";
import { selectCurrentUserInfo } from "../store/feautures/AuthSlice";

export default function HomePage() {

    const {name, lastname}: any = useSelector(selectCurrentUserInfo);

    return(
        <>
            <h2 className="text-xl my-6">Home</h2>
            <p>Benvenuto, {name} {lastname}</p>
        </>
    )
}