
export const ReportLeadListInputs = [
    {
        label: 'Carica file',
        name: 'lead',
        placeholder: 'Carica file',
        type: 'file',
        validators: {
            required: 'Il campo è richiesto'
        },
        type_input: 'file',
        size_input: 'w-full',
    },
]