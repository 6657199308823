import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useState } from "react";
import ReportOperatorFilters from "../../components/filters/ReportOperatorFilters"
import Loader from "../../components/main/Loader";
import TableComponent from "../../components/main/Table";
import { AdapterReportFilterOp } from "../../helpers/adapter/filters/AdapterFilterReportOp";
import { initialParams, IPagination } from "../../helpers/PaginationHelper";
import { useGetReportOperatorQuery } from "../../store/services/ReportService";
import { HeaderRepOp } from "../../utils/headerTable/TabHeaderReportOp";
import { useGetUserRoleQuery } from "../../store/services/LookUpService";


export default function ReportOperator() {
    const [queryParams, setQueryParams] = useState<IPagination>(initialParams);
    const [filterParams, setFilterParams] = useState<any>(null);
    let {isSuccess, isLoading, data, isFetching } = useGetReportOperatorQuery(filterParams ? {...queryParams, ...filterParams} : skipToken);
    let { data: operatoreData, isLoading: isLoadingOp } = useGetUserRoleQuery({role: 'Operatore' });

    const handleSetFilter = (dataFilter: any) => {
        const mapFilter = AdapterReportFilterOp(dataFilter)
        setFilterParams(mapFilter);
        setQueryParams(initialParams);
    }

    return(
        <>
            {isLoading || isFetching || isLoadingOp ? <Loader /> : null}
            <ReportOperatorFilters methodParent={handleSetFilter} operatoreData={operatoreData} />
            {isSuccess && data?.length > 0
                ? <TableComponent columns={HeaderRepOp} data={data} isDisabledPagination={true}  /> 
                : <p className="p-6 text-center">NESSUN REPORT PRESENTE</p>
            }
        </>
    )
}