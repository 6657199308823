export const CreatorOperator = (optionOperatoreCreazione: any[]) => ([
    {
        label: 'Operatore creazione',
        name: 'idOperatoreCreazione',
        placeholder: 'Operatore creazione',
        type: 'text',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full col-span-2',
        options: optionOperatoreCreazione
    },
]);

export const HostRegistryListInputs = (optionRegioni: [], catProfessionali: [], type: string, role: string) => ([
    {
        label: 'Nome',
        name: 'nome',
        placeholder: 'Nome',
        type: 'text',
        validators: {
            required: type === 'update' && role === 'Consulente' ? 'Il campo è richiesto' : null,
            maxLength: {
                value: 50,
                message: "Limite caratteri superato"
            }
        },
        type_input: 'input',
        size_input: 'w-full',
        maxLength: 50
    },
    {
        label: 'Cognome',
        name: 'cognome',
        placeholder: 'Cognome',
        type: 'text',
        validators: {
            required: type === 'update' && role === 'Consulente' ? 'Il campo è richiesto' : null,
            maxLength: {
                value: 50,
                message: "Limite caratteri superato"
            }
        },
        type_input: 'input',
        size_input: 'w-full',
        maxLength: 50
    },
    {
        label: 'Nome secondario',
        name: 'nome2',
        placeholder: 'Nome secondario',
        type: 'text',
        validators: {
            maxLength: {
                value: 50,
                message: "Limite caratteri superato"
            }
        },
        type_input: 'input',
        size_input: 'w-full',
        maxLength: 50
    },
    {
        label: 'Cognome secondario',
        name: 'cognome2',
        placeholder: 'Cognome secondario',
        type: 'text',
        validators: {
            maxLength: {
                value: 50,
                message: "Limite caratteri superato"
            }
        },
        type_input: 'input',
        size_input: 'w-full',
        maxLength: 50
    },
    {
        label: 'Nome 3',
        name: 'nome3',
        placeholder: 'Nome 3',
        type: 'text',
        validators: {
            maxLength: {
                value: 50,
                message: "Limite caratteri superato"
            }
        },
        type_input: 'input',
        size_input: 'w-full',
        maxLength: 50
    },
    {
        label: 'Cognome 3',
        name: 'cognome3',
        placeholder: 'Cognome 3',
        type: 'text',
        validators: {
            maxLength: {
                value: 50,
                message: "Limite caratteri superato"
            }
        },
        type_input: 'input',
        size_input: 'w-full',
        maxLength: 50
    },
    {
        label: "Nome d'arte",
        name: 'nomeArte',
        placeholder: "Nome d'arte",
        type: 'text',
        validators: {
            maxLength: {
                value: 50,
                message: "Limite caratteri superato"
            }
        },
        type_input: 'input',
        size_input: 'w-full',
        maxLength: 50
    },
    {
        label: 'Telefono comunicazioni',
        name: 'telefono',
        placeholder: 'Telefono comunicazioni',
        type: 'text',
        validators: {
            required: type === 'update' && role === 'Consulente' ? 'Il campo è richiesto' : null,
        },
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Telefono da pubblicare',
        name: 'telefono2',
        placeholder: 'Telefono da pubblicare',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Telefono fisso',
        name: 'telefonoFisso',
        placeholder: 'Telefono fisso',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Email comunicazioni',
        name: 'email',
        placeholder: 'Email comunicazioni',
        type: 'text',
        validators: {
            required: type === 'update' && role === 'Consulente' ? 'Il campo è richiesto' : null,
        },
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Azienda',
        name: 'azienda',
        placeholder: 'Azienda',
        type: 'input',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Indirizzo azienda',
        name: 'indirizzoAzienda',
        placeholder: 'Indirizzo azienda',
        type: 'input',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Regione',
        name: 'regione',
        placeholder: 'Regione',
        type: 'select',
        validators: {
            required: 'Il campo è obbligatorio'
        },
        type_input: 'react-select',
        size_input: 'w-full',
        options: optionRegioni
    },
    {
        label: 'Categoria professionale',
        name: 'categoriaProfessionaleId',
        placeholder: 'Categoria professionale',
        type: 'select',
        validators: {
            required: 'Il campo è obbligatorio'
        },
        type_input: 'react-select',
        size_input: 'w-full',
        options: catProfessionali
    },
    {
        label: 'Sottocategoria prof.',
        name: 'sottocategoriaProfessionale',
        placeholder: 'Sottocategoria prof.',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Email pubblicazione',
        name: 'emailPubblicazione',
        placeholder: 'Email pubblicazione',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Facebook',
        name: 'facebook',
        placeholder: 'Facebook',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Instagram',
        name: 'instagram',
        placeholder: 'Instagram',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Linkedin',
        name: 'linkedin',
        placeholder: 'Linkedin',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Youtube',
        name: 'youtube',
        placeholder: 'Youtube',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'TikTok',
        name: 'tikTok',
        placeholder: 'TikTok',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Sito Web',
        name: 'sitoWeb',
        placeholder: 'Sito Web',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    }
]);

export const IdOperatoreCreazione = (optionConsulente: any[]) => ([
    {
        label: 'Operatore creazione',
        name: 'idOperatoreCreazione',
        placeholder: 'Operatore creazione',
        type: 'select',
        validators: {
            required: 'Il campo è obbligatorio'
        },
        type_input: 'react-select',
        size_input: 'w-full',
        options: optionConsulente
    },
])