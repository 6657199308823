import { QueryStringHelper } from "../../helpers/QueryHelper";
import { emptySplitApi } from "./InjectionEndpoint"

const callsService = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getCalls: build.query({
            query: (params: any) => `/api/chiamata?${QueryStringHelper(params)}`,
            providesTags: ['calls']
        }),

        getCallById: build.query({
            query: (chiamataId: any) => `/api/chiamata/${chiamataId}`,
            providesTags: ['calls']
        }),

        updateCall: build.mutation({
            query: ({id, ...rest}) => ({
                url: `/api/chiamata/${id}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['calls']
        }),
    }),
})

export const {
    useGetCallsQuery,
    useGetCallByIdQuery,
    useUpdateCallMutation
} = callsService;