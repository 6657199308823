
export interface ICallData {
    argomentoIntervista: string,
    note: string,
    esito: string,
    dataAppuntamento: string,
    interesseVideo: string
}

export const CallListInput = (esito: any, optionEsito: any[], optionInteresseVideo: any[]) => ([
    {
        label: 'Esito',
        name: 'esito',
        placeholder: 'Esito',
        type: 'select',
        validators: {
            required: 'Il campo è obbligatorio'
        },
        type_input: 'react-select',
        size_input: 'w-full order-1',
        options: optionEsito
    },
    {
        label: 'Note',
        name: 'note',
        placeholder: 'Note',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full order-0',
    },
    {
        label: 'Interesse video',
        name: 'interesseVideo',
        placeholder: 'Interesse video',
        type: 'select',
        validators: esito?.value === 'AppuntamentoPreso' ? {required: 'Il campo è obbligatorio'} : {},
        type_input: 'react-select',
        size_input: 'w-full order-2',
        options: optionInteresseVideo
    },
    {
        label: 'Argomento intervista',
        name: 'argomentoIntervista',
        placeholder: 'Argomento intervista',
        type: 'text',
        validators: esito?.value === 'AppuntamentoPreso' ? {required: 'Il campo è obbligatorio'} : {},
        type_input: 'text-area',
        size_input: 'col-span-2 order-4',
    }
])

export const InputDateApp = [
    {
        label: 'Data appuntamento',
        name: 'dataAppuntamento',
        placeholder: 'Data appuntamento',
        type: 'text',
        validators: {
            required: 'Il campo è obbligatorio'
        },
        type_input: 'picker',
        size_input: 'w-full order-3',
        isLimitDate: true,
        isTimeShow: true,
    }
]

export const requiredAppuntamneto = [
]