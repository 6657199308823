

export const ListTabHelper: any = {
    ReportOperatore: "Statistiche operatore",
    ModificaAnagrafica: "Modifica anagrafica",
    ListaChiamate: "Storico chiamate",
    AggiungiChiamata: "Aggiungi chiamata",
    ListaInterviste: "Storico interviste",
    ModificaChiamata: "Modifica chiamata",
    ModificaIntervista: "Modifica intervista",
    StatisticheConsulente: "Statistiche consulente",
    StatisticheMontatore: "Statistiche montatore",
    VenditeOperatore: "Vendite operatore",
    ReportFatturato: "Fatturato",
    ReportOspiti: 'Ospiti',
    Lead: 'Lead'
}