import ErrorMessageComponent from "../main/ErrorMessage";

export default function TextAreaInput({ input, form, isDisabled }: any) {

    const { register, formState: { errors } } = form;

    return (
        <div className={`${input.size_input} ${isDisabled || input?.isDisabled ? 'opacity-60' : ''}`}>
            <div className="relative mt-2">
                <textarea
                    {...register(input.name, { ...input.validators })}
                    className={`bg-[#efefef] rounded-md px-2 py-1 peer ${input.textAreaHeight ? 'h-8' : 'h-12'} w-full text-skin-textInput placeholder-transparent focus:outline-none focus:border-skin-inputFocus text-base`}
                    autoComplete={'false'}
                    id={input.name}
                    type="text"
                    placeholder={input.placeholder}
                    disabled={isDisabled || input?.isDisabled}
                />
                <label htmlFor={input?.name} className={`pointer-events-none select-none absolute text-base left-2 -top-4 text-[#064e3b] transition-all peer-placeholder-shown:text-[#9CA3AF] peer-placeholder-shown:text-[#9CA3AF] peer-focus:left-2 ${input.textAreaHeight ? 'peer-placeholder-shown:top-2' : 'peer-placeholder-shown:top-3'} peer-focus:-top-4 peer-focus:text-skin-focus peer-focus:text-base`}>
                    {input.label}
                </label>
            </div>
            <ErrorMessageComponent errors={errors} name={input?.name} />
        </div>
    )
}