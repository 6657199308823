import moment from "moment-timezone";

export interface IFilterRegistry {
    Nominativo: string | null,
    Email: string | null,
    Regione: {value: string, label: string} | any,
    Ruolo: {value: string, label: string} | any
    CodiceOspite: string | null,
    globalSearch: boolean | any,
    Telefono: string | null,
    NominativoOCodiceOperatoreCreazioneId: {value: string, label: string} | any,
    NominativoOCodiceOperatoreGestioneId: {value: string, label: string} | any,
    DataCreazioneDa: string | "" | any,
    DataCreazioneA: string | "" | any
}


export function RegistryAdapterFilterSent(registry: IFilterRegistry) {
    let RegistryMap: IFilterRegistry = {
            Nominativo: registry?.Nominativo, 
            Regione: registry?.Regione?.value ? registry?.Regione?.value : '',
            Ruolo: registry?.Ruolo?.value ? registry?.Ruolo?.value : '',
            Email: registry?.Email,
            CodiceOspite: registry?.CodiceOspite,
            globalSearch: registry?.globalSearch,
            Telefono: registry?.Telefono,
            NominativoOCodiceOperatoreCreazioneId: registry?.NominativoOCodiceOperatoreCreazioneId?.value ? registry?.NominativoOCodiceOperatoreCreazioneId?.value : '',
            NominativoOCodiceOperatoreGestioneId: registry?.NominativoOCodiceOperatoreGestioneId?.value ? registry?.NominativoOCodiceOperatoreGestioneId?.value : '',
            DataCreazioneDa: registry?.DataCreazioneDa ? moment.tz(registry?.DataCreazioneDa, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
            DataCreazioneA: registry?.DataCreazioneA ? moment.tz(registry?.DataCreazioneA, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : ''              
    }

    return RegistryMap;
}