import { KeyIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { selectCurrentUserInfo } from "../../store/feautures/AuthSlice";
import { SuperAdminVisore } from "../../utils/genericList/ListRoleRoutes";
import ResetPasswordForm from "../form/ResetPasswordForm";
import DialogComponent from "../main/DIalogComponent";


export default function ResetPassword({ row }: any) {

    const { role } = useSelector(selectCurrentUserInfo);

    return (
        SuperAdminVisore.includes(role)
            ? (role === 'Admin' && row?.ruolo !== 'SuperAdmin') || role === 'SuperAdmin' || role === 'Supervisore' ?
                <DialogComponent
                    icon={<KeyIcon className="w-5 h-5" />}
                    label={'Reset password'}
                    content={
                        <ResetPasswordForm id={row?.id} />
                    }
                />
                : <></>
            : <></>
    )
}