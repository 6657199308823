import { ErrorMessage } from "@hookform/error-message";

export default function ErrorMessageComponent({errors, name}: any) {
    
    return(
        <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <p className="text-red-500 text-sm">{message}</p>}
      />
    )
}