import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import InputsFields from "../../helpers/InputFields";
import { TextTransformHelper } from "../../helpers/TextTransformHelper";
import StatiIntervistaLookUp from "../../lookup/Stato";
import { selectCurrentUserInfo } from "../../store/feautures/AuthSlice";
import { useGetInterviewsByIdQuery, useSetStateInterviewMutation } from "../../store/services/InterviewService";
import { useGetUserRoleQuery } from "../../store/services/LookUpService";
import { setStateListInput, speakerListInput } from "../../utils/listInputs/SetStateInputList";
import Loader from "../main/Loader";

export default function FormSetState({ row }: any) {

    let { isLoading: getIsLoading, data: getData, isSuccess: isSuccessGet } = useGetInterviewsByIdQuery(row?.id);
    const form = useForm();
    let optionStato = StatiIntervistaLookUp();
    const { handleSubmit, watch, reset, setValue } = form;
    const ctrlState = watch('esito');
    const [listSpeaker, setListSpeaker] = useState([])
    const {data} = useGetUserRoleQuery({role: 'Speaker'});
    const [setStateInterview, request] = useSetStateInterviewMutation();
    const {role, name, lastname, id} = useSelector(selectCurrentUserInfo);

    // SET LIST SPEAKER
    useEffect(() => {
        let listMap = data?.map((acc: any) => {
            return { value: acc?.id, label: acc?.nominativo }
        });

        setListSpeaker(listMap);
    }, [data])

    // SET SPEAKERID IF ROLE IS SPEAKER
    useEffect(() => {
        if(role === 'Speaker' && ctrlState?.value === 'Eseguita' ) {
            setValue('speakerId', {value: Number(id), label: `${name} ${lastname}`});
        }
    }, [ctrlState, role])

    useEffect(() => {
        let values = {
            esito: getData?.result?.stato ? {
                value: getData?.result?.stato, 
                label: TextTransformHelper(getData?.result?.stato)
            } : "",
            speakerId: getData?.result?.speakerId ? {
                value: getData?.result?.speakerId, 
                label: `${getData?.result?.speaker?.nome} ${getData?.result?.speaker?.cognome}`
            } : "",
        }
        reset(values)
    }, [getData, isSuccessGet])

    const handleSetStaete = async (date: any) => {
        date.esito = date?.esito?.value;
        date.speakerId = date?.speakerId?.value ? date?.speakerId.value : null;
        let result = await setStateInterview({ id: row?.id, ...date })

        if ('error' in result) {
            toast.error(`Stato non modificato! Errore: ${result?.error?.data?.detail}`)
        } else {
            toast.success(`Stato modificato`);
        }
    }

    return (
        <form onSubmit={handleSubmit(handleSetStaete)}>
            {getIsLoading || request?.isLoading ? <Loader /> : null}
            <div className="grid grid-cols-2 grid-rows-1 gap-6 py-24 px-10">
                <InputsFields arrFields={setStateListInput} form={form} options={optionStato} />
                {(ctrlState?.value === 'Eseguita') ? <InputsFields arrFields={speakerListInput} form={form} options={listSpeaker} isDisabled={role === 'Speaker'} /> : null}
            </div>

            <div className="flex justify-end my-8 p-10">
                <button className="h-10 bg-[#064e3b] text-white px-5 w-64 rounded-lg text-md">Salva</button>
            </div>
        </form>
    )
}