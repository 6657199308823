import UpdateHostRegistry from "../../components/dialogContent/UpdateHostRegistry";
import FormInterview from "../../components/form/FormInterview";
import { NazioniList } from "../../lookup/Addresses";
import CategorieIvaLookUp from "../../lookup/CategorieIva";

export const listInterviewTab = (row: any) => {

const optionIva = CategorieIvaLookUp();
const nazioni = NazioniList();

return  {
    ModificaAnagrafica: [
        {
            id: 2,
            component: <UpdateHostRegistry row={row} fromComponent={'interview'}  />
        },
    ],

    ModificaIntervista: [
        {
            id: 1,
            component:  <FormInterview row={row} optionIva={optionIva} nazioni={nazioni} />
        },
    ]
}}