import moment from "moment-timezone";

export interface IReportFilterOp {
    DataDa: string,
    DataA: string,
    IdOperatore: {value: string, label: string} | any,
}

export function AdapterReportFilterOp(filter: IReportFilterOp) {
    let filterMap: IReportFilterOp;
    return filterMap = {
        DataDa: filter?.DataDa ? moment.tz(filter?.DataDa, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        DataA: filter?.DataA ? moment.tz(filter?.DataA, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        IdOperatore: filter?.IdOperatore?.value ? filter?.IdOperatore?.value : null,
    } 
} 

export interface IReportFilterCons {
    DataDa: string,
    DataA: string,
    IdConsulente: {value: string, label: string} | any,
    Regione: {value: string, label: string} | any
}

export function AdapterReportFilterCon(filter: IReportFilterCons) {
    let filterMap: IReportFilterCons;
    return filterMap = {
        DataDa: filter?.DataDa ? moment.tz(filter?.DataDa, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        DataA: filter?.DataA ? moment.tz(filter?.DataA, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        IdConsulente: filter?.IdConsulente?.value ? filter?.IdConsulente?.value : null,
        Regione: filter?.Regione?.value ? filter?.Regione?.value : null,
    } 
} 


export interface IReportFilterMontatore {
    DataDa: string,
    DataA: string,
    IdMontatore: {value: string, label: string} | any,
}

export function AdapterReportFilterMont(filter: IReportFilterMontatore) {
    let filterMap: IReportFilterMontatore;
    return filterMap = {
        DataDa: filter?.DataDa ? moment.tz(filter?.DataDa, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        DataA: filter?.DataA ? moment.tz(filter?.DataA, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        IdMontatore: filter?.IdMontatore?.value ? filter?.IdMontatore?.value : null
    } 
} 

export function AdapterReportFilterOpVendite(filter: any) {
    let filterMap: any;
    return filterMap = {
        DataDa: filter?.DataDa ? moment.tz(filter?.DataDa, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        DataA: filter?.DataA ? moment.tz(filter?.DataA, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        IdOperatore: filter?.IdOperatore?.value ? filter?.IdOperatore?.value : null,
        // Regione: filter?.Regione?.value ? filter?.Regione?.value : null,
    } 
} 
