import EsitoLookUp from "../../lookup/Esito";
import InteresseVideoLookUp from "../../lookup/InteresseVideo";
import { useGetHostRegistryByIdQuery } from "../../store/services/HostRegistryService";
import CallsForm from "../form/CallForm";
import Loader from "../main/Loader";

export default function AddCall({row}: any) {

    const { data, isLoading, isFetching, isSuccess } = useGetHostRegistryByIdQuery(row?.id);
    let optionEsito = EsitoLookUp();
    let optionInteresseVideo = InteresseVideoLookUp();

    return(
        <div className="relative">
            {(!optionEsito || !optionInteresseVideo || isLoading || isFetching) && <Loader />}
            {isSuccess &&  optionEsito?.length > 0 && optionInteresseVideo?.length > 0 
                ? <CallsForm infoRegistry={row} type={'add'} lastCall={data?.result?.ultimaChiamata} optionEsito={optionEsito} optionInteresseVideo={optionInteresseVideo} />
                : null 
            }
        </div>
    )

}