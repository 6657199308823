import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useState } from "react";
import ReportMontatoreFilters from "../../components/filters/ReportMontatoreFilters";
import Loader from "../../components/main/Loader";
import TableComponent from "../../components/main/Table";
import { AdapterReportFilterMont } from "../../helpers/adapter/filters/AdapterFilterReportOp";
import { initialParams, IPagination } from "../../helpers/PaginationHelper";
import { useGetReportMontatoreQuery } from "../../store/services/ReportService";
import { HeaderRepMont } from "../../utils/headerTable/TabHeaderReportMont";
import { useGetUserRoleQuery } from "../../store/services/LookUpService";

export default function ReportMontatore() {
    const [queryParams, setQueryParams] = useState<IPagination>(initialParams);
    const [filterParams, setFilterParams] = useState<any>(null);
    let {isSuccess, isLoading, data, isFetching } = useGetReportMontatoreQuery(filterParams ? {...queryParams, ...filterParams} : skipToken);
    let { data: montatoreData, isLoading: isLoadingOp } = useGetUserRoleQuery({role: 'Montatore' });


    const handleSetFilter = (dataFilter: any) => {
        const mapFilter = AdapterReportFilterMont(dataFilter)
        setFilterParams(mapFilter);
        setQueryParams(initialParams);
    }

    return(
        <>
            {isLoading || isFetching || isLoadingOp ? <Loader /> : null}
            <ReportMontatoreFilters methodParent={handleSetFilter} montatoreData={montatoreData} />
            {isSuccess && data?.length > 0
                ? <TableComponent columns={HeaderRepMont} data={data} isDisabledPagination={true}  /> 
                : <p className="p-6 text-center">NESSUN REPORT PRESENTE</p>
            }
        </>
    )
}