import { emptySplitApi } from "./InjectionEndpoint";

const provinceService = emptySplitApi.injectEndpoints({
    endpoints: (builder: any) => ({

        getNations: builder.query({
            query: () => `/api/nazione`,
        }),

        getProvincie: builder.query({
            query: () => `/api/provincia`,
        }),

        getCitta: builder.query({
            query: (provincia: any) => `/api/comune/provincia/${provincia}`,
        }),
    }),
})

export const {
    useGetNationsQuery,
    useGetProvincieQuery,
    useGetCittaQuery
} = provinceService;