import ActionOpenDialogInterview from "../../components/action/ActionOpenDialogIterview";
import ActionSetState from "../../components/action/ActionSetState";
import {DataFormatHelperTab, DataFormatHelperTabNoTime} from "../../helpers/DataFormatHelper";
import { TextTransformHelper } from "../../helpers/TextTransformHelper";
import { setCurrentInterview } from "../../store/feautures/CurrentInterviewSlice";
import { store } from "../../store/store";

export const HeaderInterviews: any = [
    {
        Header: "Cliente",
        accessor: "anagraficaOspiteNominativo",
        disableSortBy: false,
        Filter: false,
        customSort: true,
        Cell: ({ row }: any) => (
            <>
                <p>{row?.original?.anagraficaOspiteCodice || '-'}</p>
                <p>{row?.original?.anagraficaOspiteNominativo || '-'}</p>
            </>
        )
    },
    {
        Header: "Stato intervista",
        accessor: "stato",
        disableSortBy: true,
        Filter: false,
        customSort: false,
        Cell: ({row}: any) => {
            let split =  row?.original?.stato.split(/(?=[A-Z])/);
            let statoMap =`${split[0]} ${split[1] ? split[1]?.toLowerCase() : ''} ${split[2] ? split[2]?.toLowerCase() : ''}`
            return <p>{statoMap}</p> 
        }
    },
    {
        Header: "Data consulenza",
        accessor: "dataConsulenza",
        disableSortBy: false,
        Filter: false,
        customSort: true,
        Cell: ({row}: any) => (
           <p>{row?.original?.dataConsulenza ? DataFormatHelperTab(row?.original?.dataConsulenza) : '-'}</p> 
        )
    },
    {
        Header: "Esito",
        accessor: "esitoConsulenza",
        disableSortBy: true,
        Filter: false,
        customSort: false,
        Cell: ({row}: any) => (
            <p>{row?.original?.esitoConsulenza ? TextTransformHelper(row?.original?.esitoConsulenza) : '-'}</p> 
        )
    },
    {
        Header: "Data intervista",
        accessor: "dataIntervista",
        disableSortBy: false,
        Filter: false,
        customSort: true,
        Cell: ({row}: any) => (
           <p>{row.original?.dataIntervista ? DataFormatHelperTab(row.original?.dataIntervista) : '-'}</p>
        )
    },
    {
        Header: "Speaker",
        accessor: "speaker",
        disableSortBy: true,
        Filter: false,
        customSort: false,
        Cell: ({ row }: any) => (
            <>
                <p>{row?.original?.speakerCodice || '-'}</p>
                <p>{row?.original?.speakerNominativo || '-'}</p>
            </>
        )
    },
    {
        Header: "Modificata",
        accessor: "updated",
        disableSortBy: false,
        Filter: false,
        customSort: false,
        Cell: ({ row }: any) => (
            <>
                <p>{row?.original?.updatedDate ? DataFormatHelperTab(row?.original?.updatedDate) : '-'}</p>
                <p>{row?.original?.updatedByNominativo}</p>
            </>
        )
    },
    {
        Header: "Sede",
        accessor: "sede",
        disableSortBy: true,
        Filter: false,
        customSort: false
    },
    {
        Header: "Gestore",
        accessor: "gestore",
        disableSortBy: true,
        Filter: false,
        customSort: false,
        Cell: ({ row }: any) => (
            <>
                <p>{row?.original?.operatoreGestioneAnagraficaCodice || '-'}</p>
                <p>{row?.original?.operatoreGestioneAnagraficaNominativo || '-'}</p>
            </>
        )
    },
    // {
    //     Header: "Data consegna",
    //     accessor: "dataConsegnaVideo",
    //     disableSortBy: false,
    //     Filter: false,
    //     customSort: true,
    //     Cell: ({row}: any) => (
    //         <p>{row?.original?.dataConsegnaVideo ? DataFormatHelperTabNoTime(row?.original?.dataConsegnaVideo) : '-'}</p>
    //     )
    // },
    {
        Header: "",
        accessor: "action",
        disableSortBy: true,
        Filter: false,
        customSort: false,
        Cell: ({ row }: any) => (
            <div className="flex space-x-6 justify-end px-5" onClick={() => store.dispatch(setCurrentInterview({id: row?.original?.anagraficaOspiteId, interviewId: row?.original?.id}))}>
                <ActionOpenDialogInterview row={row?.original} />
                <ActionSetState row={row?.original} label={'Modifica stato'} />
            </div>
        )
    }

]