import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { useTable, usePagination, useSortBy, useFilters, useExpanded } from "react-table";

export default function TableComponent({ columns, data, meta, renderRowSubComponent, parentMethod, pageIndex, sortDirectionParent, currentSortCol, pageSizeParent, type, isDisabledPagination }: any) {

  const[pageSize, setPageSize] = useState(pageSizeParent ? pageSizeParent : 5);
  const totPage = Math.ceil(meta?.totalCount / pageSize);
  const[rowSelected, setRowSelected] = useState<string | null>('');

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 1, pageSize: pageSize },
      manualPagination: true,
      pageCount: totPage,
      manualSortBy: true,
      parentMethod,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const getVisiblePages = (page: number, total: number) => {
    const arrPage = [];
    if (total <= 5) {
      for (let i = 1; i <= total; i++) {
        arrPage.push(i)
      }
      return arrPage;
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  const [visiblePages, setVisiblePages]: any[] = useState([]);

  useEffect(() => {
    const arrOption = getVisiblePages(pageIndex + 1, pageOptions.length);
    setVisiblePages(arrOption);
  }, [pageIndex, pageOptions.length])

  const handleChangePage = (page: any, goTo: any) => {
    goTo(page)
    parentMethod({ pageIndex: page, pageSize: pageSize })
  }


  const handleSort = (column: any) => {
    let direction: string | null = null;

    if (column.customSort) {
      if(column.id !== currentSortCol ) {
        direction = 'ASC';
      } else if (sortDirectionParent === 'ASC') {
        direction = 'DESC';
      } else if (sortDirectionParent === 'DESC') {
        direction = null;
      } else {
        direction = 'ASC';
      }
      parentMethod({directionSort: direction, col: column});
    }
  }

  return (
<div className="mt-2 flex flex-col">
	<div className="col-span-12 overflow-x-auto">
		<div className="overflow-auto lg:overflow-visible ">
          <table
            {...getTableProps()}
            className="min-w-full table table-auto px-2 relative border-separate space-y-6 text-sm"
          >
            <thead className="bg-transparent text-[#1e293b]">
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}
                      className='group p-3 text-left text-xs uppercase'
                      onClick={() => handleSort(column)}
                    >

                      <div className={`my-2 inline-flex group ${column.customSort ? 'cursor-pointer' : ''}`}>
                        <span>{column.render('Header')}</span>
                        {column?.customSort && !sortDirectionParent && <span><ChevronUpIcon className='w-3 h-3 ml-4 mt-1 group-hover:opacity-100 opacity-0' /></span>}
                        {column.customSort && currentSortCol === column.id && <span>
                          {sortDirectionParent === 'ASC' ? (
                            <ChevronUpIcon className='w-3 h-3 ml-4 mt-1 inline-block' />
                          ) : sortDirectionParent === 'DESC' ? (
                            <ChevronDownIcon className='w-3 h-3 ml-4 mt-1 inline-block' />
                          ) : null}
                        </span>}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row: any) => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr className={`${row.original.id === rowSelected  ? "bg-green-200" : 'bg-white'} shadow-md h-14 text-[#064e3b]`} onClick={() => setRowSelected(row.original.id === rowSelected ? null : row.original.id)} >
                      {row.cells.map((cell: any) => {
                        return (
                          <td {...cell.getCellProps(
                            {
                              style: {
                                minWidth: cell.column.minWidth,
                                maxWidth: cell.column.maxWidth,
                                width: cell.column.width,
                              },
                            }
                          )} className={`${ type === 'userList' ? row.original.active ? '' : 'opacity-50 last:opacity-100' : '' } p-2 whitespace-nowrap text-base`}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded && (
                      <tr className='container'>
                        <td colSpan={visibleColumns.length}>
                          {renderRowSubComponent(row)}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* PAGINATION */}
      {isDisabledPagination ?
      null
      : <div className='py-4 text-right flex space-x-12 justify-end items-center'>

        {/* SELECT ITEMS FOR PAGE */}
        <nav className="space-x-4">
        <span className="text-sm text-skin-placeholder">Totale: <strong>{meta?.totalCount}</strong></span>
        <span className="text-sm text-skin-placeholder">Elementi per pagina</span>
        <select
          className="border-0 border-b-2 bg-transparent outline-none leading-8 transition-colors duration-200 ease-in-out"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
            parentMethod({ pageIndex: 1, pageSize: Number(e.target.value) })
          }}
        >
          {[10, 25, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
        </nav>

        <nav className="relative z-0 inline-flex" aria-label="Pagination">
          <button className='relative inline-flex items-center justify-center pr-2' onClick={() => handleChangePage(pageIndex - 1, previousPage)} disabled={pageIndex === 1}>
            <ChevronLeftIcon className="w-5- h-5" />
          </button>

          {visiblePages.map((el: number, index: number) => (
            <button key={`page-${el}`} onClick={() => { const page = el ? Number(el) : 1; handleChangePage(page, gotoPage) }}
              className={`mx-1 inline-flex justify-center items-center h-6 w-9 text-xs ${el === pageIndex ? 'bg-[#064e3b] text-white' : 'bg-gray-100'}`}>
              {el}
            </button>
          ))}

          <button className='relative inline-flex items-center justify-center pl-2' onClick={() => handleChangePage(pageIndex + 1, nextPage)} disabled={pageIndex === totPage}>
            <ChevronRightIcon className="w-5 h-5" />
          </button>
        </nav>
      </div>}
    </div>
  );
}