import { PatternHelper } from "../PatternHelper";

export const UsersListInputs = (roleList: any, isEmailDisabled: boolean) => [
    {
        label: 'Ruolo',
        name: 'ruolo',
        placeholder: 'Ruolo',
        type: 'select',
        validators: {
            required: 'Il campo è obbligatorio'
        },
        type_input: 'react-select',
        size_input: 'w-full',
        options: roleList
    },
    {
        label: 'Nome',
        name: 'nome',
        placeholder: 'Nome',
        type: 'text',
        validators: {
            required: 'Il campo è obbligatorio'
        },
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Cognome',
        name: 'cognome',
        placeholder: 'Cognome',
        type: 'text',
        validators: {
            required: 'Il campo è obbligatorio'
        },
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Email',
        name: 'email',
        placeholder: 'Email',
        type: 'text',
        validators: {
            required: 'Il campo è obbligatorio',
            pattern: {
                value: PatternHelper.email,
                message: "Email non valida"
            }
        },
        type_input: 'input',
        size_input: 'w-full',
        isDisabled: isEmailDisabled
    }
]

export const passwordInput = [
    {
        label: 'Password',
        name: 'password',
        placeholder: 'Password',
        type: 'text',
        validators: {
            required: 'Il campo è obbligatorio',
            pattern: {
               value: PatternHelper.passCtrl,
               message: "La password non rispetta i caratteri richiesti"
            }
        },
        type_input: 'password',
        size_input: 'w-full',
    }
]

export const UserRegion = (optionRegioni: []) =>  [
    {
        label: 'Regione',
        name: 'regione',
        placeholder: 'Regione',
        type: 'select',
        validators: {
            required: 'Il campo è obbligatorio'
        },
        type_input: 'react-select',
        size_input: 'w-full',
        options: optionRegioni
    }
]


export const Supervisore = (optionSupervisore: any[]) =>  [
    {
        label: 'Supervisore',
        name: 'supervisoreId',
        placeholder: 'Supervisore',
        type: 'select',
        validators: {
            required: 'Il campo è obbligatorio'
        },
        type_input: 'react-select',
        size_input: 'w-full',
        options: optionSupervisore
    }
]