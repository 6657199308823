import TabsComponent from "./Tabs";


export default function ActionButtonDialogRow({elementForTab}: any) {

    return(
        <div className="px-5">
            <TabsComponent categories={elementForTab} />
        </div>
    )
}