import { useState } from "react";
import UserFilters from "../../components/filters/UsersFilters";
import ButtonAdd from "../../components/main/ButtonAdd";
import Loader from "../../components/main/Loader";
import TableComponent from "../../components/main/Table";
import { UserAdapterFilterSent } from "../../helpers/adapter/filters/AdapterFilterUser";
import { initialParams, IPagination, PaginationHelper } from "../../helpers/PaginationHelper";
import { useGetAccountQuery } from "../../store/services/UsersService";
import { HeaderUser } from "../../utils/headerTable/TabHeaderUsers";

export default function UsersListPage() {

    const [queryParams, setQueryParams] = useState<IPagination>(initialParams);
    const [filterParams, setFilterParams] = useState({});
    let {isSuccess, isLoading, data, isFetching } = useGetAccountQuery({...queryParams, ...filterParams});

    const handleActionTable = (data: any) => {
        let paramsPagination: any = PaginationHelper(queryParams, data);
        setQueryParams(paramsPagination);
    }

    const handleSetFilter = (dataFilter: any) => {
        let MapFilter: any = UserAdapterFilterSent(dataFilter)
        setFilterParams(MapFilter);
        setQueryParams(initialParams);
    }

    return(
        <>  
            {(isLoading || isFetching) && <Loader />}

            <h2 className="text-xl py-6">Gestione utenti</h2>

            <UserFilters methodParent={handleSetFilter} />

            <div className="flex items-center justify-end pt-14">
                <ButtonAdd path={'/dashboard/users/new-user'} label={'Aggiungi utente'} />
            </div>

            <div className="mt-6">
            {isSuccess && data?.result.length > 0 
                ? <TableComponent columns={HeaderUser} data={data?.result} meta={data?.meta} parentMethod={handleActionTable} pageIndex={queryParams?.CurrentPage} pageSizeParent={queryParams?.PageSize} sortDirectionParent={queryParams?.sortDirection} currentSortCol={queryParams?.sortIdCol} /> 
                : <p className="p-6 text-center">NESSUN UTENTE PRESENTE</p>
            }
            </div>

        </>
    )
}