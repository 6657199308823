import moment from "moment";

export function AdapterFilterFatturato(filter: any) {
    let filterMap: any;
    return filterMap = {
        DataDa: filter?.DataDa ? moment.tz(filter?.DataDa, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        DataA: filter?.DataA ? moment.tz(filter?.DataA, "DD-MM-YYYY HH:mm", "Europe/Rome").toISOString() : '',
        IdConsulente: filter?.IdConsulente?.value ? filter?.IdConsulente?.value : null,
        Regione: filter?.Regione?.value ? filter?.Regione?.value : null,
    } 
} 