import { ReactComponentElement } from "react"
import { ChartBarIcon, ChatBubbleLeftRightIcon, ClipboardDocumentCheckIcon, PhoneArrowUpRightIcon, RectangleGroupIcon, RectangleStackIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { AllRole, ListInterviewRole, SuperAdminVisore, SuperAdminVisoreOpe, SuperAdminVisoreOpeCons, SuperAndAdmin, SuperAndAdminConsulente } from "./ListRoleRoutes";

export interface IListMenu {
    label: string,
    path: string,
    icon: ReactComponentElement<any>,
    activePath: string,
    visibileFor: string[]
}

export const ListItemMenu: IListMenu[] = [
    {
        label: 'Home',
        path: '/dashboard/home',
        icon: <RectangleGroupIcon className='w-5 h-5' />,
        activePath: '/home',
        visibileFor: AllRole
    },
    {
        label: 'Utenti',
        path: '/dashboard/users',
        icon:<UserGroupIcon className='w-5 h-5' />,
        activePath: '/users',
        visibileFor: SuperAdminVisore
    },
    {
        label: 'Anagrafiche',
        path: '/dashboard/host-registry',
        icon: <ClipboardDocumentCheckIcon className='w-5 h-5' />,
        activePath: '/host-registry',
        visibileFor: SuperAdminVisoreOpeCons
    },
    {
        label: 'Chiamate',
        path: '/dashboard/calls',
        icon: <PhoneArrowUpRightIcon className='w-5 h-5' />,
        activePath: '/calls',
        visibileFor: SuperAdminVisoreOpe
    },
    {
        label: 'Interviste',
        path: '/dashboard/interviews',
        icon: <ChatBubbleLeftRightIcon className='w-5 h-5' />,
        activePath: '/interviews',
        visibileFor: ListInterviewRole
    },
    {
        label: 'Report',
        path: '/dashboard/reports',
        icon: <ChartBarIcon className='w-5 h-5' />,
        activePath: '/reports',
        visibileFor: SuperAdminVisore
    },
    {
        label: 'Prodotti',
        path: '/dashboard/products',
        icon: <RectangleStackIcon className='w-5 h-5' />,
        activePath: '/products',
        visibileFor: SuperAndAdmin
    }
]