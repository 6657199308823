import { useEffect } from "react";
import { useForm } from "react-hook-form";
import InputsFields from "../../helpers/InputFields";
import { ReportLeadListInputs } from "../../utils/listInputs/LeadReportListInput";
import { useDownloadLeadMutation, useGetStatusLeadQuery, useUploadLeadMutation } from "../../store/services/LeadService";
import { toast } from "react-toastify";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import { saveAs } from 'file-saver';
import excelImg from "../../assets/excel.png"


export default function ReportLead() {

    const form = useForm();
    const { handleSubmit, setValue } = form;
    const [uploadLead, resultUpload] = useUploadLeadMutation();
    // const [getStatusLead, resultStatus] = useGetStatusLeadMutation();
    const [downloadLead, resultDownload] = useDownloadLeadMutation();
    const stateUpload = ['InAttesaFile', 'Completato', 'Fallito'];
    const { data: resultStatus, refetch } = useGetStatusLeadQuery('');

    useEffect(() => {

        let int: any;

        if (!stateUpload.includes(resultStatus?.stato)) {
            int = setInterval(() => { refetch() }, 2000)
        } else {
            return clearInterval(int)
        }

        return (() => clearInterval(int))

    }, [resultStatus?.stato])

    const handleUpload = async (data: any) => {

        const formData = new FormData();
        formData.append('file', data?.lead[0]);

        let result: any = await uploadLead(formData);

        if ('error' in result) {
            console.log('ERROR DOWNLOAD')
            toast.error('Errore nel caricamento', result?.error)
        } else {
            setValue('lead', null);
            toast.success(`Report caricato a breve sarà disponibile il download`);
        }

        refetch();

    }

    const handleDownload = async () => {
        let result: any = await downloadLead('');

        if ('error' in result) {
            toast.error('Errore download', result?.error)
        } else {
            saveAs(result?.data, `Lead_${Date.now()}.xlsx`)
            toast.success(`Lead scaricato`);
        }

        refetch();
    }

    return (
        <>
            <div className="bg-white shadow-lg rounded-lg p-5">

                <form onSubmit={handleSubmit(handleUpload)} className="grid grid-cols-3 gap-6">
                    <InputsFields isDisabled={!stateUpload.includes(resultStatus?.stato)} arrFields={ReportLeadListInputs} form={form} />

                    <div className="col-span-2 text-right">
                        <button disabled={!stateUpload.includes(resultStatus?.stato)} type="submit" className={`${!stateUpload.includes(resultStatus?.stato) && 'opacity-40'} h-10 w-52 bg-[#064e3b] text-white rounded-lg px-10 text-md col-span-2`}>
                            Carica lead
                        </button>
                    </div>
                </form>
            </div>

            <div className="bg-white shadow-lg rounded-lg p-5 my-4">
                <div>
                    <div className="mb-2 flex justify-between items-center">
                        <div className="flex items-center gap-x-3">
                            <span className="size-8 flex justify-center items-center border border-gray-200 text-gray-500 rounded-lg">
                                <img src={excelImg} alt="icon" />
                            </span>
                            <div>
                                <p className="font-semibold text-base text-gray-600">Stato file lead</p>
                                <p className="text-lg text-gray-500">{resultStatus?.stato}</p>
                            </div>
                        </div>

                        <div className="inline-flex items-center gap-x-2">
                            {resultStatus?.stato === 'Completato' &&
                                <button className="flex space-x-4 items-center" onClick={handleDownload}>
                                    Scarica file
                                    <DocumentArrowDownIcon className="w-7 h-6 block cursor-pointer" />
                                </button>
                            }
                        </div>
                    </div>

                    {(resultStatus?.percentuale || resultStatus?.stato === 'Completato') && <div className="flex items-center gap-x-3 whitespace-nowrap">
                        <div className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden" role="progressbar">
                            <div
                                className={`flex flex-col justify-center rounded-full overflow-hidden ${resultStatus?.stato === 'Completato' ? 'bg-green-600' : 'bg-red-500'} text-xs text-white text-center whitespace-nowrap transition duration-500`}
                                style={{ width: `${resultStatus?.stato === 'Completato' ? '100' : resultStatus?.percentuale}%`}}
                            ></div>
                        </div>
                        <div className="w-6 text-end">
                            <span className="text-sm text-gray-800">{`${resultStatus?.stato === 'Completato' ? '100' : resultStatus?.percentuale}`}%</span>
                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
}