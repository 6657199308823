
export const ReportFatturatoListInputs = (consulenteOptions: any[], optionRegioni: any[]) => ([
    {
        label: 'Data da',
        name: 'DataDa',
        placeholder: 'Data da',
        type: 'text',
        validators: {
            required: 'Il campo è richiesto'
        },
        type_input: 'picker',
        size_input: 'w-full',
        isLimitDate: false,
        isTimeShow: false
    },
    {
        label: 'Data a',
        name: 'DataA',
        placeholder: 'Data a',
        type: 'text',
        validators: {
            required: 'Il campo è richiesto'
        },
        type_input: 'picker',
        size_input: 'w-full',
        isLimitDate: false,
        isTimeShow: false
    },
    {
        label: 'Consulente',
        name: 'IdConsulente',
        placeholder: 'Consulente',
        type: 'text',
        validators: {
            required: 'Il campo è richiesto'
        },
        type_input: 'react-select',
        size_input: 'w-full',
        options: consulenteOptions
    },
    {
        label: 'Regione',
        name: 'Regione',
        placeholder: 'Regione',
        type: 'text',
        validators: {
            required: 'Il campo è richiesto'
        },
        type_input: 'react-select',
        size_input: 'w-full',
        options: optionRegioni
    }
]);