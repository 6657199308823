import { useState } from "react";
import InterviewsFilters from "../../components/filters/InterviewsFilters";
import Loader from "../../components/main/Loader";
import TableComponent from "../../components/main/Table";
import { InterviewAdapterFilterSent } from "../../helpers/adapter/filters/AdapterFilterInterviews";
import { FiltersCallsAdapter } from "../../helpers/adapter/FiltersCallsAdapter";
import { initialParams, IPagination, PaginationHelper } from "../../helpers/PaginationHelper";
import { useGetInterviewsQuery } from "../../store/services/InterviewService";
import { HeaderInterviews } from "../../utils/headerTable/TabHeaderInterviews";

export default function InterviewListPage() {

    const [queryParams, setQueryParams] = useState<IPagination>(initialParams);
    const [filterParams, setFilterParams] = useState<any>({});
    let {isSuccess, isLoading, data, isFetching } = useGetInterviewsQuery({...queryParams, ...filterParams});

    const handleActionTable = (data: any) => {
        let paramsPagination: any = PaginationHelper(queryParams, data);
        setQueryParams(paramsPagination);
    }

    const handleSetFilter = (dataFilter: any) => {
        let filterAdapter: any = InterviewAdapterFilterSent(dataFilter)
        setFilterParams(filterAdapter);
        setQueryParams(initialParams);
    }

    return(
        <>  
            {(isLoading || isFetching) && <Loader />}
            <h2 className="text-xl my-6">Lista interviste</h2>

            <InterviewsFilters methodParent={handleSetFilter} />

            <div className="mt-10">
            {isSuccess && data?.result.length > 0 
                ? <TableComponent columns={HeaderInterviews} data={data?.result} meta={data?.meta} parentMethod={handleActionTable} pageIndex={queryParams?.CurrentPage} pageSizeParent={queryParams?.PageSize} sortDirectionParent={queryParams?.sortDirection} currentSortCol={queryParams?.sortIdCol} /> 
                : <p className="p-6 text-center">NESSUNA INTEVISTA PRESENTE</p>
            }
            </div>

        </>
    )
}