
export interface IFilterUser {
    Nome: string | null,
    Cognome: string | null,
    CodiceUtente: string | null,
    Email: string | null,
    Regione: {value: string, label: string} | any,
    Ruolo: {value: string, label: string} | any,
    SupervisoreId: {value: string, label: string} | any
}


export function UserAdapterFilterSent(user: IFilterUser) {
    let userMap: IFilterUser;
    return userMap = {
            Nome: user?.Nome,
            Cognome: user?.Cognome,
            CodiceUtente: user?.CodiceUtente,
            Regione: user?.Regione?.value ? user?.Regione?.value : '' ,
            Ruolo: user?.Ruolo?.value ? user?.Ruolo?.value : '',
            Email: user?.Email,
            SupervisoreId: user?.SupervisoreId?.value ? user?.SupervisoreId?.value : ''
    }
}