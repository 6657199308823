import { useRoutes } from "react-router-dom";
import LoginPage from "../pages/main/Login";
import MainPage from "../pages/main/MainPage";
import HostRegistryList from "../pages/hostRegistry/HostRegistryList";
import MainHostRegistry from "../pages/hostRegistry/MainHostRegistry";
import MainPageUser from "../pages/users/MainUserPage";
import NewUserPage from "../pages/users/NewUserPage";
import UsersListPage from "../pages/users/UsersList";
import PrivateGuard from "./guard/PrivateGuard";
import PublicGuard from "./guard/PublicGuard";
import HostRegistryNewPage from "../pages/hostRegistry/HostRegistryNewPage";
import CallsListPage from "../pages/calls/CallsListPage";
import InterviewListPage from "../pages/interview/InterviewListPage";
import HomePage from "../pages/HomePage";
import RoleGuard from "./guard/RoleGuard";
import { ListInterviewRole, SuperAdminVisore, SuperAdminVisoreOpe, SuperAdminVisoreOpeCons, SuperAndAdmin } from "../utils/genericList/ListRoleRoutes";
import ReportPage from "../pages/report/ReportPage";
import ProductsPage from "../pages/products/ProductsPage";


export default function RoutesList() {

    return useRoutes([
        {
            path: '/',
            element: <PublicGuard>
                <LoginPage />
            </PublicGuard>
        },
        {
            path: '/dashboard',
            element:
                <PrivateGuard>
                    <MainPage />
                </PrivateGuard>,
            children: [
                {
                    path: '/dashboard/home',
                    element: <HomePage />
                },
                {
                    path: 'users',
                    element: <RoleGuard visabilityRole={SuperAdminVisore}>
                        <MainPageUser />
                    </RoleGuard>,
                    children: [
                        {
                            path: '/dashboard/users',
                            element: <UsersListPage />
                        },
                        {
                            path: 'new-user',
                            element: <NewUserPage />
                        }
                    ]
                },
                {
                    path: 'host-registry',
                    element: <RoleGuard visabilityRole={SuperAdminVisoreOpeCons}>
                        <MainHostRegistry />
                    </RoleGuard>,
                    children: [
                        {
                            path: '/dashboard/host-registry',
                            element: <HostRegistryList />
                        },
                        {
                            path: 'new',
                            element: <HostRegistryNewPage />
                        } 
                    ]
                },
                {
                    path: 'calls',
                    element: <RoleGuard visabilityRole={SuperAdminVisoreOpe}>
                        <CallsListPage />
                    </RoleGuard>,
                },
                {
                    path: 'interviews',
                    element: <RoleGuard visabilityRole={ListInterviewRole}>
                        <InterviewListPage />
                    </RoleGuard>,
                },
                {
                    path: 'reports',
                    element:  <RoleGuard visabilityRole={SuperAdminVisore}>
                        <ReportPage />
                    </RoleGuard>,
                },
                {
                    path: 'products',
                    element: <RoleGuard visabilityRole={SuperAndAdmin}>
                        <ProductsPage />
                    </RoleGuard>,
                }
            ]
        },
    ])
};