import { RegistryAdapterValues } from "../../helpers/adapter/RegistryAdapter";
import CategorieProfesionaliLookUp from "../../lookup/CategorieProf";
import { useGetHostRegistryByIdQuery } from "../../store/services/HostRegistryService";
import HostRegistryForm from "../form/HostRegistryForm";
import Loader from "../main/Loader";

export default function UpdateHostRegistry({ row, fromComponent }: any) {
    const { data, isLoading, isSuccess } = useGetHostRegistryByIdQuery(row?.id);
    const catProfessionali = CategorieProfesionaliLookUp();

    return (
        <div className='relative'>
            {(isLoading || catProfessionali?.length === 0 || !catProfessionali) && <Loader />}
            {data?.result?.daMigrazione && isSuccess &&
                <div className="bg-red-100 border-t border-b border-red-500 text-red-700 px-4 py-3 m-8" role="alert">
                    <p className="font-bold">Anagrafica importata</p>
                    <p className="text-sm">ATTENZIONE: si tratta di un'anagrafica importata, pertanto occorre prestare attenzione a tutti i campi già presenti e completare quelli mancanti per procedere al salvataggio.</p>
                </div>
            }

            {!data?.result?.daMigrazione && !data?.result?.canBeManaged && isSuccess ?
                <div className="bg-orange-100 border-t border-b border-orange-500 text-orange-700 px-4 py-3 m-8" role="alert">
                    <p className="text-sm">Questa anagrafica è attualmente gestita da un altro operatore e non può essere modificata</p>
                </div>
                :null
            }
            
            {isSuccess && catProfessionali?.length > 0
                ? <HostRegistryForm fromComponent={fromComponent} infoForUpdateRegistry={row} infoGet={data?.result} type={'update'} catProf={catProfessionali} />
                : <div className="h-96 block"></div>
            }
        </div>
    )
}