
export const InputsListProductsFilter = [
    {
        label: 'Nome prodotto',
        name: 'nome',
        placeholder: 'Nome prodotto',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    },
]