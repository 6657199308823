import { PlusIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

interface IPropsAddBtn {
    path: string,
    label: string
}

export default function ButtonAdd({path, label}: IPropsAddBtn) {

    return(
        <Link to={path} className="w-auto text-gray-400 px-5 border-none flex items-center justify-center">
            <PlusIcon className="w-5 h-5 mr-3" /><span>{label}</span>
        </Link>
    )
}