

import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectCurrentUserInfo } from "../../store/feautures/AuthSlice";


export default function RoleGuard({children, visabilityRole} : any) {
    const {role} = useSelector(selectCurrentUserInfo);
    const isRoleAuth = visabilityRole.includes(role);
    return !isRoleAuth ? <Navigate to={"/dashboard/home"} /> : <>{children}</>;
 }
 