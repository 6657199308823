import { QueryStringHelper } from "../../helpers/QueryHelper";
import { emptySplitApi } from "./InjectionEndpoint"

const interviewsService = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getInterviews: build.query({
            query: (params: any) => `/api/intervista?${QueryStringHelper(params)}`,
            providesTags: ['interviews']
        }),

        getInterviewsById: build.query({
            query: (id: any) => `/api/intervista/${id}`,
            providesTags: ['interviews']
        }),

        setStateInterview: build.mutation({
            query: ({id, ...rest}) => ({
                url: `/api/intervista/${id}/esito`,
                method: 'POST',
                body: rest
            }),
            invalidatesTags: ['interviews']
        }),

        updateInterview: build.mutation({
            query: ({id, ...rest}) => ({
                url: `/api/intervista/${id}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['interviews']
        }),

        createInvoice: build.mutation({
            query: ({idInterview, idPagamento}) => ({
                url: `/api/intervista/${idInterview}/pagamento/${idPagamento}/fattura`,
                method: 'POST',
            }),
            invalidatesTags: ['interviews']
        }),

    }),
})

export const {
    useGetInterviewsQuery,
    useGetInterviewsByIdQuery,
    useSetStateInterviewMutation,
    useUpdateInterviewMutation,
    useCreateInvoiceMutation
} = interviewsService;