
export default function CustomInput({ openCalendar, value, handleValueChange, input, isDisabled, datePickerRef }: any) {


    return (
        <div className={'relative group'}>
            <input
                disabled={isDisabled}
                className={`${isDisabled ? 'opacity-60 cursor-not-allowed' : ''} bg-[#efefef] rounded-md p-2 peer h-10 w-full text-skin-textInput placeholder-transparent focus:outline-none focus:border-skin-inputFocus text-base`}
                onFocus={openCalendar}
                value={value || ""}
                onChange={handleValueChange}
            />
                <label htmlFor={input?.name} className={`${value ? '-top-5 left-2 text-[#064e3b]' : 'top-2 left-2'} ${!isDisabled ? 'group-hover:-top-5': ''} transition-all absolute text-base py-1 text-[#9CA3AF] z-0`}>
                {input?.placeholder}
            </label>
        </div>
    )
}