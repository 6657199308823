
export const InterviewListFilter = (optionsEsiti: any[], optionsStati: any[], optionOperatore: any[], optionConsulente: any[], optionSpeaker: any[], optionMontatore: any[], optionProduct: any[], optionStateVideo: any[], optionPaymentState: any[]) => {
    let inputs: any;
    return inputs = [
        {
            label: 'Nome ospite',
            name: 'NomeOspite',
            placeholder: 'Nome ospite',
            type: 'text',
            validators: {},
            type_input: 'input',
            size_input: 'w-full'
        },
        {
            label: 'Cognome ospite',
            name: 'CognomeOspite',
            placeholder: 'Cognome ospite',
            type: 'text',
            validators: {},
            type_input: 'input',
            size_input: 'w-full'
        },
        {
            label: 'Telefono ospite',
            name: 'TelefonoOspite',
            placeholder: 'Telefono ospite',
            type: 'text',
            validators: {},
            type_input: 'input',
            size_input: 'w-full'
        },
        {
            label: 'Codice ospite',
            name: 'CodiceOspite',
            placeholder: 'Codice ospite',
            type: 'text',
            validators: {},
            type_input: 'input',
            size_input: 'w-full'
        },
        {
            label: 'Stato intervista',
            name: 'Stato',
            placeholder: 'Stato intervista',
            type: 'text',
            validators: {},
            type_input: 'react-select',
            size_input: 'w-full',
            options: [{value: '', label: 'Qualsiasi'}].concat(optionsStati)
        },
        {
            label: 'Esito consulenza',
            name: 'Esito',
            placeholder: 'Esito consulenza',
            type: 'text',
            validators: {},
            type_input: 'react-select',
            size_input: 'w-full',
            options: optionsEsiti
        },
        {
            label: 'Nominativo o cod operatore',
            name: 'NominativoOCodiceOpearatoreChiamataId',
            placeholder: 'Nominativo o codice operatore',
            type: 'text',
            validators: {},
            type_input: 'react-select',
            size_input: 'w-full',
            options: optionOperatore
        },
        {
            label: 'Nominativo o cod consulente',
            name: 'NominativoOCodiceConsulenteId',
            placeholder: 'Nominativo consulente',
            type: 'text',
            validators: {},
            type_input: 'react-select',
            size_input: 'w-full',
            options: optionConsulente
        },
        {
            label: 'Nominativo o cod speaker',
            name: 'NominativoOCodiceSpeakerId',
            placeholder: 'Nominativo o cod speaker',
            type: 'text',
            validators: {},
            type_input: 'react-select',
            size_input: 'w-full',
            options: optionSpeaker
        },
        {
            label: 'Nominativo o cod montatore',
            name: 'NominativoOCodiceMontatoreId',
            placeholder: 'Nominativo o cod montatore',
            type: 'text',
            validators: {},
            type_input: 'react-select',
            size_input: 'w-full',
            options: optionMontatore
        },
        {
            label: 'Data consulenza da',
            name: 'DataConsulenzaDa',
            placeholder: 'Data consulenza da',
            type: 'text',
            validators: {},
            size_input: 'w-full',
            type_input: 'picker',
            isLimitDate: false,
            isTimeShow: false,
        },
        {
            label: 'Data consulenza a',
            name: 'DataConsulenzaA',
            placeholder: 'Data consulenza a',
            type: 'text',
            validators: {},
            size_input: 'w-full',
            type_input: 'picker',
            isLimitDate: false,
            isTimeShow: false,
        },
        {
            label: 'Data intervista da',
            name: 'DataIntervistaDa',
            placeholder: 'Data intervista da',
            type: 'text',
            validators: {},
            size_input: 'w-full',
            type_input: 'picker',
            isLimitDate: false,
            isTimeShow: false,
        },
        {
            label: 'Data intervista a',
            name: 'DataIntervistaA',
            placeholder: 'Data intervista a',
            type: 'text',
            validators: {},
            size_input: 'w-full',
            type_input: 'picker',
            isLimitDate: false,
            isTimeShow: false,
        },
        {
            label: 'Data consegna da',
            name: 'DataConsegnaVideoDa',
            placeholder: 'Data consegna da',
            type: 'text',
            validators: {},
            size_input: 'w-full',
            type_input: 'picker',
            isLimitDate: false,
            isTimeShow: false,
        },
        {
            label: 'Data consegna a',
            name: 'DataConsegnaVideoA',
            placeholder: 'Data consegna a',
            type: 'text',
            validators: {},
            size_input: 'w-full',
            type_input: 'picker',
            isLimitDate: false,
            isTimeShow: false,
        },
        {
            label: 'Stato video',
            name: 'StatoVideo',
            placeholder: 'Stato video',
            type: 'text',
            validators: {},
            type_input: 'react-select',
            size_input: 'w-full',
            options: optionStateVideo
        },
        {
            label: 'Metodo pagamento',
            name: 'MetodoPagamento',
            placeholder: 'Metodo pagamento',
            type: 'text',
            validators: {},
            type_input: 'react-select',
            size_input: 'w-full',
            options: [
                {value: '', label: 'Qualsiasi'},
                {value: 'Bonifico' , label: 'Bonifico'},
                {value: 'Carta', label: 'Carta o bancomat' },
                {value: 'Contanti', label: 'Contanti' }
            ]
        },
        {
            label: 'Stato pagamento',
            name: 'StatoPagamento',
            placeholder: 'Stato pagamento',
            type: 'text',
            validators: {},
            type_input: 'react-select',
            size_input: 'w-full',
            options: [{ value: '', label: 'Qualsiasi' }].concat(optionPaymentState)
            // options: optionPaymentState
        },
        {
            label: 'Prodotto',
            name: 'prodotto',
            placeholder: 'Prodotto',
            type: 'text',
            validators: {},
            type_input: 'react-select',
            size_input: 'w-full',
            options: [{ value: '', label: 'Qualsiasi' }].concat(optionProduct)
        },
    ]}


    export const  InputFilterSede = (optionSede: []) => {
            
        let input = [{
            label: 'Sede',
            name: 'Sede',
            placeholder: 'Sede',
            type: 'text',
            validators: {},
            type_input: 'react-select',
            size_input: 'w-full',
            options: [{value: '', label: 'Qualsiasi'}].concat(optionSede)
        }]

        return input;
    }

    export const RicercaGlogale = [
        {
            label: 'Ricerca globale',
            name: 'RicercaGlobale',
            placeholder: 'Ricerca globale',
            type: 'text',
            validators: {},
            type_input: 'input',
            size_input: 'w-full'
        },
    ]