import { ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/24/outline";
import moment from "moment-timezone";
import { useState } from "react";
import { useForm } from "react-hook-form";
import InputsFields from "../../helpers/InputFields";
import RegioniLookUp from "../../lookup/Regioni";
import { ReportConsulListInputFilters } from "../../utils/listInputs/ReportConsuListInputFilter";

export const InitialValueFilterReportOp: any = {
    DataDa: moment().tz("Europe/London").format('DD-MM-YYYY'),
    DataA: moment().tz("Europe/London").format('DD-MM-YYYY'),
    IdConsulente: {value: '', label: 'Qualsiasi'},
    Regione: {value: '', label: 'Qualsiasi'}
}

export default function ReportConsulenteFilters({methodParent, consulenteData}: any) {

    let mapOp = consulenteData?.map((op: any) => ({value: op?.id, label: `${op?.nominativo} (${op?.codiceUtente})`}));
    const anyOption = [{value: '', label: 'Qualsiasi'}];
    const consulenteOptions = anyOption.concat(mapOp);
    const optionRegioni = anyOption.concat(RegioniLookUp(false));

    const handleSubmitFIlter = (data: any) => {
        methodParent(data)
    }

    const form = useForm({defaultValues: InitialValueFilterReportOp});
    const [isOpenFilter, setIsOpenFilter] = useState<boolean>(true);
    const { handleSubmit } = form;

    return(
        <div className="bg-white shadow-lg rounded-lg">
            <div className="flex justify-between items-center p-4" >
                <p>Filtri</p>
                <button onClick={() => setIsOpenFilter(!isOpenFilter)}>
                    {isOpenFilter ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />}
                </button>
            </div>
            {isOpenFilter && <form onSubmit={handleSubmit(handleSubmitFIlter) } >

                <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 grid-rows-none gap-6 p-4">
                    <InputsFields arrFields={ReportConsulListInputFilters(consulenteOptions, optionRegioni)} form={form} />
                </div>

                <div className="flex items-center justify-end space-x-5 pb-5 w-full px-5">
                    <button type="submit" className="h-10 w-52 bg-[#064e3b] text-white rounded-lg px-10 text-md">
                        Download report
                    </button>
                </div>

            </form>}
        </div>
    )
}