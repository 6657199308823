import { useState } from "react";
import CallsFilters from "../../components/filters/CallsFilters";
import Loader from "../../components/main/Loader";
import TableComponent from "../../components/main/Table";
import { FiltersCallsAdapter, IFiltersCallsAdapter } from "../../helpers/adapter/FiltersCallsAdapter";
import { initialParams, IPagination, PaginationHelper } from "../../helpers/PaginationHelper";
import { useGetCallsQuery } from "../../store/services/CallsService";
import { HeaderCalls } from "../../utils/headerTable/TabHeaderCalls";
import EsitoLookUp from "../../lookup/Esito";

export default function CallsListPage() {

    const [queryParams, setQueryParams] = useState<IPagination>(initialParams);
    const [filterParams, setFilterParams] = useState<any | IFiltersCallsAdapter>({});
    let {isSuccess, isLoading, data, isFetching } = useGetCallsQuery({...queryParams, ...filterParams});
    const optionEsito = EsitoLookUp();

    const handleActionTable = (data: any) => {
        let paramsPagination: any = PaginationHelper(queryParams, data);
        setQueryParams(paramsPagination);
    }

    const handleSetFilter = (dataFilter: any) => {
        let filterAdapter: any = FiltersCallsAdapter(dataFilter)
        setFilterParams(filterAdapter);
        setQueryParams(initialParams);
    }

    return(
        <>  
            {(isLoading || isFetching) && <Loader />}
            <h2 className="text-xl my-6">Lista chiamate</h2>

            <CallsFilters methodParent={handleSetFilter} optionsEsiti={optionEsito} />

            <div className="mt-10">
            {isSuccess && data?.result.length > 0 
                ? <TableComponent columns={HeaderCalls(optionEsito)} data={data?.result} meta={data?.meta} parentMethod={handleActionTable} pageIndex={queryParams?.CurrentPage} pageSizeParent={queryParams?.PageSize} sortDirectionParent={queryParams?.sortDirection} currentSortCol={queryParams?.sortIdCol} /> 
                : <p className="p-6 text-center">NESSUNA CHIAMATA PRESENTE</p>
            }
            </div>

        </>
    )
}