import { PatternHelper } from "../PatternHelper";

export const LoginListInput = [
    {
        label: 'Email',
        name: 'username',
        placeholder: 'Email',
        type: 'text',
        validators: {
            required: 'Il campo è obbligatorio',
            pattern: {
                value: PatternHelper.email,
                message: "Email non valida"
            }
        },
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Password',
        name: 'password',
        placeholder: 'Password',
        type: 'text',
        validators: {
            required: 'Il campo è obbligatorio'
        },
        type_input: 'password',
        size_input: 'w-full',
    }
]