import { ChevronLeftIcon } from "@heroicons/react/24/outline";


export default function DetailArgoment({ argument, methodParent }: { argument: any, methodParent: any }) {
    
    return (
        <>
            <div className="flex items-center justify-end">
                <button className="w-auto text-gray-400 px-5 border-none flex items-center justify-center" onClick={() => methodParent(false)} >
                    <ChevronLeftIcon className="w-5 h-5 mr-4" />
                    Chiudi dettaglio
                </button>
            </div>

            <fieldset className="border rounded-lg p-5 my-5">
                <legend className="text-lg bg-white px-2">Argomento</legend>
                <p className="text-base">{argument?.argument?.argomento}</p>
            </fieldset>

            <fieldset className="border rounded-lg p-5 my-5">
                <legend className="text-lg bg-white px-2">Note</legend>
                <p className="text-base">{argument?.argument?.note}</p>
            </fieldset>
        </>
    )
}

