
export default function CheckboxInput({ input, form }: any) {

    const { register } = form;

    return (
        <div className={'flex items-start space-x-4'}>
            <input value={input.value} {...register(input.name, { ...input.validators })} id={input.label} type="checkbox" className="h-5 w-6 block rounded-lg focus:outline-none accent-[#064e3b] focus-[#064e3b] hover-[#064e3b]" />
            <label htmlFor={input.label} className="select-none cursor-pointer block text-base">
                {input.label}
            </label>
        </div>
    )
}