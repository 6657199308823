
export const ListRoleSuperAdmin = [
    {value: 'SuperAdmin', label: 'SuperAdmin'},
    {value: 'Admin', label: 'Admin'},
    {value: 'Operatore', label: 'Operatore'},
    {value: 'Supervisore', label: 'Supervisore'},
    {value: 'Consulente', label: 'Consulente'},
    {value: 'Speaker', label: 'Speaker'},
    {value: 'Montatore', label: 'Montatore'}
];

export const ListRoleAdmin = [
    {value: 'Admin', label: 'Admin'},
    {value: 'Operatore', label: 'Operatore'},
    {value: 'Supervisore', label: 'Supervisore'},
    {value: 'Consulente', label: 'Consulente'},
    {value: 'Speaker', label: 'Speaker'},
    {value: 'Montatore', label: 'Montatore'}
];

export const ListRoleOnlyOperator = [
    {value: 'Operatore', label: 'Operatore'},
];

export interface IRoleList {
    SuperAdmin: string,
    Admin: string,
    Operatore: string,
    Supervisore: string,
    Consulente: string,
    Speaker: string,
    Montatore: string
}

export const LabelAccountRole: IRoleList | any = {
    SuperAdmin: 'Superadmin',
    Admin: 'Admin',
    Operatore: 'Operatore',
    Supervisore: 'Supervisore',
    Consulente: 'Consulente',
    Speaker: 'Speaker',
    Montatore: 'Montatore'
};

export const SeletRoleOption: any = {
    SuperAdmin: ListRoleSuperAdmin,
    Admin: ListRoleAdmin,
    Supervisore: ListRoleOnlyOperator,
}