import { toast } from "react-toastify";
import { convertToNumber } from "../utils/functions/ConvertToNumber";

export function FormAlert(isValid: 'show' | 'noShow', errors: any) {

    if(isValid === 'show') {
        toast.error('Ci sono degli errori nel form controlla')
    }

}

export function AlertPaymentImport(products: any, payments: any, initialValues: any) {
 
    const totAmountProd = products?.reduce((acc: number, prod: {prezzo: number | string}) => acc + convertToNumber(prod.prezzo), 0);
    const totPayments = payments?.reduce((acc: number, payment: {importo: number | string}) => acc + convertToNumber(payment.importo), 0);
    let ctrlOutcome = {isPayment: false, isProd: false};

    const hasChangeProd = hasArrayChanged(initialValues?.prodotti, products, 'prod');
    const hasChangePayment = hasArrayChanged(initialValues?.pagamenti, payments, 'payment');

    const totProducsFormatted = convertToNumber(totAmountProd);
    const totPaymentsFormatted = convertToNumber(totPayments);

    if(hasChangeProd && totPaymentsFormatted !== totProducsFormatted) ctrlOutcome.isProd = true;
    if(hasChangePayment && totPaymentsFormatted !== totProducsFormatted) ctrlOutcome.isPayment = true;

    return ctrlOutcome;
}

export const hasArrayChanged = (oldArray: any[], newArray: any[], type: string) => {

    let isShow = false;

    // Controlla se l'array è cambiato in lunghezza
    if(oldArray?.length !== newArray?.length) {return isShow = true};

    // Controllo se è cambiato l'importo
    if(type === 'prod') {
        return isShow = oldArray?.some((item: any, index: number) => convertToNumber(item?.prezzo) !== convertToNumber(newArray[index]?.prezzo));
    }

    if(type === 'payment') {
        return isShow = oldArray?.some((item: any, index: number) => convertToNumber(item?.importo) !== convertToNumber(newArray[index]?.importo));
    }
    
    return isShow;
}