import EsitoLookUp from "../../lookup/Esito";
import InteresseVideoLookUp from "../../lookup/InteresseVideo";
import { useGetCallByIdQuery } from "../../store/services/CallsService";
import { useGetHostRegistryByIdQuery } from "../../store/services/HostRegistryService";
import CallsForm from "../form/CallForm";
import Loader from "../main/Loader";

export default function UpdateCall({row}: any) {

    const { data, isLoading, isFetching, isSuccess } = useGetCallByIdQuery(row?.callId);
    let optionEsito = EsitoLookUp();
    let optionInteresseVideo = InteresseVideoLookUp();

    return(
        <div className="relative">
            {(isLoading || isFetching || optionEsito?.length === 0 || optionInteresseVideo?.length === 0) && <Loader />}
            {isSuccess &&  optionEsito?.length > 0 && optionInteresseVideo?.length > 0 
                ? <CallsForm infoRegistry={data?.result} defaultValues={data?.result} type={'update'} lastCall={data?.result?.ultimaChiamata} optionEsito={optionEsito} optionInteresseVideo={optionInteresseVideo} />
                : null 
            }
        </div>
    )

}