
export default function Loader() {

    return(

        <div className="fixed top-0 left-0 bottom-0 right-0 z-50 flex items-center justify-center bg-white bg-opacity-70">
            <div className="w-24 h-24 border-t-2 border-b-2 border-[#064e3b] rounded-full animate-spin"></div>
        </div>

    )
}