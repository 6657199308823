import ActionOpenDialogCall from "../../components/action/ActionOpenDialogCall";
import { GetEsito } from "../../helpers/CallsLabelHelpes";
import { DataFormatHelperTab, DataFormatHelperTabNoTime } from "../../helpers/DataFormatHelper";

export const HeaderCalls = (optionEsito: any[]) =>  ([
    {
        Header: "Cod. ospite",
        accessor: "anagraficaOspiteCodice",
        disableSortBy: true,
        Filter: false,
        customSort: false,
        Cell: ({ row }: any) => (
            <>
                <p>{row?.original?.anagraficaOspiteCodice}</p>
                <p>{DataFormatHelperTabNoTime(row?.original?.creationDate)}</p>
            </>
        )
    },
    {
        Header: "Nominativo",
        accessor: "anagraficaOspiteNominativo",
        disableSortBy: false,
        Filter: false,
        customSort: true,
        Cell: ({ row }: any) => (
            <>
                <p className="truncate w-36">{row?.original?.anagraficaOspiteNominativo}</p>
                <p className="truncate w-36">Az: {row?.original?.anagraficaOspiteAzienda}</p>
                <p className="truncate w-36">Tel: {row?.original?.anagraficaOspiteTelefono}</p>
            </>
        )
    },
    {
        Header: "Categoria",
        accessor: "categoria",
        disableSortBy: false,
        Filter: false,
        customSort: false,
        Cell: ({ row }: any) => (
            <>
                <p className="truncate w-36">{row?.original?.anagraficaOspiteCategoriaProfessionale}</p>
                <p className="truncate w-36">{row?.original?.anagraficaOspiteSottocategoriaProfessionale}</p>
            </>
        )
    },
    {
        Header: "Regione",
        accessor: "anagraficaOspiteRegione",
        disableSortBy: true,
        Filter: false,
        customSort: false
    },
    {
        Header: "Cod. operatore",
        accessor: "operatoreChiamataCodice",
        disableSortBy: true,
        Filter: false,
        customSort: false,
        Cell: ({ row }: any) => (
            <>
                <p>{row?.original?.operatoreChiamataCodice}</p>
                <p>{row?.original?.operatoreChiamataNominativo}</p>
            </>
        )
    },
    {
        Header: "Data chiamata",
        accessor: "creationDate",
        disableSortBy: false,
        Filter: false,
        customSort: true,
        Cell: ({ row }: any) => (
            row?.original?.creationDate ? DataFormatHelperTab(row?.original?.creationDate) : '-'
        )
    },
    {
        Header: "Data appuntamento",
        accessor: "dataAppuntamento",
        disableSortBy: false,
        Filter: false,
        customSort: true,
        Cell: ({ row }: any) => (
            row?.original?.dataAppuntamento ? DataFormatHelperTab(row?.original?.dataAppuntamento) : '-'
        )
    },
    {
        Header: "Esito",
        accessor: "esito",
        disableSortBy: true,
        Filter: false,
        customSort: false,
        Cell: ({ row }: any) => {
            let esito = optionEsito?.find((esito: {value: string, label: string}) => esito?.value === row?.original?.esito)
            return <p>{esito?.label}</p>
        }
    },
    {
        Header: "",
        accessor: "action",
        disableSortBy: true,
        Filter: false,
        customSort: false,
        Cell: ({ row }: any) => (
            <div className="flex space-x-6 justify-end px-5">
                <ActionOpenDialogCall  row={row?.original} label={'info'} />
            </div>
        )
    }
])