
export interface IRegistryFormSent {
    nome: string,
    cognome: string,
    nome2: string | null,
    cognome2: string | null,
    nome3: string | null,
    cognome3: string | null,
    nomeArte: string| null,
    telefono: string,
    telefono2: string | null,
    email: string,
    azienda: string,
    indirizzoAzienda: string | null,
    regione: string,
    categoriaProfessionaleId: number | any,
    emailPubblicazione: string,
    facebook: string,
    instagram: string,
    linkedin: string,
    youtube: string,
    tikTok: string | null,
    sottocategoriaProfessionale: string,
    idOperatoreCreazione?: any,
    sitoWeb: string,
    telefonoFisso: string | null
}

export interface IRegistryFormUpdate {
    nome: string,
    cognome: string,
    nome2: string,
    cognome2: string,
    nome3: string | null,
    cognome3: string | null,
    nomeArte: string | null,
    telefono: string,
    telefono2: string,
    email: string,
    azienda: string,
    indirizzoAzienda: string,
    regione: {value: string, label: string} | any,
    categoriaProfessionaleId: {value: number, label: string | any} | any,
    emailPubblicazione: string,
    facebook: string,
    instagram: string,
    linkedin: string,
    youtube: string,
    tikTok: string,
    sottocategoriaProfessionale: string,
    idOperatoreCreazione?: any,
    sitoWeb: string,
    telefonoFisso: string | null
}

export function RegistryAdapterValues(registry: any, catProfessionali: any) {
    let registryMap: IRegistryFormUpdate = {
        nome: registry?.nome,
        cognome: registry?.cognome,
        nome2: registry?.nome2,
        cognome2: registry?.cognome2,
        nome3: registry?.nome3,
        cognome3: registry?.cognome3,
        nomeArte: registry?.nomeArte,
        telefono: registry?.telefono,
        telefono2: registry?.telefono2,
        email: registry?.email,
        azienda: registry?.azienda,
        regione: registry?.regione && registry?.regione !== 'Sconosciuta'  ? {
            value: !registry?.regione || registry?.regione === 'Sconosciuta' ? "" : registry?.regione, 
            label: !registry?.regione || registry?.regione === 'Sconosciuta' ? "" : registry?.regione
        } : "",
        indirizzoAzienda: registry?.indirizzoAzienda,
        categoriaProfessionaleId: registry?.categoriaProfessionaleId && registry?.categoriaProfessionaleName !== 'SCONOSCIUTA'
        ? {
            value: registry?.categoriaProfessionaleId || "",
            label: catProfessionali?.map(
                (el: any, key: number) =>  {
                    if(el.value === registry?.categoriaProfessionaleId) {
                        return catProfessionali[key].label
                    }
                }
            )
        }
        : "",
        emailPubblicazione: registry?.emailPubblicazione,
        facebook: registry?.facebook,
        instagram: registry?.instagram,
        linkedin: registry?.linkedin,
        youtube: registry?.youtube,
        tikTok: registry?.tikTok,
        sottocategoriaProfessionale: registry?.sottocategoriaProfessionale,
        sitoWeb: registry?.sitoWeb,
        idOperatoreCreazione: registry?.createdBy ? {
            value: registry?.createdBy?.id, 
            label: `${registry?.createdBy?.nome} ${registry?.createdBy?.cognome} (${registry?.createdBy?.codiceUtente})`
        } : "",
        telefonoFisso: registry?.telefonoFisso
    }    

    return registryMap;
}

export function RegistryAdapterSent(registry: IRegistryFormUpdate) {
    let userMap: IRegistryFormSent = {
        nome: registry?.nome,
        cognome: registry?.cognome,
        nome2: registry?.nome2 || null,
        cognome2: registry?.cognome2 || null,
        nome3: registry?.nome3 || null,
        cognome3: registry?.cognome3 || null,
        nomeArte: registry?.nomeArte || null,
        telefono: registry?.telefono,
        telefono2: registry?.telefono2 || null,
        email: registry?.email,
        azienda: registry?.azienda,
        indirizzoAzienda: registry?.indirizzoAzienda, 
        regione: registry?.regione?.value || registry?.regione || null,
        categoriaProfessionaleId: registry?.categoriaProfessionaleId.value || registry?.categoriaProfessionaleId || null,
        emailPubblicazione: registry?.emailPubblicazione,
        facebook: registry?.facebook,
        instagram: registry?.instagram,
        linkedin: registry?.linkedin,
        youtube: registry?.youtube,
        tikTok: registry?.tikTok,
        sottocategoriaProfessionale: registry?.sottocategoriaProfessionale,
        idOperatoreCreazione: registry?.idOperatoreCreazione ? registry?.idOperatoreCreazione?.value : null,
        sitoWeb: registry?.sitoWeb,
        telefonoFisso: registry?.telefonoFisso
    }

    return userMap;
}