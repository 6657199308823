

export const LabelEsiti: any = {
    NonRisposto: "Non risposto",
    DaRichiamare: "Da richiamare",
    NonInteressato: "Non interessato",
    AppuntamentoPreso: "Appuntamento preso",    
}

export const LabelInteresse: any = {
    Interessato: "Interessato",
    Forse: "Forse",
    NonSaprei: "Non Saprei",
    NonInteressato: "Non interessato"
}


export function GetEsito(esito: string) {
    return LabelEsiti[esito]
}

export function GetInteresse(esito: string) {
    return LabelInteresse[esito]
}