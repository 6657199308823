import { useForm } from "react-hook-form"
import InputsFields from "../../helpers/InputFields";
import Loader from "../main/Loader";
import { toast } from "react-toastify";
import { useCreateHostRegistryMutation, useUpdateHostRegistryMutation, useCheckTelefoniMutation, useUpdateHostRegistryFromInterviewMutation, useUpdateHostRegistryFromCallMutation } from "../../store/services/HostRegistryService";
import { CreatorOperator, HostRegistryListInputs, IdOperatoreCreazione } from "../../utils/listInputs/HostRegistryListInputs";
import { RegistryAdapterSent, RegistryAdapterValues } from "../../helpers/adapter/RegistryAdapter";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RegioniLookUp from "../../lookup/Regioni";
import CategorieProfesionaliLookUp from "../../lookup/CategorieProf";
import { useEffect, useState } from "react";
import { HostListRoleForm } from "../../utils/genericList/ListRoleRoutes";
import { selectCurrentUserInfo } from "../../store/feautures/AuthSlice";
import { useGetUserRoleQuery } from "../../store/services/LookUpService";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FormAlert } from "../../helpers/FormAlert";

export default function HostRegistryForm({ infoForUpdateRegistry, type, infoGet, catProf, fromComponent }: { infoForUpdateRegistry?: any, type: string, infoGet?: any, catProf: [], fromComponent?: string }) {
    const {role} = useSelector(selectCurrentUserInfo);
    const catProfessionali = CategorieProfesionaliLookUp();
    let mapInfo = RegistryAdapterValues(infoGet, catProf);
    const form = useForm({mode: 'all', defaultValues: mapInfo });
    const { handleSubmit, watch, formState:{isValid, errors} } = form; 
    const navigate = useNavigate();
    const [createHostRegistry, { isLoading: isAdding }] = useCreateHostRegistryMutation();
    const [updateHostRegistry, { isLoading: isUpdating }] = useUpdateHostRegistryMutation();
    const [updateHostRegistryFromInterview, { isLoading: isUpdatingFromInter }] = useUpdateHostRegistryFromInterviewMutation();
    const [updateHostRegistryFromCall, { isLoading: isUpdatingFromCall }] = useUpdateHostRegistryFromCallMutation();
    let actionLabel = type === 'add' ? 'aggiunta' : 'modificata';
    const optionRegioni = RegioniLookUp(false);
    const [checkTelefoni, request] = useCheckTelefoniMutation();
    const telefono = watch('telefono');
    const telefono2 = watch('telefono2');
    const [message, setMessage] = useState('');
    const [message2, setMessage2] = useState('');
    const {pathname} = useLocation();
    let onlyReadInput = pathname === '/dashboard/interviews' && role === 'Montatore';
    let { data, isLoading: loadingUser } = useGetUserRoleQuery(['Consulente', 'Admin', 'SuperAdmin'].includes(role) ? {role: 'Operatore' } : skipToken);
    let optionConsulente = data?.map((user: any) => ({ value: user?.id, label: `${user?.nominativo} (${user?.codiceUtente})` }));

    let { data: dataOP, isLoading: isLoadingOp } = useGetUserRoleQuery({role: 'Operatore'});
    let optionOperatoreCreazione = dataOP?.map((user: any) => ({ value: user?.id, label: `${user?.nominativo} (${user?.codiceUtente})`}));
    
    const CtrlPhone = async (type: string) => {

        if ((type === 'telefono' && telefono?.length >= 8) || (telefono2?.length >= 8 && type === 'telefono2')) {
            // CALL CTRL
            let dataCtrl = {
                "idAnagrafica": infoForUpdateRegistry?.id || null,
                "telefono": telefono,
                "telefono2": telefono2
            }
            let result: any = await checkTelefoni(dataCtrl);
            let message1 = result?.data?.result.telefono?.giaPresente
                ? `Il telefono: ${result?.data?.result.telefono?.telefono} risulta già assegnato a ${result?.data?.result.telefono?.nominativoOspiteAssociato} (${result?.data?.result.telefono.codiceOspiteAssociato})`
                : '';
            let message2 = result?.data?.result.telefono2?.giaPresente
                ? `Il telefono secondario: ${result?.data?.result.telefono2?.telefono} risulta già assegnato a ${result?.data?.result.telefono2?.nominativoOspiteAssociato} (${result?.data?.result.telefono2.codiceOspiteAssociato})`
                : '';
            setMessage(message1);
            setMessage2(message2);
        }
    }

    // useEffect(() => {
    //     const timeOutId = setTimeout(() => CtrlPhone('telefono'), 1000);
    //     return () => clearTimeout(timeOutId);
    // }, [telefono])

    // useEffect(() => {
    //     const timeOutId = setTimeout(() => CtrlPhone('telefono2'), 1000);
    //     return () => clearTimeout(timeOutId);
    // }, [telefono2])

    const handleSubmitUserData = async (data: any) => {
        let result: any;
        let objMap = RegistryAdapterSent(data);

        if (type === 'add') {
            result = await createHostRegistry(objMap);
        }

        if (type === 'update' && infoGet?.canBeManaged) {

            if(fromComponent) {
                if(fromComponent === 'interview') {
                    result = await updateHostRegistryFromInterview({ id: infoForUpdateRegistry?.interviewId, ...objMap })
                }

                if(fromComponent === 'call') {
                    result = await updateHostRegistryFromCall({ id: infoForUpdateRegistry?.callId, ...objMap })
                }
            } else {
                result = await updateHostRegistry({ id: infoForUpdateRegistry?.id, ...objMap })
            }
        }

        if ('error' in result) {
            toast.error(`Anagrafica non ${actionLabel}! Errore: ${result?.error?.data?.detail}`)
        } else {
            toast.success(`Anagrafica ${actionLabel}`)
            if (type === 'add') {
                navigate('/dashboard/host-registry')
            }
        }
    }

    return (
        <>
            {(isUpdatingFromCall || isUpdating || isUpdatingFromInter || loadingUser || isAdding || !optionRegioni || optionRegioni?.lenght === 0 || catProfessionali?.lenght === 0 || isLoadingOp) && <Loader />}
            <form onSubmit={handleSubmit(handleSubmitUserData)} className="my-6">
            {(type === 'add' || (infoGet?.canBeManaged && type === 'update') && role !== 'Montatore')
                    ? <div className={`flex ${type === 'update' ? 'justify-between' : 'justify-end'} items-end px-8 mb-4`}>
                        {type === 'update' && <p className="font-semibold">Id: {infoForUpdateRegistry?.id}</p>}
                        <button className={`${(type === 'add' 
                        || infoForUpdateRegistry?.editabile 
                        || infoGet?.editabile ||
                        ['Admin', 'SuperAdmin', 'Supervisore', 'Consulente'].includes(role)
                        ) ? '' : 'opacity-50 cursor-not-allowed'} h-10 bg-[#064e3b] text-white px-5 w-64 rounded-lg text-md`} 
                        disabled={
                            (infoForUpdateRegistry?.editabile === false || infoGet?.editabile === false) && 
                            type === 'update' && !['Admin', 'SuperAdmin', 'Supervisore', 'Consulente'].includes(role)
                        }
                        onClick={() => FormAlert(isValid && Object.keys(errors).length == 0 ? 'noShow' : 'show', errors)}
                        >Salva
                        </button>
                    </div>
                    :null
                }
                
                <div className="grid sm:grid-cols-2 grid-rows-none gap-10 p-8">
                    
                    {/* Creator Operator only form Admin SuperAdmin/Supervisor */}
                    {type === 'update'
                       ? <InputsFields arrFields={CreatorOperator(optionOperatoreCreazione)} form={form} isDisabled={!['Admin', 'SuperAdmin', 'Supervisore'].includes(role) || onlyReadInput} />
                       : null 
                    }
                    
                    <InputsFields arrFields={HostRegistryListInputs(optionRegioni, catProfessionali, type, role)} form={form} isDisabled={!HostListRoleForm.includes(role) || onlyReadInput} />

                    {['Consulente', 'Admin', 'SuperAdmin'].includes(role) && type === 'add'
                        ?
                        <>
                            <h3 className="text-orange-500 col-span-2">Operatore creazione</h3>
                            <InputsFields arrFields={IdOperatoreCreazione(optionConsulente)} form={form} isDisabled={!HostListRoleForm.includes(role) || onlyReadInput} />
                        </>
                    : null}
                </div>

                {message || message2
                    ? <div className="bg-red-100 border-t border-b border-red-500 text-red-700 px-4 py-3 m-8" role="alert">
                        {message ? <p className="text-sm">{message}</p> : null}
                        {message2 ? <p className="text-sm">{message2}</p> : null}
                        <p className="text-base">Sicuro di procedere al salvataggio?</p>
                    </div>
                    : null
                }

                {(type === 'add' || (infoGet?.canBeManaged && type === 'update') && role !== 'Montatore')
                    ? <div className="flex justify-end px-8">
                        <button className={`${(type === 'add' 
                        || infoForUpdateRegistry?.editabile 
                        || infoGet?.editabile
                        || ['Admin', 'SuperAdmin', 'Consulente'].includes(role)
                        ) ? '' 
                        : 'opacity-50 cursor-not-allowed'} h-10 bg-[#064e3b] text-white px-5 w-64 rounded-lg text-md`} 
                        disabled={
                            (infoForUpdateRegistry?.editabile === false || infoGet?.editabile === false) && 
                            type === 'update' && 
                            role !== 'Admin' &&
                            role !== 'SuperAdmin'
                            && role !== 'Consulente'
                        }
                        onClick={() => FormAlert(isValid && Object.keys(errors).length == 0 ? 'noShow' : 'show', errors)}
                        >Salva
                        </button>
                    </div>
                    :null
                }
            </form>
        </>

    )
}