import { PencilSquareIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import DialogComponent from "../../main/DIalogComponent";
import ProductForm from "../../form/ProductForm";
import DeleteProduct from "./DeleteProducts";


export default function ActionProduct({ row, type }: any) {

    const action: any = {
        add: {
            icon: <div className="w-auto text-gray-400 px-5 border-none flex items-center justify-center">
                <PlusIcon className={'w-5 h-5 mr-2'} /> <span>Aggiunti prodotto</span>
            </div>,
            content: <ProductForm type={'add'} />
        },
        update: {
            icon: <PencilSquareIcon className={'w-5 h-5'} />,
            content: <ProductForm type={'update'} row={row?.original} />
        },
        delete: {
            icon: <TrashIcon className={'w-5 h-5'}/>,
            content: <DeleteProduct row={row?.original} />
        }
    }

    return (
        <DialogComponent
            icon={action[type].icon}
            label={'Prodotto'}
            content={action[type].content}
        />
    )
}