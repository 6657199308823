import { useGetInteresseVideoQuery } from "../store/services/LookUpService"

export default function InteresseVideoLookUp() {
    const {data} = useGetInteresseVideoQuery('');

    const replaceInteresseVideo = (interesse: string) => {
        let split =  interesse.split(/(?=[A-Z])/);
        return `${split[0]} ${split[1] ? split[1]?.toLowerCase() : ''}`
    }

    let optionInteresseVideo = data?.map((interesse: string) => ({value: interesse, label: replaceInteresseVideo(interesse)}))
    return optionInteresseVideo;
}