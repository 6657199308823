import { useGetRegioniQuery } from "../store/services/LookUpService"

export default function RegioniLookUp(isAll: boolean) {
    const { data } = useGetRegioniQuery('');

    const replaceEsito = (esito: string) => {
        let split = esito.split(/(?=[A-Z])/);
        return `${split[0]} ${split[1] ? split[1]?.toLowerCase() : ''}`
    }

    if (isAll) {
        let All = data?.map((esito: string) => (
            { value: esito, label: replaceEsito(esito) }
        ))

        return All
    } else {

        let optionEsito = data?.map((esito: string) => (
            { value: esito, label: replaceEsito(esito) }
        )).filter((esito: { value: string, label: string }) => {
            return esito?.value !== 'Tutte';
        });

        return optionEsito
    }

}