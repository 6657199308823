import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    id: '',
    interviewId: ''
}

const CurrentInterviewSlice = createSlice({
    name: 'setCurrentInterview',
    initialState: initialState,
    reducers: {
        setCurrentInterview: (state, {payload}) => {
            return state =  {...payload};
        }
    }
})

export const { setCurrentInterview } = CurrentInterviewSlice.actions
export default CurrentInterviewSlice.reducer

export const CurrentInterview = (state: any) => state.CurrentInterviewSlice