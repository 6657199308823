import { useDispatch, useSelector } from "react-redux"
import { logOut, selectCurrentUserInfo } from "../../store/feautures/AuthSlice"
import UserRounded from "./UserRounded";
import { LabelAccountRole } from "../../utils/listInputs/ListRoleAccount";
import { PowerIcon } from "@heroicons/react/24/outline";
import BreadCrumbs from "./Breadcrumbs";

export default function Toolbar({isOpen}: any) {

    const {name, lastname, role}: any = useSelector(selectCurrentUserInfo);
    const dispatch = useDispatch();
    const package_json = require('../../../package.json');

    return(
        <div className={`fixed top-0 ${isOpen ? 'left-56' : 'left-20'} right-0 px-10 flex shadow-md justify-between items-center h-16 border-b border-slate-200 bg-[#f1f5f9] z-30`}>
            {/* <BreadCrumbs /> */}
            <div className="w-32 text-gray-500 text-sm">v. {package_json.version}</div>
            <div className="flex space-x-4 items-center">
                <div className="text-right">
                    <p>{name} {lastname}</p>
                    <p className="text-xs">{LabelAccountRole[role]}</p>
                </div>
                <UserRounded name={name} lastname={lastname} />
                <button onClick={() => dispatch(logOut()) }>
                    <PowerIcon className="w-6 h-6" />
                </button>
            </div>
        </div>
    )
}