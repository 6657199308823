import LoginForm from "../../components/form/LoginForm";
import logo from "../../assets/logo.png"
import { ToastContainer } from "react-toastify";

export default function LoginPage() {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100 py-5 lg:py-24">
            <div className="px-8 py-6 mt-4 sm:w-2/5 w-11/12 mx-auto bg-white shadow-lg rounded-lg">
                <div className="flex justify-center">
                    <img src={logo} alt="logo" className="h-20 w-44" />
                </div>
                <LoginForm />
            </div>

            {/* MESSAGE ACTION */}
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}