import { useState } from "react";
import { toast } from "react-toastify";
import ReportConsulenteFilters from "../../components/filters/ReportConsulenteFilters";
import Loader from "../../components/main/Loader";
import { AdapterReportFilterCon } from "../../helpers/adapter/filters/AdapterFilterReportOp";
import { initialParams, IPagination } from "../../helpers/PaginationHelper";
import { useGetReportConsulenteMutation } from "../../store/services/ReportService";
import { saveAs } from 'file-saver';
import { useGetUserRoleQuery } from "../../store/services/LookUpService";

export default function ReportConsulente() {
    const [queryParams, setQueryParams] = useState<IPagination>(initialParams);
    const [filterParams, setFilterParams] = useState<any>(null);
    let { data: consulenteData, isLoading: isLoadingOp } = useGetUserRoleQuery({role: 'Consulente' });

    const [getReportConsulente, requestReport] = useGetReportConsulenteMutation()


    const handleSetFilter = async (dataFilter: any) => {
        // const mapFilter = AdapterReportFilterCon(dataFilter)
        const mapFilter: any = AdapterReportFilterCon(dataFilter);
        setFilterParams(mapFilter);
        setQueryParams(initialParams);
        let result: any = await getReportConsulente(mapFilter);

        if ('error' in result) {
            console.log('ERROR DOWNLOAD')
        } else {
            saveAs(result?.data, `StatisticheConsulenti_${Date.now()}.xlsx`)
            toast.success(`Report scaricato`);
        }
    }

    return(
        <>
            {requestReport?.isLoading || isLoadingOp ? <Loader /> : null}
            <ReportConsulenteFilters methodParent={handleSetFilter} consulenteData={consulenteData} />
            {/* {isSuccess && data?.length > 0
                ? <TableComponent columns={HeaderRepCon} data={data} isDisabledPagination={true}  /> 
                : <p className="p-6 text-center">NESSUN REPORT PRESENTE</p>
            } */}
                {requestReport?.isError 
                ? 
                <div className="mt-8 bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                    <p className="font-bold">Attenzione</p>
                    <p>Nessun risultato presente per i parametri selezionati</p>
                </div>
                : null
            }
        </>
    )
}