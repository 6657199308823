import { QueryStringHelper } from "../../helpers/QueryHelper";
import { emptySplitApi } from "./InjectionEndpoint"

const lookUpService = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getSede: build.query({
            query: () => `/api/lookup/sedeintervista`,
        }),
        getEsito: build.query({
            query: () => `/api/lookup/esito`,
        }),
        getStatiIntervista: build.query({
            query: () => `/api/lookup/statointervista`,
        }),
        getRegioni: build.query({
            query: () => `/api/lookup/region`,
        }),

        getCategorieProfessionali: build.query({
            query: () => `/api/lookup/categoriaprofessionale`,
        }),

        getProdotti: build.query({
            query: () => `/api/lookup/prodotto`,
        }),

        getEsitoConsulenza: build.query({
            query: () => `/api/lookup/esiticonsulenza`,
        }),

        getInteresseVideo: build.query({
            query: () => `/api/lookup/interessevideo`,
        }),

        getStateVideo: build.query({
            query: () => `/api/lookup/statovideo`,
        }),

        getUserRole: build.query({
            query: (query) => `api/lookup/account?${QueryStringHelper(query)}`,
        }),

        getIva: build.query({
            query: (query) => `api/lookup/tipologiaiva`,
        }),

        getStatePayment: build.query({
            query: (query) => `api/lookup/statopagamento`,
        }),

    }),
    overrideExisting: false,
})

export const {
    useGetSedeQuery,
    useGetEsitoQuery,
    useGetStatiIntervistaQuery,
    useGetRegioniQuery,
    useGetCategorieProfessionaliQuery,
    useGetProdottiQuery,
    useGetEsitoConsulenzaQuery,
    useGetInteresseVideoQuery,
    useGetUserRoleQuery,
    useGetStateVideoQuery,
    useGetIvaQuery,
    useGetStatePaymentQuery
} = lookUpService;