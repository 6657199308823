import ActionProduct from "../../components/action/products/ActionProducts";

export const HeaderProducts = [
    {
        Header: "Nome prodotto",
        accessor: "nome",
        disableSortBy: true,
        Filter: false,
        customSort: false
    },
    {
        Header: "Prezzo",
        accessor: "prezzoConsigliato",
        disableSortBy: true,
        Filter: false,
        customSort: false,
    },
    {
        Header: "",
        accessor: "action",
        disableSortBy: true,
        Filter: false,
        customSort: false,
        Cell: ({ row }: any) => (
            <div className="flex space-x-6 justify-end px-5">
                <ActionProduct row={row} type="update" />
                <ActionProduct row={row} type="delete" />
            </div>
        )
    }
]