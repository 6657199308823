import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { logOut, setCredentials } from "../feautures/AuthSlice"
import jwt_decode from "jwt-decode";

const baseQuery = fetchBaseQuery({
    // baseUrl: 'http://storytime-001-site3.btempurl.com/', //TEST
    // baseUrl: 'https://api.storytime-backoffice.com/', //PROD
    // baseUrl: 'http://api-t.storytime-backoffice.com/', // TEST NEW
    baseUrl: 'https://api.storytime-backoffice.com/', // PROD NEW
    credentials: 'include',
    prepareHeaders: (headers, { getState }: any) => {
        const token = getState().AuthSlice?.token;
        if (token) {
            headers.set("authorization", `Bearer ${token}`)
        }
        return headers
    }
})

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
    let result: any = await baseQuery(args, api, extraOptions)

    if (result?.error?.status === 401 && api?.endpoint !== 'login') {
        // send refresh token to get new access token 
        const refreshResult: any = await baseQuery({ 
            url: '/api/auth/refresh', 
            method: 'POST',
            body: {accessToken: api.getState().AuthSlice.token, refreshToken: api.getState().AuthSlice.refreshToken}
        }, api, extraOptions)

        if (refreshResult?.data) {
            // store the new token 
            let jwtDecode: any = jwt_decode(refreshResult?.data?.accessToken);
            let name = jwtDecode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];
            let lastname = jwtDecode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'];
            let role = jwtDecode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];            
            api.dispatch(setCredentials({token: refreshResult?.data?.accessToken, refreshToken: refreshResult?.data?.refreshToken, name: name, lastname: lastname, role: role}))

            // retry the original query with new access token 
            result = await baseQuery(args, api, extraOptions)
        } else {
            api.dispatch(logOut())
        }
    }

    return result
}


export const emptySplitApi = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({}),
    tagTypes: ['users', 'registry', 'calls', 'interviews', 'lead', 'products'],
    keepUnusedDataFor: 0
})