import { DocumentCheckIcon, TrashIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { useFieldArray } from "react-hook-form";
import InputsFields from "../../helpers/InputFields";
import { IntervieCons, SuperAndAdmin } from "../../utils/genericList/ListRoleRoutes";
import { PaymentListInput } from "../../utils/listInputs/InterviewListInputFormUpdate";
import ActionSendEnvoice from "../action/ActionSendEnvoice";
import { toast } from "react-toastify";
import ActionDeleteProd from "../action/ActionDeleteProd";
import { useEffect, useState } from "react";

export default function FormArrayPayment({ role, form, interviewId, payments }: any) {

    const { control, watch } = form;
    let prodotti = watch('prodotti');
    let payment = watch('pagamenti');
    const [isDialog, setIsDialog] = useState<boolean>(false);
    const [selIndex, setSelectIndex] = useState<number | null>(null);

    const EmpytyPayment: any = {
        metodo: '',
        importo: '',
        data: ''
    }

    const {
        fields,
        append,
        remove
    } = useFieldArray({
        control,
        name: 'pagamenti'
    });

    const handleAdd = () => {

        EmpytyPayment.data = `${moment().format('DD-MM-YYYY')}`;

        const sumAllProd = parseFloat(prodotti.reduce((total: any, item: any) => {
            const prezzoValue = typeof item.prezzo === 'number' ? item.prezzo : parseFloat(item.prezzo.replace(',', '.'));
            return total + prezzoValue;
        }, 0)?.toFixed(2));

        const sumAllPay = parseFloat(payment.reduce((total: any, item: any) => {
            const prezzoValue = typeof item.importo === 'number' ? item.importo : parseFloat(item.importo?.replace(',', '.'));
            return total + prezzoValue;
        }, 0)?.toFixed(2));
        
        EmpytyPayment.importo = Number((sumAllProd - sumAllPay).toFixed(2));
        (sumAllProd - sumAllPay) > 0 ? append(EmpytyPayment) : toast.error("Impossibile aggiungere pagamento l'importo rimanente è uguale a 0")
    }

    const handleRemove = (index: number) => {
        if (payment[index]?.fatturaInviata) {
            setSelectIndex(index);
            setIsDialog(true);
        } else {
            remove(index)
        }
    }

    return (
        <>
            <div className={`flex items-center justify-between pt-6 ${fields.length === 0 ? 'pb-6' : ''}`}>
                <p className='text-[#064E3B]'>Pagamenti</p>
                {IntervieCons.includes(role) ? <button type='button' className="text-gray-400 text-md" onClick={() => handleAdd()}>
                    Aggiungi pagamento
                </button> : null}
            </div>

            {
                fields.map(({ id, fatturaInviata }: any, index: number) => (
                    <fieldset name={`pagamenti[${index}]`} key={id} className={`text-[#064E3B] border rounded-lg my-2 relative`}>
                        <legend className="mx-5">{`Pagamento ${index + 1}`}</legend>

                        <div className="grid grid-cols-3 gap-x-6 gap-y-6 p-5 pr-10 relative">
                            <InputsFields arrFields={PaymentListInput(`pagamenti[${index}]`)} form={form} isDisabled={!IntervieCons.includes(role) || fatturaInviata} />

                            <div>
                                {SuperAndAdmin?.includes(role) && payment[index]?.id && <>
                                    {!fatturaInviata
                                        ? <ActionSendEnvoice payment={payment} interviewId={interviewId} id={index} namePayment={`Pagamento ${index + 1}`} form={form} idForm={id} />
                                        : <div className="flex space-x-2 items-center">
                                            <DocumentCheckIcon className="w-6 h-6" />
                                            <span>Fattura inviata</span>
                                        </div>
                                    }
                                </>}

                                {(IntervieCons.includes(role) && !fatturaInviata) || role === 'SuperAdmin' ? <button type="button" className="pr-5 w-5 absolute top-7 right-2"  onClick={() => handleRemove(index)}>
                                    <TrashIcon className="w-5 h-5" />
                                </button> : null}
                            </div>
                        </div>
                        {isDialog && <ActionDeleteProd closeView={setIsDialog} index={selIndex} onRemove={remove} />}
                    </fieldset>
                ))
            }
        </>
    );
}