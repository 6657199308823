
export const setStateListInput = [
    {
        label: 'Esito',
        name: 'esito',
        placeholder: 'Esito',
        type: 'select',
        validators: {
            required: 'Il campo è obbligatorio'
        },
        type_input: 'react-select',
        size_input: 'w-full order-2',
    }
]


export const speakerListInput = [
    {
        label: 'Speaker',
        name: 'speakerId',
        placeholder: 'Speaker',
        type: 'select',
        validators: {
            required: 'Il campo è obbligatorio'
        },
        type_input: 'react-select',
        size_input: 'w-full order-2',
    }
]