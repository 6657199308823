import { emptySplitApi } from "./InjectionEndpoint"

const leadService = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        
        // getStatusLead: build.mutation({
        //     query() {
        //         return {
        //             url: `/api/Reports/lead/status`,
        //             method: "GET",
        //             cache: "no-cache",
        //         };
        //     },
        // }),

        getStatusLead: build.query({
            query: () => `/api/Reports/lead/status`,
        }),


        uploadLead: build.mutation({
            query(params: any) {
                return {
                    url: `/api/Reports/lead`,
                    method: "POST",
                    body: params,
                    cache: "no-cache",
                };
            },
            invalidatesTags:['lead']
        }),

        downloadLead: build.mutation({
            query() {
                return {
                    url: `api/Reports/lead/xlsx`,
                    method: "GET",
                    responseHandler: (response: any) => response.blob(),
                    cache: "no-cache",
                };
            },
            invalidatesTags:['lead']
        }),

    }),
})

export const {
    // useGetStatusLeadMutation,
    useGetStatusLeadQuery,
    useDownloadLeadMutation,
    useUploadLeadMutation
} = leadService;