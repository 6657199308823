import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectCurrentToken } from "../../store/feautures/AuthSlice";


export default function PrivateGuard({children} : any) {
    const isAuth = useSelector(selectCurrentToken);
    return isAuth 
        ? <>{children}</> 
        : <Navigate to={"/"} />;
 }
 