import { useDisableAccountMutation, useEnableAccountMutation } from "../../store/services/UsersService";
import Loader from "../main/Loader";
import { toast } from "react-toastify"


export default function ManageStateAccount({row, typeAction}: any) {

    let typeLabel = typeAction === 'disable' ? 'disabilitare' : 'attivare';
    let resultLabel = typeAction === 'disable' ? 'disabilitato' : 'attivato';
    const [disableAccount, {isLoading: isLoadingDisable}] = useDisableAccountMutation();
    const [enableAccount, {isLoading : isLoadingEnable}] = useEnableAccountMutation();

    const handleActivated = async () => {
        let result: any;

        if (typeAction === 'disable') {
            result = await disableAccount(row?.id)
        }

        if (typeAction === 'enable') {
            result = await enableAccount(row?.id)
        }

        if ('error' in result) {
            toast.error(`Utente non ${resultLabel}! Errore: ${result?.error?.data?.detail}`)
        } else {
            toast.success(`Utente ${resultLabel}! `)
        }
    }

    return(
        <div className="relative px-5">
            {(isLoadingEnable || isLoadingDisable) && <Loader />}
            <p className="text-center py-5">Sei sicuro di voler {typeLabel} l'utente {row?.nome} {row?.cognome} ?</p>
            <div className="flex items-center justify-center">
                <button onClick={() => handleActivated()} className="h-10 bg-indigo-500 text-white px-5 my-6 rounded-lg">Conferma</button>
            </div>
        </div>
    )
}