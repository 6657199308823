import { useGetStateVideoQuery, useGetStatiIntervistaQuery } from "../store/services/LookUpService"

export default function StatoVideoLookup() {
    const {data} = useGetStateVideoQuery('');

    const replaceStato = (esito: string) => {
        let split =  esito.split(/(?=[A-Z])/);
        return `${split[0]} ${split[1] ? split[1]?.toLowerCase() : ''} ${split[2] ? split[2]?.toLowerCase() : ''}`
    }

    let optionStato = data?.map((stato: string) => ({value: stato, label: replaceStato(stato)}))
    return optionStato;
}