    
export const onlySuperAdmin = ['SuperAdmin']
export const AllRole = ['SuperAdmin', 'Admin', 'Operatore', 'Supervisore', 'Consulente', 'Speaker', 'Montatore']
export const AllRoleNoOperatore = ['SuperAdmin', 'Admin', 'Supervisore', 'Consulente', 'Speaker', 'Montatore'];
export const ListInterviewRole = ['SuperAdmin', 'Admin', 'Consulente', 'Speaker', 'Montatore'];
export const SuperAndAdmin = ['SuperAdmin', 'Admin']
export const SuperAndAdminConsulente = ['SuperAdmin', 'Admin',  "Consulente"];
export const SuperAdminVisore= ['SuperAdmin', 'Admin', 'Supervisore'];
export const SuperAdminVisoreOpe= ['SuperAdmin', 'Admin', 'Supervisore', 'Operatore'];
export const SuperAdminVisoreOpeCons= ['SuperAdmin', 'Admin', 'Supervisore', 'Operatore', 'Consulente'];


export const InterviewMont = ['SuperAdmin', 'Admin', 'Montatore'];
export const InterviewSpeack = ['SuperAdmin', 'Admin', 'Speaker'];
export const IntervieAdmin = ['SuperAdmin', 'Admin']
export const IntervieCons= ['SuperAdmin', 'Admin', 'Consulente'];
export const IntervieSpeak= ['SuperAdmin', 'Admin', 'Consulente', 'Speaker'];

export const HostListRoleForm = ['SuperAdmin', 'Admin', 'Operatore', 'Supervisore', 'Consulente', 'Speaker'];