import { useNavigate } from "react-router-dom";
import HostRegistryForm from "../../components/form/HostRegistryForm";
import CategorieProfesionaliLookUp from "../../lookup/CategorieProf";

export default function HostRegistryNewPage() {
    const catProfessionali = CategorieProfesionaliLookUp();
    const navigate = useNavigate();

    return(
        <>

            <div className="flex justify-between items-center">
                <p className="mb-8">Aggiungi anagrafica</p>
                <p className="mb-8 text-[#9ca3af] cursor-pointer" onClick={() => navigate('/dashboard/host-registry')}>Torna alla lista</p>
            </div>

            <div className="bg-white shadow-lg rounded-lg py-5">
                <HostRegistryForm type={'add'} catProf={catProfessionali} />
            </div>
        </>
    )
}