import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { selectCurrentUserInfo } from "../../store/feautures/AuthSlice";
import UpdateHostRegistry from "../dialogContent/UpdateHostRegistry";
import UsersForm from "../form/usersForm";
import DialogComponent from "../main/DIalogComponent";
import { SuperAdminVisore } from "../../utils/genericList/ListRoleRoutes";


export default function ActionUpdate({row, contentType, label}: any) {

    const {role} = useSelector(selectCurrentUserInfo);

    let objContent: any = {
        user: <UsersForm infoForUpdate={row} type={'update'} />,
        hostRegistry: <UpdateHostRegistry row={row} />
    }

    return (
        SuperAdminVisore.includes(role)
        ? (role === 'Admin' && row?.ruolo !== 'SuperAdmin') || role === 'SuperAdmin' || role === 'Supervisore'
            ? <DialogComponent 
                icon={<PencilSquareIcon className="w-6 h-6" />} 
                label={label} 
                content={objContent[contentType]}
            />
            : <></>
        : <></>
    )
}