export function QueryStringHelper(paramsQuery: any) {

    let objMap = {...paramsQuery};

    Object.keys(objMap).forEach(key => {
        if (objMap[key] === null || objMap[key] === undefined || !objMap[key] || key === 'sortDirection' || key === 'sortIdCol') {
          delete objMap[key];
        }
    });

    let queryString = new URLSearchParams(objMap).toString();

    return queryString;
}



// EXAMPLE: 
//     getListMerchants: build.query({
//       // query: (params: any) => `merchant-search/merchants?page=${params?.queryParams?.page}&pageSize=${params?.queryParams.pageSize}&sort=${params?.queryParams?.sort}${params?.filterParams?.name && `&name=${params?.filterParams?.name}`}`,
//       query: (params: any) => `merchant-search/merchants?${QueryStringHelper(params)}`,
//       providesTags: ['merchants'],
//     }),