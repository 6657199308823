
export const HostRegistryFiltersList = (optionRegioni: any[]) => ([
    {
        label: 'Nominativo o Azienda',
        name: 'Nominativo',
        placeholder: 'Nominativo o Azienda',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Email o Social',
        name: 'Email',
        placeholder: 'Email o Social',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Telefono',
        name: 'Telefono',
        placeholder: 'Telefono',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Regione',
        name: 'Regione',
        placeholder: 'Regione',
        type: 'text',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        options: optionRegioni
    },
    {
        label: 'Codice ospite',
        name: 'CodiceOspite',
        placeholder: 'Codice ospite',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Data creazione da',
        name: 'DataCreazioneDa',
        placeholder: 'Data creazione da',
        type: 'text',
        validators: {},
        type_input: 'picker',
        size_input: 'w-full',
        isLimitDate: false,
        isTimeShow: false
    },
    {
        label: 'Data creazione a',
        name: 'DataCreazioneA',
        placeholder: 'Data creazione a',
        type: 'text',
        validators: {},
        type_input: 'picker',
        size_input: 'w-full',
        isLimitDate: false,
        isTimeShow: false
    }
]);

export const RolesFilterRegistry = (optionNominativo: any[], optionGestore: any[]) => ([
    {
        label: 'Nominativo o cod. creatore',
        name: 'NominativoOCodiceOperatoreCreazioneId',
        placeholder: 'Nominativo operatore',
        type: 'text',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        options: optionNominativo
    },
    {
        label: 'Nominativo o cod. gestore',
        name: 'NominativoOCodiceOperatoreGestioneId',
        placeholder: 'Nominativo gestore',
        type: 'text',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        options: optionGestore
    }
]);

export const CheckRegistry = [
    {
        label: 'Includi anagrafiche vecchio gestionale',
        name: 'includeImportData',
        placeholder: 'Includi anagrafiche vecchio gestionale',
        type: 'checkbox',
        validators: {},
        type_input: 'checkbox',
        size_input: 'w-full',
    }
];

export const CheckOperator = [
    {
        label: 'Cerca anche tra le anagrafiche di altri operatori',
        name: 'globalSearch',
        placeholder: 'Cerca anche tra le anagrafiche di altri operatori',
        type: 'checkbox',
        validators: {},
        type_input: 'checkbox',
        size_input: 'w-full',
    }
]