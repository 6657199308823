import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    isOpen: true,
}

const DialogActionSlice = createSlice({
    name: 'setOpenDialogAction',
    initialState: initialState,
    reducers: {
        setIsOpenDialogAction: (state, {payload}) => {
            return state =  {...payload};
        }
    }
})

export const { setIsOpenDialogAction } = DialogActionSlice.actions
export default DialogActionSlice.reducer

export const isOpenDialogAction = (state: any) => state.DialogActionSlice.isOpen