import { IntervieAdmin, IntervieCons } from "../genericList/ListRoleRoutes";

export const InterviewListInputFormUpdate = (optionEsito: any[], role: string) => ([
    {
        label: 'Data consulenza',
        name: 'dataConsulenza',
        placeholder: 'Data consulenza',
        type: 'text',
        validators: {},
        type_input: 'picker',
        size_input: 'w-full',
        isTimeShow: true,
        isDisabled: !['SuperAdmin', 'Admin'].includes(role)
    },
    {
        label: 'Esito consulenza',
        name: 'esitoConsulenza',
        placeholder: 'Esito consulenza',
        type: 'select',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        options: optionEsito
    },
    {
        label: 'Data intervista',
        name: 'dataIntervista',
        placeholder: 'Data intervista',
        type: 'text',
        validators: {},
        type_input: 'picker',
        size_input: 'w-full',
        isTimeShow: true,
        isDisabled: !['SuperAdmin', 'Admin'].includes(role)
    },
])

export const ConsulentiListOp = (optionCons: any[]) => ([
    {
        label: 'Consulente',
        name: 'consulenteId',
        placeholder: 'Consulente',
        type: 'select',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        options: optionCons
    }
])

export const SpeackListOp = (optionSpeaker: any[]) => ([
    {
        label: 'Speaker',
        name: 'speakerId',
        placeholder: 'Speaker',
        type: 'select',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        options: optionSpeaker
    }
])

export const NotePre = [
    {
        label: 'Note pre intervista',
        name: 'notePreIntervista',
        placeholder: 'Note pre intervista',
        type: 'text',
        validators: {},
        type_input: 'text-area',
        size_input: 'w-full col-span-2',
    }
]

export const InfoAddresses = [
    {
        label: 'Sede',
        name: 'sede',
        placeholder: 'Sede',
        type: 'text',
        validators: {},
        type_input: 'select',
        size_input: 'w-full',
    },
    {
        label: 'Ragione sociale',
        name: 'ragioneSociale',
        placeholder: 'Ragione sociale',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Cod. Fiscale/P.IVA',
        name: 'codFiscPiva',
        placeholder: 'Cod. Fiscale/P.IVA',
        type: 'text',
        validators: {
            // pattern: {
            //     value: PatternHelper.cf || PatternHelper.piva,
            //     message: "P.IVA o CF non valido"
            // }
        },
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'PEC/Cod sdi',
        name: 'pecCodSdi',
        placeholder: 'PEC/Cod sdi',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    }
];

export const Nazione = (Nazione: any[]) => [
    {
        label: 'Nazione',
        name: 'nazione',
        placeholder: 'Nazione',
        type: 'text',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        options: Nazione
    }
];

export const Provincia = (optionProvincia: any[]) => [
    {
        label: 'Provincia',
        name: 'provincia',
        placeholder: 'Provincia',
        type: 'text',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        options: optionProvincia
    }
];

export const CittaInput = (optionCitta: any[]) => [
    {
        label: 'Città',
        name: 'citta',
        placeholder: 'Città',
        type: 'text',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        options: optionCitta
    }
]

export const capInput = [
    {
        label: 'CAP',
        name: 'cap',
        placeholder: 'CAP',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    }
];

export const Address = [
    {
        label: 'Indirizzo',
        name: 'indirizzo',
        placeholder: 'Indirizzo',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    }
]

export const Iva = [
    {
        label: 'Iva',
        name: 'tipologiaIvaId',
        placeholder: 'Iva',
        type: 'text',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
    }
]

export const Upa = [
    {
        label: 'Codice UPA',
        name: 'codUpa',
        placeholder: 'Codice UPA',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    }
]

export const InputNoteSpeack = [
    {
        label: 'Note speaker',
        name: 'noteSpeaker',
        placeholder: 'Note speaker',
        type: 'text',
        validators: {},
        type_input: 'text-area',
        size_input: 'w-full col-span-2',
    }
]

// NUOVA SEZIONE

export const ProductsInput = (fieldName: any, optionProduct: any[], optionMontatore: any[], role: string, indexMontatore: number, montatore: any) => {
    let montDisabled = Number(indexMontatore) !== montatore;
    return [
    {
        label: 'Prodotto',
        name: `${fieldName}.prodotto`,
        placeholder: 'Prodotto',
        type: 'select',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        options: optionProduct,
        isDisabled: !IntervieCons.includes(role)
    },
    {
        label: 'Prezzo',
        name: `${fieldName}.prezzo`,
        placeholder: 'Prezzo',
        type: 'number',
        validators: {required: 'Il campo è obbligatorio'},
        type_input: 'input',
        size_input: 'w-1/2 col-span-2 pr-3',
        isDisabled: !IntervieCons.includes(role)
    },
    {
        label: 'Montatore',
        name: `${fieldName}.montatore`,
        placeholder: 'Montatore',
        type: 'text',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        options: optionMontatore,
        isDisabled: !IntervieAdmin.includes(role)
    },
    {
        label: 'Data materiale ricevuto',
        name: `${fieldName}.dataMaterialiRicevuti`,
        placeholder: 'Data materiale ricevuto',
        type: 'text',
        validators: {},
        type_input: 'picker',
        size_input: 'w-full',
        isDisabled: !IntervieAdmin.includes(role)
    },
    {
        label: 'Data consegna video',
        name: `${fieldName}.dataConsegnaVideo`,
        placeholder: 'Data consegna video',
        type: 'select',
        validators: {},
        type_input: 'picker',
        size_input: 'w-full',
        isDisabled: !IntervieAdmin.includes(role) && montDisabled && role !== 'Consulnte'
    },
    {
        label: 'Note montatore',
        name: `${fieldName}.noteMontatore`,
        placeholder: 'Note montatore',
        type: 'text',
        validators: {},
        type_input: 'text-area',
        size_input: 'col-span-3 w-full',
        textAreaHeight: true,
        isDisabled: !IntervieAdmin.includes(role) && montDisabled && role !== 'Consulnte'
    }
]};

export const ProductAddInputs = [
    {
        label: 'Prodotto da creare',
        name: 'prodottoDaAggiungereNome',
        placeholder: 'Prodotto da creare',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Prodotto da creare prezzo',
        name: 'prodottoDaAggiungerePrezzo',
        placeholder: 'Prodotto da creare prezzo',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    }
]

export const MethodList = [
    { value: 'Bonifico', label: 'Bonifico' },
    { value: 'Carta', label: 'Carta o bancomat' },
    { value: 'Contanti', label: 'Contanti' }
]

export const PaymentListInput = (fieldName: any) => ([
    {
        label: 'Metodo pagamento',
        name: `${fieldName}.metodo`,
        placeholder: 'Metodo pagamento',
        type: 'text',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        options: MethodList
    },
    {
        label: 'Importo',
        name: `${fieldName}.importo`,
        placeholder: 'number',
        type: 'number',
        validators: {required: 'Il campo è obbligatorio'},
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Data pagamento',
        name: `${fieldName}.data`,
        placeholder: 'Data pagamento',
        type: 'select',
        validators: {},
        type_input: 'picker',
        size_input: 'w-full',
    }
]);