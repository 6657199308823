import { ListRoleSuperAdmin } from "./ListRoleAccount";

export const FilterUsersInputs = (optionRegioni: any[], listSuperVi: any[]) => [
    {
        label: 'Nome',
        name: 'Nome',
        placeholder: 'Nome',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full'
    },
    {
        label: 'Cognome',
        name: 'Cognome',
        placeholder: 'Cognome',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full'
    },
    {
        label: 'Codice utente',
        name: 'CodiceUtente',
        placeholder: 'Codice utente',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full'
    },
    {
        label: 'Regione',
        name: 'Regione',
        placeholder: 'Regione',
        type: 'select',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        options: optionRegioni
    },
    {
        label: 'Email',
        name: 'Email',
        placeholder: 'Email',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full',
    },
    {
        label: 'Ruolo',
        name: 'Ruolo',
        placeholder: 'Ruolo',
        type: 'text',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        options: [{value: '', label: 'Qualsiasi'}].concat(ListRoleSuperAdmin)
    },
    {
        label: 'Supervisore',
        name: 'SupervisoreId',
        placeholder: 'Supervisore',
        type: 'text',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        options: listSuperVi
    }
]