import { ChevronDownIcon, ChevronUpIcon, FunnelIcon } from "@heroicons/react/24/outline";
import InputsFields from "../../helpers/InputFields";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { InputsListProductsFilter } from "../../utils/listInputs/ProductsListFilter";

export default function ProductsFilters({ methodParent }: any) {

    const form = useForm();
    const [isOpenFilter, setIsOpenFilter] = useState<boolean>(true);
    const { handleSubmit, reset } = form;

    const InitialValueFilterProducts = {
        nome: ''
    }

    return (
        <div className="bg-white shadow-lg rounded-lg">
            <div className="flex justify-between items-center p-4" >
                <p>Filtri</p>
                <button onClick={() => setIsOpenFilter(!isOpenFilter)}>
                    {isOpenFilter ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />}
                </button>
            </div>
            {isOpenFilter && <form onSubmit={handleSubmit(methodParent)} className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 grid-rows-none gap-6 p-4">
                <InputsFields arrFields={InputsListProductsFilter} form={form} />

                <div className="flex items-center justify-end space-x-5 col-span-3">
                    <button className="w-5 h-5" onClick={() => reset(InitialValueFilterProducts)}>
                        <FunnelIcon className="w-5 h-5" />
                    </button>
                    <button className="h-10 w-52 bg-[#064e3b] text-white rounded-lg px-10 text-md">
                        Cerca
                    </button>
                </div>

            </form>}
        </div>
    )
}