import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { selectCurrentUserInfo } from "../../store/feautures/AuthSlice";
import { SuperAdminVisore } from "../../utils/genericList/ListRoleRoutes";
import ManageStateAccount from "../accounts/ManageStateAccount";
import DialogComponent from "../main/DIalogComponent";


export default function ActiveAccount({ row }: any) {

    const { role } = useSelector(selectCurrentUserInfo);

    return (
        SuperAdminVisore.includes(role)
            ? (role === 'Admin' && row?.ruolo !== 'SuperAdmin') || role === 'SuperAdmin' || role === 'Supervisore' 
            ?   <DialogComponent
                    icon={<CheckCircleIcon className="w-5 h-5" />}
                    label={'Attiva utente'}
                    content={
                        <ManageStateAccount row={row} typeAction={'enable'} />
                    }
                />
                : <></>
            : <></>
    )
}