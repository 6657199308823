import { combineReducers, configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./feautures/AuthSlice";
import { emptySplitApi } from "./services/InjectionEndpoint";
import storage from "redux-persist/lib/storage";
import {persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from "redux-persist"
import DialogSlice from "./feautures/DialogSlice";
import DialogActionSlice from "./feautures/DialogActionSlice";
import TabSlice from "./feautures/TabSlice";
import CurrentInterviewSlice from "./feautures/CurrentInterviewSlice";

const reducers = combineReducers({
    AuthSlice: AuthSlice,
    DialogSlice: DialogSlice,
    DialogActionSlice: DialogActionSlice,
    TabSlice: TabSlice,
    CurrentInterviewSlice: CurrentInterviewSlice,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
})

const persistConfig: any = {
    key: 'root',
    storage,
    whitelist: ['AuthSlice']
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    reducer: persistedReducer,
    middleware: (gdm) =>
    gdm({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    }).concat(emptySplitApi.middleware)
});

export let persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;