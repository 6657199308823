import { ChevronDownIcon, ChevronUpIcon, FunnelIcon,  } from "@heroicons/react/24/outline";
import moment from "moment-timezone";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AdapterReportFilterOp } from "../../helpers/adapter/filters/AdapterFilterReportOp";
import InputsFields from "../../helpers/InputFields";
import { useDownloadReportOperatorMutation } from "../../store/services/ReportService";
import { ReportOperatorListInputFilters } from "../../utils/listInputs/ReportOperatorListInputFilter";
import Loader from "../main/Loader";
import { saveAs } from 'file-saver';

export const InitialValueFilterReportOp: any = {
    DataDa: moment().tz("Europe/London").format('DD-MM-YYYY'),
    DataA: moment().tz("Europe/London").format('DD-MM-YYYY'),
    IdOperatore: {value: '', label: 'Qualsiasi'}
}

export default function ReportOperatorFilters({methodParent, operatoreData}: any) {

    let mapOp = operatoreData?.map((op: any) => ({value: op?.id, label: `${op?.nominativo} (${op?.codiceUtente})`}));
    const anyOption = [{value: '', label: 'Qualsiasi'}];
    const operatorOptions = anyOption.concat(mapOp);
    const [typeAction, setTypeAction] = useState<string | any>();
    const [downloadReportOperator, resultDownloadf] = useDownloadReportOperatorMutation();

    const handleSubmitFIlter = async (data: any) => {
        if(typeAction === 'table') methodParent(data);

        if(typeAction === 'download') {
            let dataMap = AdapterReportFilterOp(data)
            let result = await downloadReportOperator(dataMap);

            if('error' in result) {
                toast.error('Download non effettuato')
            } else {
                saveAs(result?.data, `StatisticheOperatore-${Date.now()}.xlsx`)
                toast.success('Download effettuato')
            }
        }
    }

    const form = useForm({defaultValues: InitialValueFilterReportOp});
    const [isOpenFilter, setIsOpenFilter] = useState<boolean>(true);
    const { handleSubmit, reset } = form;

    return(
        <div className="bg-white shadow-lg rounded-lg">
            {resultDownloadf.isLoading ? <Loader /> : null}
            <div className="flex justify-between items-center p-4" >
                <p>Filtri</p>
                <button onClick={() => setIsOpenFilter(!isOpenFilter)}>
                    {isOpenFilter ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />}
                </button>
            </div>
            {isOpenFilter && <form onSubmit={handleSubmit(handleSubmitFIlter) } >

                <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 grid-rows-none gap-6 px-4 py-2">
                    <InputsFields arrFields={ReportOperatorListInputFilters(operatorOptions)} form={form} />
                
                    <div className="flex items-center justify-end space-x-5 pb-5">
                    <button className="w-5 h-5" onClick={() => reset(InitialValueFilterReportOp)}>
                        <FunnelIcon className="w-5 h-5" />
                    </button>
                    <button type="submit" onClick={()=> setTypeAction('table')} className="h-10 w-52 bg-[#064e3b] text-white rounded-lg px-10 text-md">
                        Mostra report
                    </button>
                </div>
                </div>

                <div className="w-full flex justify-end px-5 pb-4">
                    <button type="submit" onClick={()=> setTypeAction('download')} className="h-10 w-52 bg-[#064e3b] text-white rounded-lg px-10 text-md">
                        Download report
                    </button>
                </div>

            </form>}
        </div>
    )
}