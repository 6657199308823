import { QueryStringHelper } from "../../helpers/QueryHelper";
import { emptySplitApi } from "./InjectionEndpoint"

const hostRegistryApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getHostRegistry: build.query({
            query: (params: any) => `api/anagraficaospite?${QueryStringHelper(params)}`,
            providesTags: ['registry', 'users']
        }),
        getHostRegistryById: build.query({
            query: (id) => `/api/anagraficaospite/${id}`,
            providesTags: ['registry', 'users']
        }),
        createHostRegistry: build.mutation({
            query: (host) => ({
                url: '/api/anagraficaospite',
                method: 'POST',
                body: host
            }),
            invalidatesTags: ['registry', 'users']
        }),
        updateHostRegistry: build.mutation({
            query: (host) => ({
                url: `/api/anagraficaospite/${host?.id}`,
                method: 'PUT',
                body: host
            }),
            invalidatesTags: ['registry', 'interviews']
        }),
        updateHostRegistryFromInterview: build.mutation({
            query: (host) => ({
                url: `/api/intervista/${host?.id}/anagraficaospite`,
                method: 'PUT',
                body: host
            }),
            invalidatesTags: ['registry', 'interviews']
        }),
        updateHostRegistryFromCall: build.mutation({
            query: (host) => ({
                url: `/api/chiamata/${host?.id}/anagraficaospite`,
                method: 'PUT',
                body: host
            }),
            invalidatesTags: ['registry', 'interviews']
        }),
        createCallHostRegistry: build.mutation({
            query: (call) => ({
                url: `/api/anagraficaospite/${call?.idHostRegistry}/chiamate`,
                method: 'POST',
                body: call?.dataAdd
            }),
            invalidatesTags: ['registry', 'users']
        }),
        checkTelefoni: build.mutation({
            query: (phone) => ({
                url: `/api/AnagraficaOspite/checktelefoni`,
                method: 'POST',
                body: phone
            }),
            invalidatesTags: ['registry', 'users']
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetHostRegistryQuery,
    useGetHostRegistryByIdQuery,
    useCreateHostRegistryMutation,
    useUpdateHostRegistryMutation,
    useCreateCallHostRegistryMutation,
    useCheckTelefoniMutation,
    useUpdateHostRegistryFromInterviewMutation,
    useUpdateHostRegistryFromCallMutation
} = hostRegistryApi;

