import { DocumentArrowUpIcon, PhoneArrowUpRightIcon } from "@heroicons/react/24/outline";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { useCreateInvoiceMutation } from "../../store/services/InterviewService";
import SmallLoader from "../main/SmallLoader";

export default function ActionSendEnvoice({ interviewId, id, namePayment, form, idForm }: any) {
    
    const [isOpen, setIsOpen] = useState(false);
    const {watch, formState:{dirtyFields}} = form;
    const [createInvoice, resultCreateInvoice] = useCreateInvoiceMutation();
    let payment = watch('pagamenti');

    const handleOpen = () => {
        let currentProd = payment[id];

        if(currentProd?.data && currentProd?.importo && currentProd?.metodo && currentProd?.importo > 0 && currentProd?.id) {
            setIsOpen(true)
        }

        if((Object.keys(dirtyFields)?.length > 0 && !Object.keys(dirtyFields)?.includes('pagamenti')) || !currentProd?.id) {
            toast.error(`Devi prima salvare la modifica per effettuare l'invio della fattura del pagamento ${namePayment}`)
        } 
         
        if (!currentProd?.metodo || !currentProd?.data || !currentProd?.importo) {
            toast.error(`Impossibile inviare la fattura mancano dei dati sul ${namePayment}`)
        }
        
    }

    const handleSendEnvoice = async () => {
        let currentProd = payment[id];

        let result = await createInvoice({ idInterview: interviewId, idPagamento: currentProd?.id });
        if ('error' in result) {
            setIsOpen(false);
            toast.error(`Fattura non inviata! Errore: ${result?.error?.data?.detail}`)
        } else {
            setIsOpen(false);
            toast.success(`Fattura inviata`);
        }
    }
    
        return (
            <>
              <div className="flex items-center">
                <button
                  type="button"
                  onClick={handleOpen}
                  className="flex items-center space-x-2 rounded-md bg-[#064e3b] px-2 py-2 text-base font-medium text-white focus:outline-none"
                >
                  <DocumentArrowUpIcon className="w-6 h-6" />
                  <span>Invia fattura</span>
                </button>
              </div>
        
              <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={() => null}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                  </Transition.Child>
        
                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="relative w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                          {resultCreateInvoice?.isLoading && <SmallLoader />}
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Invia fattura
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-md text-gray-500">
                              Sei sicuro di voler inviare la fattura del {namePayment}
                            </p>
                          </div>
        
                          <div className="mt-4 grid grid-cols-2 space-x-4">
                            <button type="button" className="h-10 bg-[#064e3b] text-white px-2 inline rounded-lg text-md" onClick={() => handleSendEnvoice()}>
                                Invia
                            </button>

                            <button type="button" className="h-10 border text-[#064e3b] border-[#064e3b] text-white px-2 inline rounded-lg text-md" onClick={() => setIsOpen(false)}>
                                Annulla
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </>
          )
}