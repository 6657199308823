import AddCall from "../../components/calls/AddCall";
import CallListComponent from "../../components/dialogContent/CallListContent";
import InterviewListComponent from "../../components/dialogContent/InterviewListConponent";
import UpdateHostRegistry from "../../components/dialogContent/UpdateHostRegistry";

export const listRegistryTab = (row: any) => ({

    ModificaAnagrafica: [
        {
            id: 1,
            component: <UpdateHostRegistry row={row}  />
        },
    ],
    ListaChiamate: [
        {
            id: 2,
            component: <CallListComponent row={row} />
        },
    ],
    AggiungiChiamata: [
        {
            id: 3,
            component: <AddCall row={row} />
        },
    ],
    ListaInterviste: [
        {
            id: 4,
            component: <InterviewListComponent row={row} />
        },
    ],
})