/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import { isOpenDialog, setIsOpenDialog } from '../../store/feautures/DialogSlice'
import { useEffect } from 'react'
import { setCurrentTab } from '../../store/feautures/TabSlice'

export default function DialogComponent({icon, label, content, isDisabled, textTooltip}: any) {
  const isOpenDialogSlice: boolean = useSelector(isOpenDialog);
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setCurrentTab({selectedTab: 0}))
  }

  useEffect(() => {
    if(!isOpenDialogSlice) {setOpen(false);}
  },[isOpenDialogSlice])

  return (
    <>
      <div className="group flex relative h-full">
        <button className={`${isDisabled ? 'opacity-50 cursor-not-allowed group' : 'opacity-1'}`} disabled={isDisabled} onClick={() => {setOpen(!open); handleClose(); dispatch(setIsOpenDialog({isOpen: true}))}}>{icon}</button>
        {/* {isDisabled 
          ? <p className="group-hover:opacity-100 w-32 whitespace-pre-line break-words transition-opacity bg-gray-200 shadow-md border p-2 text-sm rounded-md absolute -right-8 -translate-x-0 translate-y-0 opacity-0 top-96 z-20">{textTooltip}</p>
          : null
        } */}
      </div>

    <Transition.Root show={open} as={Fragment}>
      <Dialog 
        as="div" 
        className="relative z-50" initialFocus={cancelButtonRef} 
        onClose={() => null}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex justify-center p-4 text-center items-start sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="max-w-6xl relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full" style={{minHeight: 500}} >
                <div className='flex items-center justify-between px-8 pt-5'>
                  <p></p>
                  <XMarkIcon className="w-6 h-6 cursor-pointer" onClick={() => {setOpen(!open); handleClose()}} />
                </div>
                {content}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </>
  )
}