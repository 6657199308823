import AddCall from "../../components/calls/AddCall";
import UpdateCall from "../../components/calls/UpdateCall";
import CallListComponent from "../../components/dialogContent/CallListContent";
import InterviewListComponent from "../../components/dialogContent/InterviewListConponent";
import UpdateHostRegistry from "../../components/dialogContent/UpdateHostRegistry";

export const listCallsTab = (row: any) => ({

    ModificaChiamata: [
        {
            id: 1,
            component:  <UpdateCall row={row} />
        },
    ],
    ModificaAnagrafica: [
        {
            id: 1,
            component: <UpdateHostRegistry row={row} fromComponent={'call'}  />
        },
    ],
    ListaChiamate: [
        {
            id: 2,
            component: <CallListComponent row={row} />
        },
    ],
    AggiungiChiamata: [
        {
            id: 3,
            component: <AddCall row={row} />
        },
    ],
    ListaInterviste: [
        {
            id: 4,
            component: <InterviewListComponent row={row} />
        },
    ],
})