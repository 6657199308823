import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    isOpen: true,
}

const DialogSlice = createSlice({
    name: 'setOpenDialog',
    initialState: initialState,
    reducers: {
        setIsOpenDialog: (state, {payload}) => {
            return state =  {...payload};
        }
    }
})

export const { setIsOpenDialog } = DialogSlice.actions
export default DialogSlice.reducer

export const isOpenDialog = (state: any) => state.DialogSlice.isOpen