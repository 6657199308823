import { createSlice } from "@reduxjs/toolkit";

export const initialStateAuth = {
    token: null,
    refreshToken: null,
    role: null,
    name: null,
    lastname: null
}

const AuthSlice = createSlice({
    name: 'auth',
    initialState: initialStateAuth,
    reducers: {
        setCredentials: (state, {payload}) => {
            return state =  {...payload};
        },

        logOut: (state) => {
            return state = {...initialStateAuth}
        }
    }
})

export const { setCredentials, logOut } = AuthSlice.actions
export default AuthSlice.reducer

export const selectCurrentToken = (state: any) => state.AuthSlice.token
export const selectRefreshToken = (state: any) => state.AuthSlice.refreshToken
export const selectCurrentUserInfo = (state: any) => state.AuthSlice