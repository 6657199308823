export const HeaderRepMont = [
    {
        Header: "Periodo riferimento",
        accessor: "periodoRiferimento",
        disableSortBy: true,
        Filter: false,
        customSort: false,
    },
    {
        Header: "Nominativo",
        accessor: "nominativo",
        disableSortBy: true,
        Filter: false,
        customSort: false,
        Cell: ({ row }: any) => (
            <>
                <p>{row?.original?.codice}</p>
                <p>{row?.original?.nominativo}</p>
            </>
        )
    },
    {
        Header: "Video consegnati",
        accessor: "videoConsegnati",
        disableSortBy: true,
        Filter: false,
        customSort: false,
    }
]