import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import InputsFields from "../../helpers/InputFields";
import { ProductListInput } from "../../utils/listInputs/ProductsInputList";
import { useAddProductMutation, useUpdateProductMutation } from "../../store/services/ProductsService";
import { useDispatch } from "react-redux";
import { setIsOpenDialog } from "../../store/feautures/DialogSlice";
import Loader from "../main/Loader";

export default function ProductForm({row, type}: any) {

    const form = useForm({defaultValues: row});
    const dispatch = useDispatch();
    const { handleSubmit } = form;
    const actionLabel = type === 'add' ? 'aggiunto' : 'modificato';
    const [updateProduct, resultUpdate] = useUpdateProductMutation();
    const [addProduct, resultAdd] = useAddProductMutation();

    const handleSubmitProductData = async (data: any) => {
        let result: any;
        let prodMap = data;

        prodMap.prezzoConsigliato = Number(data?.prezzoConsigliato.replace(',','.'))

        if (type === 'add') {
            result = await addProduct(prodMap);
        }

        if (type === 'update') {
            result = await updateProduct({ id: row?.id, ...prodMap });
        }

        if ('error' in result) {
            toast.error(`Prodotto non ${actionLabel}! Errore: ${result?.error?.data?.detail}`)
        } else {
            dispatch(setIsOpenDialog({isOpen: false}));
            toast.success(`Prodotto ${actionLabel}`);
        }
    }

    return(
        <div className="p-8">
            {(resultAdd?.isLoading || resultUpdate?.isLoading) && <Loader />}
            <h3 className="px-5 h-10 py-2 outline-none border-[#064e3b] border-b-2 text-[#064e3b] inline">{type === 'update' ? 'Modifica prodotto' : 'Crea prodotto'}</h3>

            <form onSubmit={handleSubmit(handleSubmitProductData)} className="my-14 grid grid-cols-2 gap-8">
                <InputsFields arrFields={ProductListInput} form={form} isDisabled={false} />
                <div className="flex justify-end pt-5 col-span-2">
                    <button className="h-10 bg-[#064e3b] text-white px-5 w-64 rounded-lg text-md">
                        Salva
                    </button>
                </div>
            </form>
        </div>
    )
}