
import { DataFormatHelperTab, DataFormatHelperTabNoTime } from "../../helpers/DataFormatHelper";
import { useGetHostRegistryByIdQuery } from "../../store/services/HostRegistryService";
import Loader from "../main/Loader";


export default function InterviewListComponent({ row }: any) {

    const { data, isLoading, isSuccess } = useGetHostRegistryByIdQuery(row?.id);

    return (
        <>
            {isLoading && <Loader /> && <div className="h-48"></div>}
            {data?.result?.interviste?.length == 0 && isSuccess && <p className="h-32 m-24 text-center">Nessuna intervista presente</p>}
            {data?.result?.interviste?.length > 0 && isSuccess &&
                <div className="flex flex-col m-8">
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="overflow-hidden">
                                <table className="min-w-full">
                                    <thead className="border-b bg-white">
                                        <tr>
                                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">Stato intervista</th>
                                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">Data consulenza</th>
                                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">Esito consulenza</th>
                                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">Data intervista</th>
                                            <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-2">Data consegna</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {data?.result?.interviste?.map((interview: any) => (
                                            <tr className="bg-white border-b">
                                                <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">{interview?.stato}</td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">{interview?.dataConsulenza ? DataFormatHelperTab(interview?.dataConsulenza) : '-'}</td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">{interview?.esitoConsulenza || 'Nessun esito'}</td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap flex flex-col">{interview?.dataIntervista ? DataFormatHelperTab(interview?.dataIntervista) : '-'}</td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">{interview?.dataUltimaConsegna ? DataFormatHelperTabNoTime(interview?.dataUltimaConsegna) : '-'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}