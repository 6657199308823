import { useState } from "react";
import ErrorMessageComponent from "../main/ErrorMessage";

export default function InputFile({ input, form, isDisabled }: any) {

  const { register, formState: { errors }, clearErrors } = form;
  const [file, setFile] = useState<File | undefined>();

  function handleOnChange(e: React.FormEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement & {
      files: FileList;
    }

    setFile(target.files[0]);
    target?.files?.length > 0 && clearErrors(input.name)
  }

  return (
    <div>
      <div className="mb-2">
        <label htmlFor="file-input cursor-pointer" className="sr-only">Carica file</label>
        <input
          {...register(input.name, { ...input.validators })}
          type={input.type}
          placeholder={input.placeholder}
          disabled={isDisabled || input?.isDisabled}
          id="file-input"
          onChange={handleOnChange}
          className="cursor-pointer block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-3 file:px-4"
        />
      </div>
      <ErrorMessageComponent errors={errors} name={input?.name} />
    </div>
  )
};