import { QueryStringHelper } from "../../helpers/QueryHelper";
import { emptySplitApi } from "./InjectionEndpoint"

const reportService = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getReportOperator: build.query({
            query: (params: any) => `/api/Reports/statisticheoperatore?${QueryStringHelper(params)}`,
            providesTags: []
        }),

        // getReportConsulente: build.query({
        //     query: (params: any) => `/api/Reports/statisticheconsulente?${QueryStringHelper(params)}`,
        //     providesTags: []
        // }),

        getReportMontatore: build.query({
            query: (params: any) => `/api/Reports/statistichemontatore?${QueryStringHelper(params)}`,
            providesTags: []
        }),

        getReportOpVendite: build.mutation({
            query(params: any) {
              return {
                url: `/api/Reports/venditeoperatore/xlsx?${QueryStringHelper(params)}`,
                method: "GET",
                responseHandler: (response: any) => response.blob(),
                cache: "no-cache",
              };
            },
          }),

          getReportFatturato: build.mutation({
            query(params: any) {
              return {
                url: `/api/Reports/fatturato/xlsx?${QueryStringHelper(params)}`,
                method: "GET",
                responseHandler: (response: any) => response.blob(),
                cache: "no-cache",
              };
            },
          }),

          getReportConsulente: build.mutation({
            query(params: any) {
              return {
                url: `/api/Reports/statisticheconsulente/xlsx?${QueryStringHelper(params)}`,
                method: "GET",
                responseHandler: (response: any) => response.blob(),
                cache: "no-cache",
              };
            },
          }),


          downloadReportOperator: build.mutation({
            query(params: any) {
              return {
                url: `/api/Reports/statisticheoperatori/xlsx?${QueryStringHelper(params)}`,
                method: "GET",
                responseHandler: (response: any) => response.blob(),
                cache: "no-cache",
              };
            },
          }),

          downloadReportOspiti: build.mutation({
            query(params: any) {
              return {
                url: `/api/Reports/ospiti/xlsx?${QueryStringHelper(params)}`,
                method: "GET",
                responseHandler: (response: any) => response.blob(),
                cache: "no-cache",
              };
            },
          }),

    }),
})

export const {
    useGetReportOperatorQuery,
    // useGetReportConsulenteQuery,
    useGetReportMontatoreQuery,
    useGetReportOpVenditeMutation,
    useGetReportFatturatoMutation,
    useGetReportConsulenteMutation,
    useDownloadReportOperatorMutation,
    useDownloadReportOspitiMutation
} = reportService;