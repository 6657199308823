import { Tab } from '@headlessui/react'
import { useDispatch, useSelector } from 'react-redux'
import { ListTabHelper } from '../../helpers/ListTabHelper'
import { SelectedTab, setCurrentTab } from '../../store/feautures/TabSlice'

export default function TabsComponent({categories}: any) {

  const currentTab = useSelector(SelectedTab);
  const dispatch = useDispatch();

  return (
    <div className="w-full px-2 py-2 sm:px-0">
      <Tab.Group selectedIndex={currentTab} onChange={(e: any) => dispatch(setCurrentTab({selectedTab: e}))
}>
        
        <Tab.List className="flex space-x-2">
          {Object.keys(categories).map((category) => (
            
            <Tab
              key={`tab-btn-${category}`}
              className={({ selected }) => (` px-5 h-10 py-2 outline-none
                  ${selected
                    ? 'border-[#064e3b] border-b-2 text-[#064e3b]'
                    : 'text-gray-500 hover:bg-white/[0.12] hover:text-[#064e3b]'}
                `)
              }
            >
              {ListTabHelper[category]}
            </Tab>
          ))}
        </Tab.List>


        <Tab.Panels className="mt-10">
          {Object.values(categories).map((contentArr: any, idx: number) => (
            <Tab.Panel key={`${idx}-panel`} >
            {contentArr?.map((content: any) => (
                  content?.component
                ))
            }
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
