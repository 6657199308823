import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import RoutesList from './routing/Routes';

function App() {
  return (
    <Router>
      <Suspense fallback={<div>LOADING.....</div>}>
        <RoutesList />
      </Suspense>
    </Router>
  );
}

export default App;
