import { ChevronDownIcon, ChevronUpIcon, FunnelIcon,  } from "@heroicons/react/24/outline";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { IFiltersCallsAdapter } from "../../helpers/adapter/FiltersCallsAdapter";
import InputsFields from "../../helpers/InputFields";
import CategorieProfesionaliLookUp from "../../lookup/CategorieProf";
import RegioniLookUp from "../../lookup/Regioni";
import { selectCurrentUserInfo } from "../../store/feautures/AuthSlice";
import { useGetUserRoleQuery } from "../../store/services/LookUpService";
import { SuperAdminVisore } from "../../utils/genericList/ListRoleRoutes";
import { CallsFiltersList, FilterOpCall, InputDataFilterApp } from "../../utils/listInputs/CallsListFilters";
import Loader from "../main/Loader";


export default function CallsFilters({methodParent, optionsEsiti}: any) {

    const form = useForm();
    const [isOpenFilter, setIsOpenFilter] = useState<boolean>(true);
    const { handleSubmit, reset } = form;
    const { role } = useSelector(selectCurrentUserInfo);
    const anyOption = [{value: '', label: 'Qualsiasi'}]
    const optionRegioni = anyOption.concat(RegioniLookUp(false));
    const catProfessionali = anyOption.concat(CategorieProfesionaliLookUp());
    const optionEsito = anyOption.concat(optionsEsiti);
    const {isLoading, data} = useGetUserRoleQuery('')
    
    let optionNominativo: any[] = useMemo(() => {
        return data?.filter((user: any) => user.ruolo === 'Operatore').map((user: any) => ({value: user?.id, label: `${user?.nominativo}(${user?.codiceUtente})`}))
    }, [data]);

    const InitialValueFilterCalls: IFiltersCallsAdapter = {
        CategoriaProfessionaleId: null,
        NominativoOAziendaOspite: null,
        CodiceOspite: null,
        DataAppuntamentoDa: '',
        DataAppuntamentoA: '',
        DataChiamataDa: '',
        DataChiamataA: '',
        Esito: '',
        NominativoOCodiceOperatoreCreazioneId: "",
        RegioneOspite: '',
        TelefonoOspite: ''
    }

    return(
        <div className="bg-white shadow-lg rounded-lg">
            {isLoading ? <Loader /> : null}
            <div className="flex justify-between items-center p-4" >
                <p>Filtri</p>
                <button onClick={() => setIsOpenFilter(!isOpenFilter)}>
                    {isOpenFilter ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />}
                </button>
            </div>
            {isOpenFilter && <form onSubmit={handleSubmit(methodParent) } >

                <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 grid-rows-none gap-6 p-4">
                    <InputsFields arrFields={CallsFiltersList(optionRegioni, catProfessionali, optionEsito)} form={form} />
                    {SuperAdminVisore.includes(role) && <InputsFields arrFields={FilterOpCall(optionNominativo)} form={form} />}
                    <InputsFields arrFields={InputDataFilterApp} form={form} />
                </div>

                <div className="flex items-center justify-end space-x-5 sm:col-span-3 p-5">
                    <button className="w-5 h-5" onClick={() => reset(InitialValueFilterCalls)}>
                        <FunnelIcon className="w-5 h-5" />
                    </button>
                    <button type="submit" className="h-10 w-52 bg-[#064e3b] text-white rounded-lg px-10 text-md">
                        Cerca
                    </button>
                </div>

            </form>}
        </div>
    )
}