import { useDispatch } from "react-redux";
import { useDeleteProductMutation } from "../../../store/services/ProductsService"
import Loader from "../../main/Loader";
import { toast } from "react-toastify";
import { setIsOpenDialog } from "../../../store/feautures/DialogSlice";


export default function DeleteProduct({row}: any) {

    const dispatch = useDispatch();
    const [deleteProduct, resultDelete] = useDeleteProductMutation();

    const handleDelete = async () => {
        let result: any;

        result = await deleteProduct(row?.id);
        
        if ('error' in result) {
            toast.error(`Prodotto non eliminato! Errore: ${result?.error?.data?.detail}`)
        } else {
            dispatch(setIsOpenDialog({isOpen: false}));
            toast.success(`Prodotto eliminato`);
        }
    }

    return(
        <>
            {resultDelete.isLoading && <Loader />}
            <div className="p-8 space-y-8">
                <h3 className="px-5 h-10 py-2 outline-none border-[#064e3b] border-b-2 text-[#064e3b] inline">Elimina prodotto</h3>
                <p>Sei sicuro di voler eliminare il prodotto {row?.nome}?</p>

                <div className="mt-4 flex space-x-4 justify-end pt-32">
                    <button onClick={() => dispatch(setIsOpenDialog({isOpen: false}))} type="button" className="w-24 h-10 border text-[#064e3b] border-[#064e3b] text-white px-2 inline rounded-lg text-md">
                        Annulla
                    </button>
                    <button onClick={handleDelete} type="button" className="w-24 h-10 bg-[#064e3b] text-white px-2 inline rounded-lg text-md">
                        Elimina
                    </button>
                </div>
            </div>
        </>

    )
}