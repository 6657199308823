import { useRef } from "react";
import { Controller } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import ErrorMessageComponent from "../main/ErrorMessage";
import CustomInput from "./InputRenderPicker";
import "react-multi-date-picker/styles/colors/green.css"
import "react-multi-date-picker/styles/backgrounds/bg-dark.css"
import { XMarkIcon } from "@heroicons/react/24/outline";


export default function DatePickerInput({ input, form, isDisabled }: any) {

    const weekDays = ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"];
    const months = ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"]
    const { control, formState: { errors } } = form;
    const datePickerRef: any = useRef(null)

    return (
        <div className="flex flex-col">
        <Controller
            name={input?.name}
            control={control}
            rules={input.validators}
            render={({ field: {onChange, value}}) => (
                <DatePicker
                    disabled={isDisabled || input?.isDisabled}
                    value={value}
                    weekDays={weekDays}
                    months={months}
                    className="green bg-dark relative"
                    ref={datePickerRef}
                    minDate={input?.isLimitDate ? new Date() : ''}
                    onChange={(date: any) => {
                        if(!value && date?.minute) {
                            date.minute = date?.minute + 5;
                        }
                        onChange(date?.format?.(input.isTimeShow ? "DD-MM-YYYY HH:mm" : "DD-MM-YYYY"));
                    }}
                    fixMainPosition={true}
                    calendarPosition={'bottom-center'}
                    format={input.isTimeShow ? "DD-MM-YYYY HH:mm" : "DD-MM-YYYY"}
                    render={<CustomInput input={input} isDisabled={isDisabled || input?.isDisabled} datePickerRef={datePickerRef} />}
                    plugins={
                        input.isTimeShow
                        ? [<TimePicker hideSeconds position="right" />]
                        : []
                    }
                >
                    <button className="absolute top-0 right-0"
                        style={{ margin: "5px" }}
                        onClick={() => datePickerRef?.current.closeCalendar()}
                    ><XMarkIcon className="h-4 w-4" /></button>
                </DatePicker>
            )}

        />
        <ErrorMessageComponent errors={errors} name={input?.name} />
        </div>
    )
}