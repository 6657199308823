import { useNavigate } from "react-router-dom";
import UsersForm from "../../components/form/usersForm";

export default function NewUserPage() {
    const navigate = useNavigate();

    return (
        <>
            <div className="flex justify-between items-center">
                <p className="mb-8">Aggiungi utente</p>
                <p className="mb-8 text-[#9ca3af] cursor-pointer" onClick={() => navigate('/dashboard/users')}>Torna alla lista</p>
            </div>
            <div className="bg-white shadow-lg rounded-lg">
                <UsersForm type={'add'} />
            </div>
        </>
    )
}