import ErrorMessageComponent from "../main/ErrorMessage";

export default function DefaultInput({ input, form, isDisabled }: any) {

    const { register, formState: { errors } } = form;

    return (
        <div className={`${input.size_input}`}>
            <div className={`relative`}>
                <input
                    {...register(input.name, { ...input.validators })}
                    className={`${isDisabled || input?.isDisable ? 'opacity-70' : ''} bg-[#efefef] rounded-md p-2 peer h-10 w-full text-skin-textInput placeholder-transparent focus:outline-none focus:border-skin-inputFocus text-base`}
                    autoComplete={'false'}
                    id={input.name}
                    type={input?.type}
                    placeholder={input.placeholder}
                    disabled={isDisabled || input?.isDisabled}
                    maxLength={input?.maxLength ? input?.maxLength : null}
                />
                <label htmlFor={input?.name} className={`${isDisabled || input?.isDisable ? 'opacity-70' : ''} pointer-events-none select-none absolute text-base left-2 -top-4 text-[#064e3b] transition-all peer-placeholder-shown:text-[#9CA3AF] peer-placeholder-shown:text-[#9CA3AF] peer-focus:left-2 peer-placeholder-shown:top-3 peer-focus:-top-4 peer-focus:text-skin-focus peer-focus:text-base`}>
                    {input.label}
                </label>
            </div>
            <ErrorMessageComponent errors={errors} name={input?.name} />
        </div>

    )
}