import { useState } from "react";
import { useSelector } from "react-redux";
import HostRegistryFilters from "../../components/filters/HostRegistryFilters";
import ButtonAdd from "../../components/main/ButtonAdd";
import Loader from "../../components/main/Loader";
import TableComponent from "../../components/main/Table";
import { RegistryAdapterFilterSent } from "../../helpers/adapter/filters/AdapterFilterRegistry";
import { initialParams, IPagination, PaginationHelper } from "../../helpers/PaginationHelper";
import { selectCurrentUserInfo } from "../../store/feautures/AuthSlice";
import { useGetHostRegistryQuery } from "../../store/services/HostRegistryService";
import { HeaderHostRegistry } from "../../utils/headerTable/TabHeaderHostregistry";

export default function HostRegistryList() {

    const { role } = useSelector(selectCurrentUserInfo);
    const [queryParams, setQueryParams] = useState<IPagination>(initialParams);
    const [filterParams, setFilterParams] = useState({ includeImportData: true, globalSearch: role === 'Operatore' ? true : false });
    let { isSuccess, isLoading, data, isFetching } = useGetHostRegistryQuery({ ...queryParams, ...filterParams });

    const handleActionTable = (data: any) => {
        let paramsPagination: any = PaginationHelper(queryParams, data);
        setQueryParams(paramsPagination);
    }

    const handleSetFilter = (dataFilter: any) => {
        let MapFilterRegistry: any = RegistryAdapterFilterSent(dataFilter);
        setFilterParams(MapFilterRegistry);
        setQueryParams(initialParams);
    }

    return (
        <>
            {(isLoading || isFetching) && <Loader />}
            <h2 className="text-xl py-6">Gestione anagrafiche</h2>
            <HostRegistryFilters methodParent={handleSetFilter} />
            <div className="flex items-center justify-end pt-14">
                <ButtonAdd path={'/dashboard/host-registry/new'} label={'Aggiungi anagrafica'} />
            </div>
            <div className="mt-6">
                {isSuccess && data?.result.length > 0
                    ? <TableComponent columns={HeaderHostRegistry} data={data?.result} meta={data?.meta} parentMethod={handleActionTable} pageIndex={queryParams?.CurrentPage} pageSizeParent={queryParams?.PageSize} sortDirectionParent={queryParams?.sortDirection} currentSortCol={queryParams?.sortIdCol} />
                    : <p className="p-6 text-center">NESSUN ANAGRAFICA CLIENTE PRESENTE</p>
                }
            </div>
        </>
    )
}