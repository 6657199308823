
export const CallsFiltersList = (optionRegioni: any[], catProfessionali: any[], optionEsito: any[]) => ([
    {
        label: 'Codice ospite',
        name: 'CodiceOspite',
        placeholder: 'Codice ospite',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full'
    },
    {
        label: 'Telefono ospite',
        name: 'TelefonoOspite',
        placeholder: 'Telefono ospite',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full'
    },
    {
        label: 'Nominativo o azienda ospite',
        name: 'NominativoOAziendaOspite',
        placeholder: 'Nominativo',
        type: 'text',
        validators: {},
        type_input: 'input',
        size_input: 'w-full'
    },
    {
        label: 'Regione ospite',
        name: 'RegioneOspite',
        placeholder: 'Regione ospite',
        type: 'select',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        options: optionRegioni
    },
    {
        label: 'Cat. professionale',
        name: 'CategoriaProfessionaleId',
        placeholder: 'Cat. professionale',
        type: 'text',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        options: catProfessionali
    },
    {
        label: 'Esito',
        name: 'Esito',
        placeholder: 'Esito',
        type: 'text',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        options: optionEsito
    },
    {
        label: 'Data chiamata da',
        name: 'DataChiamataDa',
        placeholder: 'Data chiamata da',
        type: 'text',
        validators: {},
        type_input: 'picker',
        size_input: 'w-full',
        isLimitDate: false,
        isTimeShow: false
    },
    {
        label: 'Data chiamata a',
        name: 'DataChiamataA',
        placeholder: 'Data chiamata a',
        type: 'text',
        validators: {},
        type_input: 'picker',
        size_input: 'w-full',
        isLimitDate: false,
        isTimeShow: false
    }
])


export const InputDataFilterApp = [
    {
        label: 'Data appuntamento da',
        name: 'DataAppuntamentoDa',
        placeholder: 'Data appuntamento da',
        type: 'text',
        validators: {},
        type_input: 'picker',
        size_input: 'w-full',
        isLimitDate: false,
        isTimeShow: false
    },
    {
        label: 'Data appuntamento a',
        name: 'DataAppuntamentoA',
        placeholder: 'Data appuntamento a',
        type: 'text',
        validators: {},
        type_input: 'picker',
        size_input: 'w-full',
        isLimitDate: false,
        isTimeShow: false
    }
]

export const FilterOpCall = (optionNominativo: any[]) => ([
    {
        label: 'Nominativo o cod. operatore',
        name: 'NominativoOCodiceOperatoreCreazioneId',
        placeholder: 'Nominativo o cod. operatore',
        type: 'text',
        validators: {},
        type_input: 'react-select',
        size_input: 'w-full',
        options: optionNominativo
    }
])