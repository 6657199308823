
export interface IUserFormSent {
    nome: string,
    cognome: string,
    regione: string,
    ruolo: string,
    email: string
    password?: string,
    supervisoreId: any,
    supervisoreNominativo?: any
}

export interface IUserFormUpdate {
    nome: string,
    cognome: string,
    regione: {value: string, label: string} | any,
    ruolo: {value: any, label: string} | any,
    email: string,
    password?: string,
    supervisoreId: {value: any, label: string} | any
}

export function UserAdapterValues(user: any, list: any) {
    let userMap: IUserFormUpdate;
    return userMap = {
            nome: user?.nome,
            cognome: user?.cognome,
            regione:{value: user?.regione || '', label: user?.regione || ''},
            ruolo: {value: user?.ruolo, label: user?.ruolo},
            email: user?.email,
            supervisoreId: {
                value: user?.supervisoreId, 
                label: user?.supervisoreNominativo
            } || ''
        } 
}

export function UserAdapterSent(user: IUserFormUpdate) {
    let userMap: IUserFormSent;
    return userMap = {
            nome: user?.nome,
            cognome: user?.cognome,
            regione: user?.regione?.value ? user?.regione?.value : "Tutte",
            ruolo: user?.ruolo?.value || user.ruolo,
            email: user?.email,
            password: user?.password,
            supervisoreId: user?.supervisoreId?.value || null
    }
}