import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import ErrorMessageComponent from "../main/ErrorMessage";

export default function PasswordInput({ input, form }: any) {

    const { register, formState: { errors } } = form;
    const [isShowPassword, setIsShowPassword] = useState(false);

    return (
        <div>
            <div className={`relative ${input.size_input}`}>
                <input
                    {...register(input.name, { ...input.validators })}
                    className={`bg-[#efefef] rounded-md p-2 peer h-10 w-full text-skin-textInput placeholder-transparent focus:outline-none focus:border-skin-inputFocus text-base`}
                    autoComplete={'false'}
                    id={input.name}
                    type={isShowPassword ? 'text' : 'password'}
                    placeholder={input.placeholder}
                />
                <label htmlFor={input?.name} className={`pointer-events-none select-none absolute text-base left-2 -top-4 text-[#064e3b] transition-all peer-placeholder-shown:text-[#9CA3AF] peer-placeholder-shown:text-[#9CA3AF] peer-focus:left-2 peer-placeholder-shown:top-3 peer-focus:-top-4 peer-focus:text-skin-focus peer-focus:text-base`}>
                    {input.label}
                </label>
                <button type="button" className="absolute top-3 right-3" onClick={() => setIsShowPassword(!isShowPassword)}>
                    {isShowPassword
                        ? <EyeSlashIcon className="w-5 h-5" />
                        : <EyeIcon className="w-5 h-5" />
                    }
                </button>
            </div>
            <ErrorMessageComponent errors={errors} name={input?.name} />
        </div>

    )
}