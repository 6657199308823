import { Link, useLocation } from "react-router-dom";
import bgNav from "../../assets/rounded-bottom.svg"
import smallLogo from "../../assets/small-logo.png"
import logo from "../../assets/logo.png"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { IListMenu, ListItemMenu } from "../../utils/genericList/ListItemMenu";
import { useSelector } from "react-redux";
import { selectCurrentUserInfo } from "../../store/feautures/AuthSlice";

export default function SideNav({openSidenav, isOpen}: any) {

    const { pathname } = useLocation();
    const {role} = useSelector(selectCurrentUserInfo);

    return (
        <div className={`${isOpen ? 'w-56' : 'w-20'} z-40 bg-[#064e3b] transition-all duration-800 ease-in-out relative fix top-0 bottom-0 left-0 min-h-screen`}>
            <button onClick={() => openSidenav(!isOpen)} className="p-1 rounded-md absolute top-10 -right-3 rotate-45 bg-[#064e3b]">
                {isOpen
                    ? <ChevronLeftIcon className="w-5 h-5 -rotate-45 text-[#f1f5f9]" />
                    : <ChevronRightIcon className="w-4 h-4 -rotate-45 text-[#f1f5f9]" />
                }
            </button>

            <div className="h-20 flex items-center justify-center">
                {isOpen
                    ? <img src={logo} alt='logo-small' className="w-32 h-14 block" />
                    : <img src={smallLogo} alt='logo-small' className="w-8 h-8 block" />
                }
            </div>
            <ul className="mt-4 pl-5">
                {ListItemMenu.map((item: IListMenu) => (
                    item?.visibileFor.includes(role) ? 
                    <li onClick={() => openSidenav(false)} className="my-5" key={`irem-menu-${item?.label}`}>
                        <Link to={item?.path} className={`flex items-center relative ${!isOpen && 'justify-center'} p-4 ${pathname.includes(item?.activePath) ? 'bg-[#f1f5f9] text-[#064e3b] rounded-tl-3xl rounded-bl-3xl stroke-white' : 'text-[#f1f5f9]'}`}>
                            {pathname.includes(item?.activePath) && <>
                                <img src={bgNav} className="absolute rotate-90" style={{ width: '30px', height: '30px', top: '-29.45px', right: '-1px' }} />
                                <img src={bgNav} className="absolute" style={{ width: '30px', height: '30px', bottom: '-29.45px', right: '-1px' }} />
                            </>
                            }
                            {item?.icon}
                            {isOpen && <span className="mx-4 font-medium">{item?.label}</span>}
                        </Link>
                    </li>
                    : <></>
                ))
                }
            </ul>
        </div>
    )
}