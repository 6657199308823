import { IdentificationIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { listCallsTab } from "../../utils/listTabs/CallListTabs";
import ActionButtonDialogRow from "../main/ActionButtonDialogRow";
import DialogComponent from "../main/DIalogComponent";

export default function ActionOpenDialogCall({ row, label, type}: any) {

    let rowMap = {id: row?.anagraficaOspiteId, callId: row?.id};
    let elementForTab = listCallsTab(rowMap);

    return (
        <DialogComponent
            icon={type ? <IdentificationIcon className="w-6 h-6" /> : <PencilSquareIcon className="w-6 h-6" />}
            label={label}
            content={<ActionButtonDialogRow elementForTab={elementForTab} />}
        />
    )
}