import { QueryStringHelper } from "../../helpers/QueryHelper";
import { emptySplitApi } from "./InjectionEndpoint"


const usersApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getAccount: build.query({
            query: (params: any) => `/api/account?${QueryStringHelper(params)}`,
            providesTags: ['users']
        }),
        getAccountById: build.query({
            query: (id) => `/api/account/${id}`,
            providesTags: ['users']
        }),
        createAccount: build.mutation({
            query: (account) => ({
                url: '/api/account',
                method: 'POST',
                body: account
            }),
            invalidatesTags: ['users']
        }),
        updateAccount: build.mutation({
            query: (account) => ({
                url: `/api/account/${account?.id}`,
                method: 'PUT',
                body: account
            }),
            invalidatesTags: ['users']
        }),
        enableAccount: build.mutation({
            query: (id) => ({
                url: `/api/account/${id}/enable`,
                method: 'PUT'
            }),
            invalidatesTags: ['users']
        }),
        disableAccount: build.mutation({
            query: (id) => ({
                url: `/api/account/${id}/disable`,
                method: 'PUT'
            }),
            invalidatesTags: ['users']
        }),
        resetPassword: build.mutation({
            query: (data) => ({
                url: `/api/account/${data.id}/password`,
                method: 'PUT',
                body: {password: data?.password}
            }),
            invalidatesTags: ['users']
        }),
    }),
})

export const {
    useGetAccountQuery,
    useGetAccountByIdQuery,
    useCreateAccountMutation,
    useUpdateAccountMutation,
    useDisableAccountMutation,
    useEnableAccountMutation,
    useResetPasswordMutation
} = usersApi;

