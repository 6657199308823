import { useForm } from "react-hook-form"
import InputsFields from "../../helpers/InputFields";
import Loader from "../main/Loader";
import {toast} from "react-toastify";
import { useCreateCallHostRegistryMutation } from "../../store/services/HostRegistryService";
import { CallListInput, InputDateApp } from "../../utils/listInputs/CallListInputs";
import { CallAdapter } from "../../helpers/adapter/CallsAdapter";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { useUpdateCallMutation } from "../../store/services/CallsService";
import { SelectedTab, setCurrentTab } from "../../store/feautures/TabSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUserInfo } from "../../store/feautures/AuthSlice";
import { IdOperatoreCreazione } from "../../utils/listInputs/HostRegistryListInputs";
import { useGetUserRoleQuery } from "../../store/services/LookUpService";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FormAlert } from "../../helpers/FormAlert";

export default function CallsForm({infoRegistry, type, defaultValues, lastCall, optionEsito, optionInteresseVideo}: {infoRegistry?: any, type: string, defaultValues?: any, methodParent?: any, lastCall?: any, optionEsito: any[], optionInteresseVideo: any[]}) {
    
    const {role} = useSelector(selectCurrentUserInfo);
    let initialValues = {
        argomentoIntervista: lastCall ? lastCall?.argomentoIntervista : defaultValues?.argomentoIntervista ?  defaultValues?.argomentoIntervista : '',
        note: lastCall?.note ? lastCall?.note : defaultValues?.note ? defaultValues?.note : '',
        interesseVideo: lastCall?.interesseVideo || defaultValues?.interesseVideo ? {
            value: lastCall ? lastCall?.interesseVideo : defaultValues?.interesseVideo,
            label: optionInteresseVideo?.map(
                (el: any, key: number) =>  {
                    if(el.value === lastCall?.interesseVideo || el.value === defaultValues?.interesseVideo) {
                        return optionInteresseVideo[key].label
                    }
                }
            )
        }
        : "",
        esito: ((lastCall?.esito || defaultValues?.esito) && type !== 'add') ? {
            value: lastCall ? lastCall?.esito : defaultValues?.esito,
            label: optionEsito?.map(
                (el: any, key: number) =>  {
                    if(el.value === lastCall?.esito || el.value === defaultValues?.esito) {
                        return optionEsito[key].label
                    }
                }
            )
        }
        : "",
        dataAppuntamento: defaultValues?.dataAppuntamento ? moment(defaultValues?.dataAppuntamento).format('DD-MM-YYYY HH:mm')  : ''
    }

    const dispatch = useDispatch();
    const form = useForm({
        mode: 'all',
        defaultValues: {...defaultValues, ...initialValues}
    });
    const { handleSubmit, watch, clearErrors, formState:{isValid, errors} } = form;
    const [ createCallHostRegistry, requestAdd ] = useCreateCallHostRegistryMutation();
    const [ updateCall, requestUpdate ] = useUpdateCallMutation();
    let actionLabel = type === 'add' ? 'aggiunta' : 'modificata';
    const valueEsito = watch('esito');
    const [inputList, setInput] = useState([]);
    const currentTab = useSelector(SelectedTab);
    let { data, isLoading: loadingUser } = useGetUserRoleQuery(['Consulente', 'Admin', 'SuperAdmin'].includes(role) ? {role: 'Operatore' } : skipToken);
    let optionConsulente = data?.map((user: any) => ({ value: user?.id, label: `${user?.nominativo} (${user?.codiceUtente})` }));

    useEffect(() => {
        if(valueEsito !== 'AppuntamentoPreso') {
            clearErrors(['argomentoIntervista', 'interesseVideo'])
        }
        let inputList: any = CallListInput(valueEsito, optionEsito, optionInteresseVideo);
        setInput(inputList)
    }, [valueEsito, optionEsito, optionInteresseVideo])
    
    const handleSubmitUserData = async (data: any) => {
        let result: any;
        let objAdapter = CallAdapter(data);

        let objMap = {
            idHostRegistry: infoRegistry.id,
            dataAdd: { ...objAdapter }
        }

        if(type === 'add') {
            result = await createCallHostRegistry(objMap);
        }

        if(type === 'update') {
            result = await updateCall({id: defaultValues?.id, ...objAdapter});
        }

        if ('error' in result) {
            toast.error(`Chiamata non ${actionLabel}! Errore: ${result?.error?.data?.detail}`)
        } else {
            toast.success(`Chiamata ${actionLabel}`)
            dispatch(setCurrentTab({selectedTab: currentTab - 1}))
        }
    }

    return(
        <div className="relative">
            {(requestAdd?.isLoading || requestUpdate.isLoading) && <Loader />}
            {type === 'update' && <p className="font-semibold mb-4 px-10">Id: {defaultValues?.id}</p>}
            <form onSubmit={handleSubmit(handleSubmitUserData)} className="my-2">
                <div className="grid grid-cols-2 grid-rows-none gap-10 p-10">
                    <InputsFields arrFields={inputList} form={form} />
                    {(valueEsito?.value === 'AppuntamentoPreso' || valueEsito?.value === 'DaRichiamare') && <InputsFields arrFields={InputDateApp} form={form} />}
                </div>

                {['Consulente', 'Admin', 'SuperAdmin'].includes(role) && type === 'add'
                        ?
                        <div className="grid grid-cols-2 grid-rows-none gap-10 px-10 mb-5">
                            <h3 className="text-orange-500 col-span-2">Operatore creazione</h3>
                            <InputsFields arrFields={IdOperatoreCreazione(optionConsulente)} form={form} />
                        </div>
                : null}

                <div className="flex justify-end px-8">
                    <button disabled={
                        requestAdd?.isLoading || requestUpdate.isLoading ||
                        (((defaultValues && !defaultValues?.editabile) || (lastCall && !lastCall?.editabile)) && !['Admin', 'SuperAdmin'].includes(role))
                    }
                    className={`${defaultValues?.editabile || lastCall?.editabile || lastCall === null || role === 'Admin' || role === 'SuperAdmin' ? '' : 'opacity-50 cursor-not-allowed'} h-10 bg-[#064e3b] text-white px-5 w-64 rounded-lg text-md`}
                    onClick={() => FormAlert(isValid && Object.keys(errors).length == 0 ? 'noShow' : 'show', errors)}
                    >Salva</button>
                </div>
            </form>
        </div>

    )
}